import './cx-avatar-dialog.scss';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, autoinject } from 'aurelia-framework';
import { apiEndpoint } from 'environment';
import { ImageService } from 'services/image-service';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class CxAvatarDialog {
    parent;
    private inputFileRef: HTMLInputElement;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(
        private imageService: ImageService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService,
        private customerService: CustomerService,
        private helper: Helper
    ) {}

    @bindable dialogStatus: string;
    @bindable filesList: object[];
    @bindable dialog;
    @bindable user;
    environment = apiEndpoint();
    selectedFiles: FileList;
    selectedAvatar;
    dropzone;
    avatarPreview;
    loading: boolean;
    staticChicks;

    attached() {
        this.dialogStatus = 'menu';
        this.createChicks();
        this.filesList = [];
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    close() {
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.dialogStatus = 'menu';
        this.clearStatus();
        this.eventAggregator.publish('avatar-dialog-open', { avatarDialogOpen : false });
    }

    switchToSeeAvatars() {
        this.dialogStatus = 'seeAvatars';
    }

    switchToUpload() {
        this.dialogStatus = 'uploadImage';
        this.dropzone = document.getElementById('drop-zone');
    }

    selectAvatar(chick) {
        this.clearAvatars();
        this.selectedAvatar = chick.name;
        this.avatarPreview = chick;
        this.staticChicks[chick.id - 1].state = 'selected';
    }

    clearAvatars() {
        for (const chick of this.staticChicks) {
            chick.state = '';
        }
    }

    clearStatus() {
        this.selectedFiles = null;
        this.selectedAvatar = null;
        this.avatarPreview = null;
        this.filesList = [];
        const input = <HTMLInputElement>document.getElementById('image-input');
        input.value = '';
    }

    clearSelectedAvatar() {
        this.selectedAvatar = null;
        this.avatarPreview = null;
    }

    handleBack() {
        if (this.dialogStatus === 'menu') {
            this.dialog.close();
        } else if (this.selectedFiles || this.filesList.length !== 0) {
            this.clearStatus();
            this.handleClosing();
        } else if (this.avatarPreview) {
            this.clearSelectedAvatar();
            this.dialogStatus = 'seeAvatars';
        } else {
            this.handleClosing();
        }
    }

    triggerImgInput() {
        this.inputFileRef.click();
    }

    dropHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items) {
            for (const key in e.dataTransfer.items) {
                if (e.dataTransfer.items[key].kind === 'file') {
                    this.filesList.push(e.dataTransfer.items[key].getAsFile());
                }
            }
        } else {
            for (const key in e.dataTransfer.files) {
                this.filesList.push(e.dataTransfer.items[key].getAsFile());
            }
        }

        this.dropzone.classList.remove('drop-zone-hover');

        if (e.dataTransfer.items) {
            e.dataTransfer.items.clear();
        } else {
            e.dataTransfer.clearData();
        }
    }

    dragEnterHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.path.filter(item => (item['id'] === 'drop-zone'))) {
            this.dropzone.classList.add('drop-zone-hover');
        }
    }

    dragLeaveHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.path.filter(item => (item['id'] === 'drop-zone'))) {
            this.dropzone.classList.remove('drop-zone-hover');
        }
    }

    avatarUploadChange() {
        if (Array.from(this.selectedFiles).find(x => !x.type.includes('image'))) {
            this.toastService.showToast('Invalid file type', 'Please select an image file.', 'info');
            this.selectedFiles = null;
        }
    }

    async handleAvatarUpdate(file: FileList) {
        if (file) {
            this.loading = true;

            if (Array.from(file).find(x => !x.type?.includes('image'))) {
                await this.toastService.showToast('Invalid file type', 'Please select an image file.', 'info');
                return;
            }

            const result = this.imageService.buildFormData(file);
            if (result) {
                this.user.avatarImagePath = await this.imageService.postAvatar(result);
                this.eventAggregator.publish('user-updated', { user: this.user });
                await this.toastService.showToast('Updated profile', 'Your avatar has been updated successfully.', 'success');
                this.dialog.close();
                this.dialogStatus = 'menu';
                this.loading = false;
            }
        } else {
            await this.toastService.showToast('No file selected', 'Please select a file before trying to submit.', 'info');
        }
    }

    async manualAvatarUpdate() {
        try {
            this.user.avatarImagePath = this.selectedAvatar === 'deleteAvatar' ? '' : this.selectedAvatar;
            const response = await this.customerService.updateInformation(this.user);
            if (response) {
                this.user = response;
                this.eventAggregator.publish('user-updated', { user: this.user });
                await this.toastService.showToast('Updated profile', 'Your avatar has been updated successfully.', 'success');
                this.dialog.close();
                this.dialogStatus = 'menu';
            } else {
                await this.toastService.showToast('Failed to update profile', 'Please check that all field are valid.', 'error');
            }
        } catch (e) {
            console.log(e);
        }
    }

    createStaticChick = (num, addStyle = false, forceSrc : string | number = -1, forceName : string | number = -1) => this.staticChicks.push(({
        id: this.staticChicks.length + 1,
        src: forceSrc === -1 ? this.environment + `Image/chicks-avatars/chick_${num}` : forceSrc,
        name: forceName === -1 ? `chick_${num}` : forceName,
        state: '',
        classStyle: addStyle ? 'modified-chick' : undefined
    }));

    createChicks() {
        this.staticChicks = [];
        this.helper.range(12).forEach(i => this.createStaticChick(i + 1));
    }

    getDefault = () => this.user?.isSubscribed ? '/icons/cx_vip_icon.svg' : `${this.environment}Image/chicks-avatars/chick_0`;
}
