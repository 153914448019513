import './location-landing.scss';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class Locations {
    locations = [];
    isModalOpen = false;
    isMapModalOpen = false;
    modalImages: string[] = [];
    mapModalImageKey: string | null = null;
    mapLocation: string | null = null;
    isLoading = true;

    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
    ) {
        this.router = router;
        this.websiteService = websiteService;
        this.pageContentAreaService = pageContentAreaService;
    }

    activate() {
        this.isLoading = true;
    }

    async attached() {
        try {
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            const pageId = pages.find((x) => x.name === 'Locations')?.id;
            if (!pageId) {
                console.warn('No "Locations" page found.');
                return;
            }

            const contentAreas = await this.pageContentAreaService.getByPageId(pageId);

            const storeSlugEntries = contentAreas
                .filter((c) => c.key.match(/^STORE_(\d+)_SLUG$/))
                .map((c) => {
                    const match = c.key.match(/^STORE_(\d+)_SLUG$/);
                    if (!match) return null;
                    const storeNumber = match[1];
                    let rawSlug = c.markup || '';
                    if (rawSlug.includes('<')) {
                        rawSlug = this.extractTextFromMarkup(rawSlug);
                    }
                    const slug = rawSlug.trim().toLowerCase();
                    return { storeNumber, slug };
                })
                .filter(Boolean);

            this.locations = storeSlugEntries.map(({ storeNumber, slug }) => {
                const slugUpper = slug.toUpperCase();

                const address = this.pageContentAreaService.getSiteString(`STORE_${slugUpper}_ADDRESS`) || '';
                const phone = this.pageContentAreaService.getSiteString(`STORE_${slugUpper}_PHONE`) || '';

                const hoursKey = `STORE_${slugUpper}_HOURS`;
                const daysKey = `STORE_${slugUpper}_DAYS`;

                const titleKey = `STORE_${slugUpper}_TITLE_CARD_LANDING`;
                const descriptionKey = `STORE_${slugUpper}_DESCRIPTION_CARD_LANDING`;

                const imageKeyLeft = `STORE_${slugUpper}_IMAGE_LEFT_CARD_LANDING`;
                const imageKeyRight = `STORE_${slugUpper}_IMAGE_RIGHT_CARD_LANDING`;

                return {
                    name: `STORE_${storeNumber}`,
                    slug: slug,
                    titleKey,
                    descriptionKey,
                    address,
                    locationPhone: phone,
                    locationHours: hoursKey,
                    locationDays: daysKey,
                    imageKeyLeft,
                    imageKeyRight
                };
            });

            const observer = new MutationObserver((mutations) => {
                mutations.forEach(() => {
                    const emptyParagraphs = document.querySelectorAll('p.font-size-inherit');
                    emptyParagraphs.forEach((paragraph) => {
                        if (!paragraph.textContent?.trim()) {
                            paragraph.remove();
                        }
                    });
                });
            });
            observer.observe(document.body, { childList: true, subtree: true });

        } catch (error) {
            console.error('Error loading store keys:', error);
        } finally {
            this.isLoading = false;
        }
    }


    extractTextFromMarkup(markup: string): string {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(markup, 'text/html');
            return doc.body.textContent?.trim() || '';
        } catch (error) {
            console.error('Error processing markup:', error);
            return '';
        }
    }

    private async extractImagePath(key: string): Promise<string | null> {
        try {
            const content = await this.pageContentAreaService.getByKey(key);

            if (content?.key) {
                return key;
            } else {
                return null;
            }
        } catch (error) {
            console.error(
                `Error retrieving the image key for the key ${key}:`,
                error,
            );
            return null;
        }
    }

    async onImageClick(locationSlug: string, event: Event) {
        event.stopPropagation();

        const selectedLocation = this.locations.find(
            (loc) => loc.slug === locationSlug
        );

        if (!selectedLocation) {
            console.error('Location not found for slug:', locationSlug);
            return;
        }

        const storeSlug = selectedLocation.slug;
        if (!storeSlug) {
            console.error('No slug found for location:', locationSlug);
            return;
        }

        const suffixes = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];
        const carouselImageKeys = [];
        const slugUpper = storeSlug.toUpperCase();

        for (const suffix of suffixes) {
            const key = `IMAGE_${suffix}_CAROUSEL_${slugUpper}`;
            const imagePath = await this.extractImagePath(key);
            if (imagePath) {
                carouselImageKeys.push(imagePath);
            } else {
                console.warn(`Invalid or missing key: ${key}`);
            }
        }

        this.modalImages = carouselImageKeys;

        if (this.modalImages.length === 0) {
            console.error('No images available for the carousel');
            return;
        }

        this.openModal();
    }



    async onMapImageClick(locationName: string, event: Event) {
        event.stopPropagation();

        const selectedLocation = this.locations.find(
            (location) => location.name === locationName,
        );

        if (!selectedLocation) {
            console.error('Location not found for:', locationName);
            return;
        }

        const storeNumberMatch = selectedLocation.name.match(/STORE_(\d+)/);
        const storeNumber = storeNumberMatch ? storeNumberMatch[1] : null;

        if (!storeNumber) {
            console.error(
                'The store name does not contain a valid number:',
                selectedLocation.name,
            );
            return;
        }

        const mapKey = `MAPS_UBICATION_${storeNumber}`;

        this.mapLocation = await this.extractMapLocation(mapKey);

        if (!this.mapLocation) {
            console.error(`No location found for the key: ${mapKey}`);
            return;
        }

        this.openMapModal();
    }

    private async extractMapLocation(key: string): Promise<string | null> {
        try {
            const content = await this.pageContentAreaService.getByKey(key);

            if (content?.markup) {
                const extractedText = this.extractTextFromMarkup(
                    content.markup,
                );
                return extractedText;
            } else {
                return null;
            }
        } catch (error) {
            console.error(
                `Error extracting the location for the key ${key}:`,
                error,
            );
            return null;
        }
    }

    openModal() {
        if (this.modalImages.length === 0) {
            console.error('No images available to display in the modal.');
            return;
        }

        this.isModalOpen = true;

        setTimeout(() => {
            new Swiper('.swiper-container', {
                modules: [Navigation],
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                loop: this.modalImages.length > 1,
                centeredSlides: true,
                spaceBetween: 20,
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                on: {
                    init: function () {
                        updatePaginationLines(this);
                    },
                    slideChange: function () {
                        updatePaginationLines(this);
                    },
                },
            });

            function updatePaginationLines(swiper: Swiper) {
                const paginationContainer = document.querySelector(
                    '.swiper-pagination-lines',
                );
                const numberContainer = document.querySelector(
                    '.swiper-pagination-numbers',
                );

                if (!paginationContainer || !numberContainer) return;

                paginationContainer.innerHTML = '';
                for (let i = 0; i < swiper.slides.length; i++) {
                    const line = document.createElement('div');
                    line.className = 'line';

                    if (i === swiper.realIndex) {
                        line.classList.add('active');
                    }

                    paginationContainer.appendChild(line);
                }

                const currentIndex = swiper.realIndex + 1;
                const totalSlides = swiper.slides.length;
                numberContainer.textContent = `${currentIndex}/${totalSlides}`;
            }
        }, 0);
    }

    openMapModal() {
        this.isMapModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
        this.modalImages = [];
    }

    closeMapModal(event?: Event) {
        if (event) {
            event.stopPropagation();
        }
        this.isMapModalOpen = false;
        this.mapModalImageKey = null;
    }

    preventClose(event: Event) {
        event.stopPropagation();
    }

    preventMapModalClose(event: Event) {
        event.stopPropagation();
    }

    onCardClick(locationSlug: string, event: Event) {
        event.stopPropagation();

        if (!locationSlug) {
            console.error('Empty slug provided when clicking on the card');
            return;
        }

        const matchingLocation = this.locations.find(
            (location) => location.slug === locationSlug
        );

        if (!matchingLocation) {
            console.error('No store found with slug:', locationSlug);
            return;
        }

        const storeNumberMatch = matchingLocation.name.match(/STORE_(\d+)/);
        const storeNumber = storeNumberMatch ? storeNumberMatch[1] : null;

        if (!storeNumber) {
            console.error(
                'No valid number in location.name:',
                matchingLocation.name
            );
            return;
        }

        this.router.navigateToRoute('location-detail', {
            locationName: locationSlug,
            storeNumber,
        });
    }

    goToLocation(slug: string, storeNumber: string) {
        if (!slug || !storeNumber) {
            console.error('The slug or store number is invalid:', {
                slug,
                storeNumber,
            });
            return;
        }

        try {
            this.router.navigateToRoute('location-detail', {
                locationName: slug,
                storeNumber,
            });
        } catch (error) {
            console.error('Error  during navigation:', error);
        }
    }

    getGoogleMapUrl(location: string): string {
        const baseUrl = 'https://www.google.com/maps/embed/v1/place';
        const apiKey = 'AIzaSyBm8YKDqPpvaJswz3QECEGl7066XcOoc4U';
        const encodedLocation = encodeURIComponent(location);
        return `${baseUrl}?key=${apiKey}&q=${encodedLocation}`;
    }

    navigateTo(path: string) {
        const baseUrl = window.location.origin;
        window.location.href = `${baseUrl}${path}`;
    }
}
