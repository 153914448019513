// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/chicks/crying_chick_empty.svg", import.meta.url);
// Module
var code = `<template>
    <div id="sitemap">
        <div class="container">
            <div class="header">
                <h1 if.bind="!selectedCategory" class="sitemap-title" skeleton-loading="is-loading.bind:pageLoading;">\${'CX_SITEMAP' | siteStrings}</h1>
                <cx-breadcrumbs if.bind="selectedCategory && width > 992" breadcrumbs.bind="breadcrumbs" exclude-forcing-active="true" force-parent-function="true"></cx-breadcrumbs>
                <cds-back-button
                    if.bind="selectedCategory && width <= 992"
                    class="back-button mb-2"
                    click.trigger="backButtonFunction()"
                ></cds-back-button>
                <div class="search-container" skeleton-loading="is-loading.bind:pageLoading;">
                    <cx-input-search search-query.two-way="searchQuery" button-handler.bind="clearSearch" handle-search.bind="searchDesktop"></cx-input-search>
                </div>
                <div class="button-search">
                    <cx-button
                        class="font-regular"
                        button-icon="search"
                        button-style.bind="'primary navbar-btn h-100 w-100'"
                        click.delegate="handleDrawerOpenClick()"
                    ></cx-button>
                </div>
            </div>
            <div if.bind="!selectedCategory && !noResults" class="d-grid category-content wrapper-grid">
                <div class="category-column" repeat.for="category of filteredCategories">
                    <div class="text-medium font-bold mb-2 category-title" skeleton-loading="is-loading.bind:pageLoading;">
                        <span if.bind="category.icon" class="material-icons-outlined category-icon" alt.bind="category.icon">
                            \${category.icon}
                        </span>
                        <img else class="category-icon" src.bind="category.url" alt="\${category.name} icon" loading="lazy">
                        <span class="category-name text-medium-static">
                            \${category.keyName | siteStrings:category.name}
                        </span>
                    </div>
                    <div class="sitemap-links \${\$index}" repeat.for="page of category.pages.slice(0, maxAmount)" skeleton-loading="is-loading.bind:pageLoading;">
                        <a class="sitemap-link link-styling text-truncate" href="\${page.slug | languageRoute & async}">
                            \${page.keyName | siteStrings:page.name}
                        </a>
                    </div>
                    <a if.bind="category.pages.length >= maxAmount" class="d-flex align-items-center text-semi-black all-pages-container" href="\${siteMapPageRoute}/\${helper.toRoute(category.name)}" click.delegate="categorySelected(category.name)" skeleton-loading="is-loading.bind:pageLoading;">
                        \${'CX_ALL_PAGES' | siteStrings}
                        <div class="material-icons text-semi-black arrow-container">arrow_right</div>
                    </a>
                </div>
            </div>
            <div if.bind="selectedCategory && !noResults" class="selected-category-container">
                <div class="category-title">
                    <span if.bind="selectedCategory.icon" class="material-icons-outlined category-icon" alt.bind="selectedCategory.icon">
                        \${selectedCategory.icon}
                    </span>
                    <img else class="category-icon" src.bind="selectedCategory.url" alt="\${selectedCategory.name} icon" loading="lazy">
                    <span class="category-name text-medium-static">\${selectedCategory.keyName | siteStrings:selectedCategory.name}</span>
                </div>
                <div class="d-grid sitemap-links-all">
                    <div class="sitemap-links" repeat.for="page of selectedCategory.pages">
                        <div class="text-truncate sitemap-link-container" repeat.for="childPage of page">
                            <a class="sitemap-link link-styling text-truncate" href="\${childPage.slug}">
                                \${childPage.keyName | siteStrings:childPage.name}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center no-results-container" if.bind="noResults">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="crying-chick" alt="crying_chick" loading="lazy">
                <span class="text-normal font-regular text-center empty-caption">\${'CX_SEARCH_NO_RESULTS' | siteStrings}</span>
            </div>
        </div>
        <div>
            <mdc-drawer type="modal" view-model.ref="drawer" class="drawer w-100">
                <mdc-drawer-content class="au-target mdc-content">
                    <div class="content-form">
                        <div class="h-100">
                            <button mdc-button click.delegate="handleDrawerOpenClick()" class="close-button button-transparent">
                                <mdc-icon mdc-icon class="material-icons close-icon">close</mdc-icon>
                                \${'CX_CLOSE' | siteStrings}
                            </button>
                        </div>
                        <div class="w-100">
                            <cx-input-search search-query.two-way="searchQuery"
                                handle-search.bind="searchMobile"
                                button-handler.bind="clearSearch"
                            ></cx-input-search>
                        </div>
                    </div>
                    <div if.bind="!selectedCategory && !noResults" class="d-grid category-content category-content__drawer wrapper-grid">
                        <div class="category-column" repeat.for="category of filteredCategories">
                            <div class="text-medium font-bold mb-2 category-title">
                                <span if.bind="category.icon" class="material-icons-outlined category-icon" alt.bind="category.icon">
                                    \${category.icon}
                                </span>
                                <img else class="category-icon" src.bind="category.url" alt="\${category.name} icon" loading="lazy">
                                <span class="category-name text-medium-static">\${category.keyName | siteStrings:category.name}</span>
                            </div>
                            <div class="sitemap-links \${\$index}" repeat.for="page of category.pages.slice(0, maxAmount)">
                                <a class="sitemap-link link-styling text-truncate" href="\${page.slug}">
                                    \${page.keyName | siteStrings:page.name}
                                </a>
                            </div>
                            <a if.bind="category.pages.length >= maxAmount" class="d-flex align-items-center text-semi-black all-pages-container" href="\${siteMapPageRoute}/\${helper.toRoute(category.name)}" click.delegate="categorySelected(category.name)">
                                \${'CX_ALL_PAGES' | siteStrings}
                                <div class="material-icons text-semi-black arrow-container">arrow_right</div>
                            </a>
                        </div>
                    </div>
                    <div if.bind="selectedCategory && !noResults" class="selected-category-container">
                        <div class="category-title">
                            <span if.bind="selectedCategory.icon" class="material-icons-outlined category-icon" alt.bind="selectedCategory.icon">
                                \${selectedCategory.icon}
                            </span>
                            <img else class="category-icon" src.bind="selectedCategory.url" alt="\${selectedCategory.name} icon" loading="lazy">
                            <span class="category-name text-medium-static">\${selectedCategory.keyName | siteStrings:selectedCategory.name}</span>
                        </div>
                        <div class="d-grid sitemap-links-all">
                            <div class="sitemap-links" repeat.for="page of selectedCategory.pages">
                                <div class="text-truncate sitemap-link-container" repeat.for="childPage of page">
                                    <a class="sitemap-link link-styling text-truncate" href="\${childPage.slug}">
                                        \${childPage.keyName | siteStrings:childPage.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center no-results-container" if.bind="noResults">
                        <img src="${___HTML_LOADER_IMPORT_0___}" class="crying-chick" alt="crying_chick" loading="lazy">
                        <span class="text-normal font-regular text-center empty-caption__drawer">\${'CX_SEARCH_NO_RESULTS' | siteStrings}</span>
                    </div>
                </mdc-drawer-content>
            </mdc-drawer>
        </div>
    </div>
</template>`;
// Exports
export default code;