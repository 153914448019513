import { Router } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import './cx-language-currency-selector.scss';

import { autoinject, computedFrom } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { LanguageService } from 'services/language-service';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { Language } from 'services/models/language/language';
import { CurrencyChangedEvent, LanguageAbbreviations } from 'resources/constants';
import { CdsAutocomplete } from '@chicksgroup/web-components';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class CxLanguageCurrencySelector {
    currencies: Currency[] = [];
    selectedCurrency: Currency;
    savedCurrency: Currency;
    languages: Language[] = [];
    selectedLanguage: Language;

    currencyAutocompleteRef: CdsAutocomplete;
    languageAutocompleteRef: CdsAutocomplete;

    currencyEvent: Subscription;
    languageEvent: Subscription;

    popularCurrencies = ['CAD', 'USD', 'EUR'];
    popularLanguages = ['ENG', 'ESP', 'DEU'];
    showDivider = true;

    typeaheadController = {
        active: true, onKeydown: () => { }
    };

    constructor(
        private sessionService: SessionService,
        private currencyService: CurrencyService,
        private languageService: LanguageService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
    }

    async attached() {
        try {
            await this.initData();
            setTimeout(() => {
                this.setDefaultCurrency();
                this.setDefaultLanguage();
            }, 1000);
        } catch (e) {
            console.log(e);
        }
    }

    detached() {
        this.currencyEvent?.dispose();
        this.languageEvent?.dispose();
    }

    async initData() {
        this.currencies = await this.currencyService.getCurrencyOptions();
        this.languages = await this.languageService.getLanguages();
        const currency = await this.sessionService.getCurrency();
        const language = await this.sessionService.getLanguage();
        this.selectedCurrency = this.currencies.find(curr => curr.code === currency);
        this.selectedLanguage = this.languages.find(lng => lng.id === language.id);
        this.handleLanguageForHtml();
        this.hanldeEventAggreators();
    }

    hanldeEventAggreators() {
        this.currencyEvent = this.eventAggregator.subscribe('selected-currency', payload => {
            const { currency } = payload;
            this.currencySelected(null, currency);
        });

        this.languageEvent = this.eventAggregator.subscribe('selected-language', payload => {
            const { language } = payload;
            this.languageSelected(null, language);
        });
    }

    setDefaultCurrency() {
        if (this.selectedCurrency) {
            this.currencies = JSON.parse(JSON.stringify(this.currencies));
            this.currencyAutocompleteRef.setValue(`${this.selectedCurrency.code} - ${this.selectedCurrency.description}`);
            this.currencyAutocompleteRef.setLeadingIcon(this.selectedCurrency.code);
            setTimeout(() => {
                this.currencies = JSON.parse(JSON.stringify(this.currencies));
            }, 500);
        }
    }

    setDefaultLanguage() {
        if (this.selectedLanguage) {
            this.languageAutocompleteRef.setValue(LanguageAbbreviations.find(lng => lng.abbreviation === this.selectedLanguage.name).name);
            setTimeout(() => {
                this.languages = JSON.parse(JSON.stringify(this.languages));
            }, 500);
        }
    }

    @computedFrom('languages')
    get languageAutocompleteItems() {
        const popular = this.filterPopularLanguages();
        const all = this.filterAllLanguages();
        let items = [
            {
                label: this.siteStringsValueConverter.toView('CX_POPULAR', 'Popular'),
                value: 'popular',
                items: popular,
                selected: false
            },
            {
                label: this.siteStringsValueConverter.toView('CX_ALL', 'All'),
                value: 'all',
                items: all,
                selected: false
            }
        ];
        if (!all?.length)
            items = [items[0]];
        return items;
    }

    private filterPopularLanguages() {
        return this.languages
            .filter(language => this.popularLanguages.includes(language.name))
            .map(language => this.createLanguageAutocompleteItem(language));
    }

    private filterAllLanguages() {
        return this.languages
            .filter(language => !this.popularLanguages.includes(language.name))
            .map(language => this.createLanguageAutocompleteItem(language));
    }

    private createLanguageAutocompleteItem(language: Language): { label: string; value: number; selected: boolean; } {
        return {
            label: LanguageAbbreviations.find(lng => lng.abbreviation === language.name).name,
            value: language.id,
            selected: language.id === this.selectedLanguage?.id
        };
    }

    @computedFrom('currencies')
    get currencyAutocompleteItems() {
        const popular = this.filterPopularCurrencies();
        const all = this.filterAllCurrencies();
        let items = [
            {
                label: this.siteStringsValueConverter.toView('CX_POPULAR', 'Popular'),
                value: 'popular',
                items: popular
            }, {
                label: this.siteStringsValueConverter.toView('CX_ALL', 'All'),
                value: 'all',
                items: all
            }
        ];
        if (!all?.length)
            items = [items[0]];
        return items;
    }

    private filterPopularCurrencies() {
        return this.currencies
            .filter(currency => this.popularCurrencies.includes(currency.code))
            .map(currency => this.createCurrencyAutocompleteItem(currency));
    }

    private filterAllCurrencies() {
        return this.currencies
            .filter(currency => !this.popularCurrencies.includes(currency.code))
            .map(currency => this.createCurrencyAutocompleteItem(currency));
    }

    private createCurrencyAutocompleteItem(currency: Currency): { label: string; value: number; icon: string; selected: boolean; } {
        return {
            label: `${currency.code} - ${currency.description}`,
            value: currency.id,
            icon: currency.code,
            selected: currency.id === this.selectedCurrency?.id
        };
    }

    async languageSelected(event = null, language = null) {
        if (['popular', 'all'].includes(event?.detail?.value)) {
            this.setDefaultLanguage();
            return;
        }
        this.selectedLanguage = this.languages.find(lng => [+event?.detail?.value, +language?.id].includes(lng.id));
        const prevLanguage = await this.sessionService.getLanguage();
        if (prevLanguage.id === this.selectedLanguage.id) return;
        this.sessionService.setLanguage(this.selectedLanguage);
        this.changeLanguageURL(prevLanguage, this.selectedLanguage);
        this.setDefaultLanguage();
        location.reload();
    }

    changeLanguageURL(prevLanguage: Language, selectedLanguage: Language) {
        const hrefLang = ['en', 'en-US'].includes(selectedLanguage.hrefLang) ? '' : selectedLanguage.hrefLang;
        let currentUrl = this.router.currentInstruction.fragment;
        if (!['en', 'en-US'].includes(prevLanguage.hrefLang)) {
            currentUrl = currentUrl.replace(prevLanguage.hrefLang, '');
        }
        this.router.navigate([hrefLang, currentUrl].join('/').split('/').filter(e => e).join('/'));
    }

    currencySelected(event = null, currency = null) {
        if (['popular', 'all'].includes(event?.detail?.value)) {
            this.setDefaultCurrency();
            return;
        }
        this.selectedCurrency = this.currencies.find(curr => [+event?.detail?.value, +currency?.id].includes(+curr.id));
        this.setDefaultCurrency();
        this.sessionService.saveCurrency(this.selectedCurrency.code);
        this.eventAggregator.publish(CurrencyChangedEvent, { currencySelected: this.selectedCurrency, avoidUpdate: true });
    }

    handleLanguageForHtml = () => {
        const language = this.selectedLanguage;
        if (language) {
            const htmlElement = document.querySelector('html');
            const metaElement = document.querySelector('meta[property~="og:locale"]');
            if (htmlElement) htmlElement.setAttribute('lang', language.hrefLang);
            if (metaElement) metaElement.setAttribute('content', language.hrefLang);
        }
    };

    closingLanguageAutocomplete() {
        this.setDefaultLanguage();
        this.showDivider = true;
    }

    closingCurrencyAutocomplete() {
        this.setDefaultCurrency();
        this.showDivider = true;
    }

    search() {
        this.showDivider = false;
    }
}
