// Module
var code = `<template>
    <div id="location-card">
      <div class="page-container">
        <div class="d-flex flex-column align-items-center" id="location-content">
          <div
            class="header store-content flex-column flex-sm-row w-100 d-flex align-items-end align-items-xl-center justify-content-between"
          >
            <div class="text-container">
              <h1 class="hero-txt">
                <cx-page-content-area
                  key.bind="titleKey"
                ></cx-page-content-area>
              </h1>
              <span class="hero-content mb-3">
                <cx-page-content-area
                  key.bind="descriptionKey"
                ></cx-page-content-area>
              </span>
              <div class="extra-info">
                <div class="info-block">
                  <div class="info-row">
                    <p class="info-content info-content-styled">
                      <a
                        href="#"
                        class="phone-link"
                        click.delegate="openMapModal()"
                        style="cursor: pointer"
                      >
                        <cx-page-content-area
                          key.bind="addressKey"
                        ></cx-page-content-area>
                      </a>
                    </p>
                  </div>
                </div>
                <div class="info-block">
                  <div class="info-row">
                    <p class="info-content info-content-styled">
                      <cx-page-content-area
                        key.bind="hoursDaysKey"
                      ></cx-page-content-area>
                    </p>
                  </div>
                </div>
                <div class="info-block">
                  <div class="info-row">
                    <p class="info-content info-content-styled">
                      <a
                        href="tel:\${phoneNumber}"
                        class="phone-link"
                      >
                        <cx-page-content-area
                          key.bind="contactKey"
                        ></cx-page-content-area>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div
                id="btn"
                class="btn-container btn-container-desktop d-flex"
              >
                <a
                  href.bind="tradePageRoute | languageRoute & async"
                  class="btn-w"
                >
                  <cx-button
                    class="text-large"
                    button-text.bind="'CX_BUY_ONLINE' | siteStrings"
                    button-style.bind="'secondary font-medium btn'"
                  ></cx-button>
                </a>
                <cx-button
                  class="text-large fisrt-btn btn-w"
                  button-text.bind="'CX_VISIT_OUR_STORE' | siteStrings"
                  button-style.bind="'primary primary-style font-medium btn'"
                  click.delegate="openMapModal()"
                  touchend.delegate="openMapModal()"
                ></cx-button>
              </div>
            </div>
  
            <div class="map-container">
              <cx-page-content-image
                key.bind="imageMapKey"
                click.delegate="openMapModal()"
                style="cursor: pointer"
              ></cx-page-content-image>
            </div>
          </div>
  
          <div
            class="custom-map-modal"
            class.bind="isMapModalOpen ? 'visible' : ''"
            click.delegate="closeMapModal()"
          >
            <div
              class="custom-map-modal-content"
              click.delegate="\$event.stopPropagation()"
            >
              <button
                class="custom-map-close"
                click.delegate="closeMapModal()"
              >
                &times;
              </button>
              <iframe
                class="custom-map-iframe"
                if.bind="mapIframeUrl"
                src.bind="mapIframeUrl"
                frameborder="0"
                allowfullscreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
  
          <div class="btn-container-mobile btn-container-mobile-override">
            <a
              href.bind="tradePageRoute | languageRoute & async"
              class="btn-mobile-override"
            >
              <cx-button
                button-text.bind="'CX_BUY_ONLINE' | siteStrings"
                button-style.bind="'secondary font-medium btn'"
              ></cx-button>
            </a>
            <cx-button
              class="btn-mobile-override"
              button-text.bind="'CX_VISIT_OUR_STORE' | siteStrings"
              button-style.bind="'primary primary-style font-bg btn'"
              click.delegate="openMapModal()"
              touchend.delegate="openMapModal()"
            ></cx-button>
          </div>
        </div>
  
        <div class="custom-section">
          <div class="custom-header">
            <h2 class="custom-title">
              <cx-page-content-area
                key="LOCATION_TITLE_2_LANDING"
              ></cx-page-content-area>
            </h2>
            <p class="custom-description">
              <cx-page-content-area
                key="LOCATION_DESCRIPTION_2_LANDING"
              ></cx-page-content-area>
            </p>
          </div>
  
          <div class="custom-cards">
            <cds-large-button
              icon="shopping_cart"
              class="custom-card"
              click.delegate="navigateTo('/buy')"
              label="Cart shop"
              title.bind="'CX_BUY' | siteStrings"
              skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
              <cx-page-content-area
                key="OFFER_DESCRIPTION_CARD_1"
                skeleton-loading.bind="isLoading"
              ></cx-page-content-area>
            </cds-large-button>
  
            <cds-large-button
              icon="sell"
              class="custom-card"
              click.delegate="navigateTo('/sell')"
              label="Sell"
              title.bind="'CX_SELL' | siteStrings"
              skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
              <cx-page-content-area
                key="OFFER_DESCRIPTION_CARD_2"
                skeleton-loading.bind="isLoading"
              ></cx-page-content-area>
            </cds-large-button>
  
            <cds-large-button
              icon="swap_horiz"
              class="custom-card"
              click.delegate="navigateTo('/swap/fiat')"
              label="swap fiat"
              title.bind="'CX_SELL_SWAP_FIAT' | siteStrings"
              skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
              <cx-page-content-area
                key="OFFER_DESCRIPTION_CARD_3"
                skeleton-loading.bind="isLoading"
              ></cx-page-content-area>
            </cds-large-button>
  
            <cds-large-button
              icon="swap_horizontal_circle"
              class="custom-card"
              click.delegate="navigateTo('/swap/crypto')"
              label="Swap crypto"
              title.bind="'CX_SELL_SWAP_CRYPTO' | siteStrings"
              skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
              <cx-page-content-area
                key="OFFER_DESCRIPTION_CARD_4"
                skeleton-loading.bind="isLoading"
              ></cx-page-content-area>
            </cds-large-button>
          </div>
        </div>
      </div>
    </div>
  </template>
  `;
// Exports
export default code;