interface FlipOptions {
  node: HTMLElement;
  from: number;
  to?: number;
  duration?: number;
  delay?: number;
  easeFn?: (pos: number) => number;
  systemArr?: string[] | number[];
  direct?: boolean;
  separator?: string | string[];
  separateOnly?: number;
  separateEvery?: number;
  isFloatingNumber?: boolean;
}

export class Flip {
    private beforeArr: number[];
    private afterArr: number[];
    private ctnrArr: HTMLElement[];
    private duration: number;
    private systemArr: string[] | number[];
    private easeFn: (pos: number) => number;
    from: number;
    to: number;
    private node: HTMLElement;
    private direct: boolean;
    private separator?: string | string[];
    private separateOnly: number;
    private separateEvery: number;
    private height?: number;
    private decimalsSeparator: string;
    private thousandsSeparator: string;
    private isFloatingNumber: boolean;

    constructor({
        node,
        from = 0,
        to,
        duration = 0.5,
        delay,
        easeFn = (pos: number) => ((pos /= 0.5) < 1 ? 0.5 * Math.pow(pos, 3) : 0.5 * (Math.pow(pos - 2, 3) + 2)),
        systemArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        direct = true,
        separator,
        separateOnly = 0,
        separateEvery = 3,
        isFloatingNumber = true
    }: FlipOptions) {
        this.beforeArr = [];
        this.afterArr = [];
        this.ctnrArr = [];
        this.duration = duration * 1000;
        this.systemArr = systemArr;
        this.easeFn = easeFn;
        this.from = from;
        this.to = to || 0;
        this.node = node;
        this.direct = direct;
        this.separator = separator;
        this.separateOnly = separateOnly;
        this.separateEvery = separateEvery;
        this.decimalsSeparator = '.';
        this.thousandsSeparator = ',';
        this.isFloatingNumber = isFloatingNumber;
        this.initHTML(this.maxLenNum(this.from, this.to), from);
        this.setSelect(this.from);
        if (to === undefined) return;
        if (delay) setTimeout(() => this.flipTo({ to: this.to, from: this.from }), delay * 1000);
        else this.flipTo({ to: this.to, from: this.from });
    }

    maxLenNum(aNum: number, bNum: number) {
        const maxLen = (aNum > bNum ? aNum : bNum).toString().length;
        return this.isFloatingNumber && maxLen < 2 ? 2 : maxLen;
    }

    num2PadNumArr = (num: number) => {
        const rawStr = num < 100 && this.isFloatingNumber ? num.toString().padStart(3, '0') : num.toString();
        const str2NumArr = (rawStr: string) => rawStr.split('').map(Number);
        return str2NumArr(rawStr).reverse();
    };

    initHTML(digits: number, num: number) {
        this.node.classList.add('number-flip');
        this.node.style.position = 'relative';
        this.node.style.overflow = 'hidden';
        const isSmallFloatingNumber = num < 100 && this.isFloatingNumber;
        if (isSmallFloatingNumber) {
            this.addDigitElement(0);
            this.addSeparator(this.decimalsSeparator);
        }
        for (let i = isSmallFloatingNumber ? 2 : 0; i < (isSmallFloatingNumber ? digits + 2 : digits); i++) {
            this.addDigitElement(i);

            if (isSmallFloatingNumber || !this.separator || (!this.separateEvery && !this.separateOnly) || i === digits - 1 || ((digits - i) % this.separateEvery !== 0 && digits - i - this.separateOnly !== 1))
                continue;
            let sprtrStr = '';
            if (digits - i - this.separateOnly === 1) {
                sprtrStr = this.decimalsSeparator;
            }

            if (((digits - i) % this.separateEvery) === 0 && sprtrStr === '') {
                sprtrStr = this.thousandsSeparator;
            }

            this.addSeparator(sprtrStr);
        }
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    resetHTML(to: number) {
        this.node.classList.remove('number-flip');
        this.node.setAttribute('style', '');
        this.ctnrArr = [];
        this.beforeArr = [];
        this.afterArr = this.num2PadNumArr(to);
        while (this.node.firstChild) {
            this.node.removeChild(this.node.firstChild);
        }
        this.initHTML(to.toString().length, to);
    }

    resize = () => {
        this.height = this.ctnrArr[0].clientHeight / (this.systemArr.length + 1);
        this.node.style.height = this.height + 'px';
        if (this.afterArr.length) this.frame(1);
        else
            for (let d = 0, len = this.ctnrArr.length; d < len; d += 1)
                this.draw({
                    digit: d,
                    per: 1,
                    alter: ~~(this.from / Math.pow(10, d)),
                });
    };

    addSeparator(sprtrStr: string) {
        const separator = document.createElement('div');
        separator.className = 'sprtr';
        separator.style.display = 'inline-block';
        separator.innerHTML = sprtrStr;
        this.node.appendChild(separator);
    }

    addDigitElement(pos: number) {
        const ctnr = document.createElement('div');
        ctnr.className = `.ctnr.ctnr${pos}`;
        ctnr.style.position = 'relative';
        ctnr.style.display = 'inline-block';
        ctnr.style.verticalAlign = 'top';
        [...this.systemArr, this.systemArr[0]].forEach((pos) => {
            const child = document.createElement('div');
            child.className = 'digit';
            child.innerHTML = `${pos}`;
            ctnr.appendChild(child);
        });
        this.ctnrArr.unshift(ctnr);
        this.node.appendChild(ctnr);
        this.beforeArr.push(0);
    }

    draw({ per, alter, digit }: { per: number; alter: number; digit: number }) {
        const newHeight = this.ctnrArr[0].clientHeight / (this.systemArr.length + 1);
        if (newHeight && this.height !== newHeight) this.height = newHeight;
        const from = this.beforeArr[digit];
        const modNum = (((per * alter + from) % 10) + 10) % 10;
        const translateY = `translateY(${-modNum * (this.height || 0)}px)`;
        this.ctnrArr[digit].style.webkitTransform = translateY;
        this.ctnrArr[digit].style.transform = translateY;
    }

    frame(per: number) {
        let temp = 0;
        for (let d = this.ctnrArr.length - 1; d >= 0; d -= 1) {
            const alter = this.afterArr[d] - this.beforeArr[d];
            temp += alter;
            this.draw({
                digit: d,
                per: this.easeFn(per),
                alter: this.direct ? alter : temp,
            });
            temp *= 10;
        }
    }

    flipTo({
        to,
        from,
        duration = 0,
        easeFn,
        direct,
    }: {
        to: number;
        from?: number
        duration?: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        easeFn?: () => any;
        direct?: boolean;
      }) {
        if (easeFn) this.easeFn = easeFn;
        if (direct !== undefined) this.direct = direct;
        if (from) this.from = from;
        if (this.num2PadNumArr(to).length !== this.ctnrArr.length) {
            this.resetHTML(to);
        }
        this.setSelect(to);
        this.beforeArr = this.num2PadNumArr(this.from);
        this.afterArr = this.num2PadNumArr(to);
        const start = Date.now();
        const dur = duration * 1000 || this.duration;
        const tick = () => {
            const elapsed = Date.now() - start;
            this.frame(elapsed / dur);
            if (elapsed < dur) requestAnimationFrame(tick);
            else {
                this.from = to;
                this.frame(1);
            }
        };
        requestAnimationFrame(tick);
    }

    setSelect(num: number) {
        this.num2PadNumArr(num).forEach((n: number, digit: number) => {
            if (!this.ctnrArr[digit]?.childNodes) return;
            for (let i = 0; i < this.ctnrArr[digit].childNodes.length; i += 1) {
                const el = this.ctnrArr[digit].childNodes[i] as HTMLElement;
                el.style.userSelect = i === n ? 'auto' : 'none';
            }
        });
    }
}
