// Module
var code = `<template>
    <div id="locations">
        <div class="main-container">
            <div class="locations-section">
                <div class="locations-container">
                    <div class="locations-header">
                        <h1 class="page-title">
                            <cx-page-content-area
                                key="LOCATION_TITLE_1_LANDING"
                                skeleton-loading.bind="isLoading"
                            ></cx-page-content-area>
                        </h1>
                        <p class="page-description">
                            <cx-page-content-area
                                key="LOCATION_DESCRIPTION_1_LANDING"
                                skeleton-loading.bind="isLoading"
                            ></cx-page-content-area>
                        </p>
                    </div>

                    <div class="locations-list">
                        <div
                            class="location-card"
                            repeat.for="location of locations"
                            click.delegate="onCardClick(location.slug, \$event)"
                        >
                            <div class="image-container">
                                <cx-page-content-image
                                    class="location-card-image"
                                    key.bind="location.imageKeyLeft"
                                    click.delegate="onImageClick(location.slug, \$event)"
                                    skeleton-loading.bind="isLoading"
                                ></cx-page-content-image>
                            </div>

                            <div class="location-card-content">
                                <h2 class="truncate-multilinee" id="ellipsis-truncate">
                                    <cx-page-content-area
                                        key.bind="location.titleKey"
                                        skeleton-loading.bind="isLoading"
                                    ></cx-page-content-area>
                                </h2>
                                <p class="location-card-address truncate-multiline">
                                    <cx-page-content-area
                                        key.bind="location.descriptionKey"
                                        skeleton-loading.bind="isLoading"
                                    ></cx-page-content-area>
                                </p>
                                <div class="location-card-hours">
                                    <span>
                                        <cx-page-content-area
                                            key.bind="location.locationDays"
                                            skeleton-loading.bind="isLoading"
                                        ></cx-page-content-area>
                                    </span>
                                    |
                                    <span>
                                        <cx-page-content-area
                                            key.bind="location.locationHours"
                                            skeleton-loading.bind="isLoading"
                                        ></cx-page-content-area>
                                    </span>
                                </div>
                            </div>

                            <div class="image-container-right">
                                <div class="image-wrapper">
                                    <cx-page-content-image
                                        class="location-card-image-right"
                                        key.bind="location.imageKeyRight"
                                        click.delegate="onMapImageClick(location.slug, \$event)"
                                        skeleton-loading.bind="isLoading"
                                    ></cx-page-content-image>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-container" if.bind="isModalOpen">
                        <div class="modal-backdrop" click.delegate="closeModal()"></div>
                        <div class="modal-content" click.delegate="preventClose(\$event)">
                            <button class="closee-button" click.delegate="closeModal()">
                                &times;
                            </button>

                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        repeat.for="imageKey of modalImages"
                                    >
                                        <cx-page-content-image
                                            class="locationn-card-image"
                                            key.bind="imageKey"
                                        ></cx-page-content-image>
                                    </div>
                                </div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>

                                <div class="swiper-pagination-numbers"></div>
                                <div class="swiper-pagination-lines"></div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="map-modal-container"
                        if.bind="isMapModalOpen"
                        click.delegate="closeMapModal(\$event)"
                    >
                        <div
                            class="map-modal-content"
                            click.delegate="preventMapModalClose(\$event)"
                        >
                            <button
                                class="modal-close-button"
                                click.delegate="closeMapModal(\$event)"
                            >
                                ✖
                            </button>

                            <iframe
                                class="google-map-frame"
                                if.bind="mapLocation"
                                src.bind="getGoogleMapUrl(mapLocation)"
                                frameborder="0"
                                allowfullscreen
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="offer-section">
                <div class="offer-header">
                    <h2 class="offer-title">
                        <cx-page-content-area
                            key="LOCATION_TITLE_2_LANDING"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </h2>
                    <p class="offer-description">
                        <cx-page-content-area
                            key="LOCATION_DESCRIPTION_2_LANDING"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </p>
                </div>

                <div class="offer-cards">
                    <cds-large-button
                        icon="shopping_cart"
                        class="offer-card-content"
                        click.delegate="navigateTo('/buy')"
                        label="Cart shop"
                        title.bind="'CX_BUY' | siteStrings"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                    >
                        <cx-page-content-area
                            key="OFFER_DESCRIPTION_CARD_1"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </cds-large-button>

                    <cds-large-button
                        icon="sell"
                        class="offer-card-content"
                        click.delegate="navigateTo('/sell')"
                        label="Sell"
                        title.bind="'CX_SELL' | siteStrings"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                    >
                        <cx-page-content-area
                            key="OFFER_DESCRIPTION_CARD_2"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </cds-large-button>
                    <cds-large-button
                        icon="swap_horiz"
                        class="offer-card-content"
                        click.delegate="navigateTo('/swap/fiat')"
                        label="swap fiat"
                        title.bind="'CX_SELL_SWAP_FIAT' | siteStrings"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                    >
                        <cx-page-content-area
                            key="OFFER_DESCRIPTION_CARD_3"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </cds-large-button>

                    <cds-large-button
                        icon="swap_horizontal_circle"
                        class="offer-card-content"
                        click.delegate="navigateTo('/swap/crypto')"
                        label="swap crypto"
                        title.bind="'CX_SELL_SWAP_CRYPTO' | siteStrings"
                        skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                    >
                        <cx-page-content-area
                            key="OFFER_DESCRIPTION_CARD_4"
                            skeleton-loading.bind="isLoading"
                        ></cx-page-content-area>
                    </cds-large-button>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;