// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/logo/chicksx-circle-logo-purple.svg", import.meta.url);
// Module
var code = `<template>
    <div id="trade-currency">
        <div class="container p-0">
             <section class="trade-steps d-none d-lg-flex">
                <compose view="./trade-steps-content.html" containerless></compose>
            </section>
            <cx-seo-dropdown header-title.bind="'CX_VIEW_MORE' | siteStrings:'View more'" is-loading.bind="pageLoading" is-open.two-way="isDropdownOpen">
                <!-- ################################################# -->
                <!-- Include here the "Live exchange rates" component. -->
                <!-- ################################################# -->
                 <section class="first-paragraph" if.bind="!pageLoading && (viewingAsAdmin || shouldRenderContent(['_FIRST_LEFT_PARAGRAPH', '_FIRST_LEFT_PARAGRAPH_BODY']))">
                    <cx-page-content-area
                        key="\${suffix}_FIRST_LEFT_PARAGRAPH"
                        class="content-title \${pageLoading ? 'd-flex' : ''}"
                        alternative-key="\${defaultSuffix}_FIRST_LEFT_PARAGRAPH"
                        skeleton-loading="is-loading.bind:pageLoading;"
                    ></cx-page-content-area>
                    <cx-page-content-area
                        key="\${suffix}_FIRST_LEFT_PARAGRAPH_BODY"
                        class="content-body \${pageLoading ? 'd-flex mt-3' : ''}"
                        alternative-key="\${defaultSuffix}_FIRST_LEFT_PARAGRAPH_BODY"
                        skeleton-loading="is-loading.bind:pageLoading;"
                    ></cx-page-content-area>
                </section>
                <section class="trade-steps d-flex d-lg-none">
                    <compose view="./trade-steps-content.html" containerless></compose>
                </section>
                <cx-paragraph-title
                    caption="TRADE_SUPPORTED_ASSETS_TITLE"
                    text="TRADE_SUPPORTED_ASSETS_TEXT"
                    caption-style="content-title dropdown-content"
                    text-style="content-body dropdown-content"
                ></cx-paragraph-title>
                <div class="crypto-price-cards-container d-flex">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <cx-crypto-price-card
                            repeat.for="currency of mostPopularCurrencies"
                            currency.bind="currency"
                            is-loading.bind="pageLoading"
                            learn-more-func.call="goToBuySell(currency.code)"
                            learn-more-route.bind="currency.redirectToRoute | languageRoute & async"
                            data-stats-currencies.bind="dataStatsCurrencies"
                            ready.from-view="currency.ready"
                            is-loaded.bind="allCardsLoaded"
                            cta-key="CX_EXCHANGE_NOW"
                        ></cx-crypto-price-card>
                        <div if.bind="pageLoading" class="extra-crypto-price-card d-flex align-items-center flex-column">
                            <div class="extra-card-text d-flex align-items-center flex-column">
                                <div class="line-1 skeleton"></div>
                                <div class="line-2 skeleton"></div>
                            </div>
                            <div class="learn-more-link skeleton"></div>
                        </div>
                        <div else class="extra-crypto-price-card" click.delegate="goToLearnMore(extraCryptoPriceCardRoute)">
                            <div>
                                <img src="${___HTML_LOADER_IMPORT_0___}" class="currency-logo" alt="Hero img" loading="lazy">
                                <div class="extra-card-text text-large-plus font-regular text-start dinamyc-line-height ">
                                    \${ 'CX_VIEW_MORE_CRYPTO_HERE' | siteStrings }
                                </div>
                            </div>
                            <a href.bind="extraCryptoPriceCardRoute | languageRoute & async" class="learn-more-link-extra-card text-medium-static font-regular d-flex align-items-center">
                                <span>\${ 'CX_EXCHANGE_NOW' | siteStrings }</span>
                                <span class="material-icons ms-2">
                                    arrow_forward
                                </span>
                            </a>
                        </div>
                        <div class="extra-crypto-price-card-space"></div>
                    </div>
                    <cx-timer
                        time-limit.bind="5"
                        auto-restart.bind="autoRestart"
                        time-left.two-way="timeLeft"
                        element.ref="timer"
                        show.bind="false"
                    ></cx-timer>
                </div>
                <cx-paragraph-title
                    caption="TRADE_BENEFITS_TITLE"
                    text="TRADE_BENEFITS_TEXT"
                    caption-style="content-title dropdown-content"
                    text-style="content-body dropdown-content"
                ></cx-paragraph-title>
                <div class="learn-more-cards-container">
                    <cx-learn-more-card
                        repeat.for="card of learnMoreCards"
                        page-loading.bind="pageLoading"
                        title.bind="card.title"
                        paragraph.bind="card.paragraph"
                        icon.bind="card.icon"
                        icon-alt.bind="card.iconAlt"
                        button-caption="\${card.buttonCaption | siteStrings}"
                        href.bind="card.redirectRoute|languageRoute & async"
                        text-to-aria-label.bind="card.ariaLabel"
                    ></cx-learn-more-card>
                </div>
                 <cx-page-content-area
                    key="\${ contentDropdownKey }"
                    class="seo-dropdown-content"
                 ></cx-page-content-area>
            </cx-seo-dropdown>
        </div>
    </div>
</template>
`;
// Exports
export default code;