// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/customer-portal/upload-support.svg", import.meta.url);
// Module
var code = `<template>
    <div id="verification" class="position-relative p-regular">
        <h1 class="text-extra-large font-medium" skeleton-loading="is-loading.bind:contentLoading;width:210px;height:50px;">Verification</h1>

        <div class="d-flex flex-column gap-regular">
            <!-- Phone Verification -->
            <cds-large-button
                class.bind="'is-active' |in: phoneVerification"
                title.bind="'CX_PHONE_VERIFICATION' | siteStrings"
                description.bind="phoneSubtitle"
                icon="phone_iphone"
                click.trigger="!phoneVerification ? toggleVerificationTab('phone', \$event) : null"  
                is-clickable.bind="!phoneVerification"
                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
                <div slot="content" show.bind="phoneVerification && !contentLoading" class="au-animate animate-fade-in animate-fade-out input-outer-container w-100" click.delegate="\$event.stopPropagation()">
                    <div class="input-inner-container phone-element-container">
                        <cx-phone-input if.bind="user"
                            view-model.ref="phoneElement"
                            phone-input.two-way="phoneInput"
                            loading.two-way="loadingPhoneInput"
                            input-style="form-control input-password"
                            require-sms.bind="true"
                            user.two-way="userPhone"
                            click.delegate="\$event.stopPropagation()"
                            copy-text-bellow.bind="'CX_PHONE_INFO_MESSAGE' | siteStrings"
                        ></cx-phone-input>
                    </div>
                </div>
            </cds-large-button>

            <!-- Mail Verification -->
            <cds-large-button
                class.bind="'is-active' |in: emailVerification"
                title.bind="'CX_EMAIL_VERIFICATION' | siteStrings"
                description.bind="emailSubtitle"
                icon="email"
                click.trigger="handleTabClick(\$event, 'email', user.emailConfirmed)"  
                is-clickable.bind="!emailVerification"
                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
                <div slot="content" if.bind="!user.emailConfirmed" show.bind="emailVerification" class="w-100 au-animate animate-fade-in animate-fade-out input-outer-container" click.delegate="\$event.stopPropagation()">
                    <div class="input-inner-container" skeleton-loading="is-loading.bind:contentLoading;">
                        <span if.bind="!emailSent" class="text-darkergray text-small mt-2">\${'CX_SEND_EMAIL_WITH_CODE_MESSAGE' | siteStrings}</span>
                        <span else class="text-success-green text-small mt-2">\${'CX_CONFIRMATION_EMAIL_SENT_MESSAGE' | siteStrings}</span>
                        <form submit.delegate="catchSubmit()" class="position-relative mt-2 email-container" click.delegate="\$event.stopPropagation()">
                            <mdc-text-field
                                outlined
                                autocomplete="off"
                                value.two-way="user.email & validate"
                                id="email"
                                class="w-100 input-password cx-form-control pe-5 \${ emailSent ? 'cx-form-control--warning' : '' } \${ showCheckMark ? 'cx-form-control--success' : ''} \${ showErrorMark ? 'cx-form-control--error' : ''}"
                                required.bind="true"
                                placeholder.bind="'CX_ENTER_YOUR_EMAIL' | siteStrings"
                                focusin.delegate="emailUpdatedOnFocusIn()"
                                keyup.delegate="emailUpdatedOnKeyPress(\$event)"
                                disabled.bind="emailSent"
                                ref="emailInput"
                            ></mdc-text-field>
                            <span class="circle-icon check text-large material-icons-outlined" alt="green check" if.bind="showCheckMark">check_circle</span>
                            <span class="circle-icon error text-large material-icons" alt="error" if.bind="showErrorMark">error_outline</span>
                            <span class="circle-icon pending text-large material-icons-outlined" alt="waiting" if.bind="emailSent">watch_later</span>
                            <span repeat.for="rule of rules.results" if.bind="showEmailValidationError" class="ms-auto mt-1 align-self-center text-small text-red font-regular">\${'CX_ENTER_VALID_EMAIL_PROMPT' | siteStrings}</span>
                        </form>
                        <div if.bind="emailSent" class="row mt-2">
                            <div class="col-12 text-purple text-small">
                                <span class="link-styling cursor-pointer" click.delegate="sendConfirmationEmail()">\${'CX_RESEND_VERIFICATION_CODE' | siteStrings}</span>
                                <span class="text-medium px-1">|</span>
                                <span class="link-styling cursor-pointer" click.delegate="cancelEmailVerification()">\${'CX_CANCEL' | siteStrings}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </cds-large-button>

            <!-- ID Verification -->
            <cds-large-button
                class.bind="'is-active' |in: idVerification"
                title.bind="'CX_ID_VERIFICATION' | siteStrings"
                description.bind="idSubtitle"
                icon="fingerprint"
                click.trigger="handleTabClick(\$event, 'id', user.idVerified)"
                is-disabled.bind="!user.emailConfirmed && !user.phoneNumberConfirmed && !user.idVerified"
                is-clickable.bind="!idVerification"
                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
                <div slot="content" show.bind="idVerification && !user.idVerified" class="au-animate animate-fade-in animate-fade-out w-100 input-outer-container" click.delegate="\$event.stopPropagation()">
                    <div class="input-inner-container id-verification" skeleton-loading="is-loading.bind:contentLoading;">
                        <cx-veriff
                            element.ref="idVeriffViewModel"
                            user.bind="user"
                            class="d-block"
                            on-cancel.bind="closedVeriff.bind(\$this)"
                        ></cx-veriff>
                    </div>
                </div>
            </cds-large-button>
            
            <!-- Address Verification -->
            <cds-large-button
                class.bind="'is-active' |in: addressVerification"
                title.bind="'CX_ADDRESS_VERIFICATION' | siteStrings"
                description.bind="addressSubtitle"
                icon="fmd_good"
                click.trigger="handleTabClick(\$event, 'address', user.addressVerified)"  
                is-disabled.bind="!user.idVerified"
                is-clickable.bind="!addressVerification"
                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
                <div slot="content" if.bind="!user.addressVerified" show.bind="addressVerification" class="au-animate animate-fade-in animate-fade-out input-outer-container w-100">
                    <div class="input-inner-container" skeleton-loading="is-loading.bind:contentLoading;">
                        <cx-veriff
                            view-model.ref="veriffViewModel"
                            hidden-button="true"
                            show.bind="!user.addressVerified"
                            user.bind="user"
                            if.bind="!poaHidden"
                        ></cx-veriff>
                        <div else>
                            <span if.bind="!fileUploaded" class="text-darkergray text-regular mt-2">\${'CX_UPLOAD_DOCUMENT_FRONT' | siteStrings}</span>
                            <form click.delegate="isAddressInReview ? '' : triggerDocumentSelector()" submit.delegate="catchSubmit()" class="verification-form position-relative mt-2 \${ isAddressInReview ? 'cursor-default' : 'cursor-pointer' }">
                                <mdc-text-field
                                    outlined
                                    autocomplete="off"
                                    id="fileHolder"
                                    ref="fileHolderElement"
                                    class="w-100 input-password cx-form-control \${ user.addressVerified ? 'cx-form-control--success' : '' } \${ showErrorMark ? 'cx-form-control--error' : '' } \${ isAddressInReview ? 'cx-form-control--warning' : '' }"
                                    required.bind="true"
                                    placeholder.bind="'CX_SELECT_DOCUMENT_PROMPT' | siteStrings"
                                    value.bind="fileName"
                                    disabled
                                    ref="addressInput"
                                ></mdc-text-field>
                                <span if.bind="!user.addressVerified && !showErrorMark && !isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon cursor-pointer">upload</span>
                                <span if.bind="user.addressVerified" class="circle-icon check text-large material-icons-outlined" alt="green check">check_circle</span>
                                <span if.bind="showErrorMark" class="circle-icon error text-large material-icons" alt="error">error_outline</span>
                                <span if.bind="isAddressInReview" class="circle-icon_close text-large material-icons-outlined" alt="waiting">watch_later</span>
                                <span if.bind="isAddressInReview && !addressUploading" class="material-icons-outlined text-cx-purple upload-icon cursor-pointer" click.delegate="clearAddressVerification()">close</span>
                            </form>
                        </div>
                    </div>
                </div>
            </cds-large-button>

            <!-- Additional Information -->
            <cds-large-button
                class.bind="'is-active' |in: additionalInformationVerification"
                if.bind="user.additionalInformation || user.idVerified"
                title="Additional Information"
                description.bind="'CX_ADDITIONAL_INFORMATION_DESCRIPTION' | siteStrings"
                icon.bind="!additionalInformationVerification ? 'add_circle_outline' : 'add_circle'"
                click.trigger="!additionalInformationVerification ? toggleVerificationTab('additionalInformation', \$event) : null" 
                is-disabled.bind="!user.idVerified"
                is-clickable.bind="!additionalInformationVerification"
                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
            >
                <div slot="content" show.bind="additionalInformationVerification" class="au-animate animate-fade-in animate-fade-out input-outer-container w-100">
                    <div
                        id="drag-drop-additional-info"
                        class="input-inner-container additional-information cx-drop-zone-outside position-relative"
                        skeleton-loading="is-loading.bind:contentLoading;"
                    >
                        <div class="file-input">
                            <cx-file-input
                                text.bind="fileTextKey | siteStrings:fileText"
                                upload-function-name="handleFileUpload"
                                uploading.bind="fileUpload"
                                state.two-way="fileState"
                                selectable="true"
                            ></cx-file-input>
                        </div>
                        <div id="drop-zone" class="flex-column position-absolute">
                            <div class="drop-zone-lg col d-flex flex-column align-items-center justify-content-center">
                                <div if.bind="isAllFilesDropLoaded" class="d-flex align-items-center flex-column">
                                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="cloud icon" loading="lazy" />
                                    <div class="drop-file-text">\${'CX_DROP_FILE_HERE' | siteStrings}</div>
                                </div>
                                <div if.bind="!isAllFilesDropLoaded" class="d-flex align-items-center w-100 flex-column">
                                    <div class="uploading-text">\${'CX_UPLOADING' | siteStrings}...</div>
                                    <mdc-linear-progress open.bind="true" buffer="1"></mdc-linear-progress>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </cds-large-button>
        </div>
   
        <input type="file"
            id="documentSelector"
            files.bind="selectedFiles"
            input.delegate="isAddressInReview ? '' : handleAddressUpload(selectedFiles)"
            class="d-none"
        >
        <div class="verification-footer">
            <cx-progress-bar
                num-steps.bind="3"
                is-loading.bind="contentLoading"
                is-verification-page.bind="'true'"
                user.bind="user"
                class="d-none d-md-block d-lg-none"
            ></cx-progress-bar>
            <cx-progress-bar
                num-steps.bind="3"
                is-loading.bind="contentLoading"
                user.bind="user"
                is-vertical.bind="'true'"
                class="d-sm-block d-md-none"
            ></cx-progress-bar>
        </div>
    </div>
</template>
`;
// Exports
export default code;