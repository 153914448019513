import './home.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';
import { CurrencyService } from 'services/currency-service';
import { CustomerService } from 'services/customer-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { CoinbaseService } from 'services/coinbase-service';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { ToastService } from 'services/toast-service';
import { Helper } from 'resources/helpers/helper';
import { DataCurrencyStats } from 'services/models/currency/dataStatsCurrenciesInterface';
import { CurrencyChangedEvent } from 'resources/constants';
import { PageContentArea } from 'services/models/page/pageContentArea';

@autoinject()
export class Home {
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private router: Router,
        private currencyService: CurrencyService,
        private customerService: CustomerService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private coinbaseService: CoinbaseService,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private toastService: ToastService,
        private helpers: Helper
    ) {
    }

    faqPageRoute;
    baseAwsEndpoint;
    sizeChangeSubcription;
    learnMoreCards = [
        {
            title: 'CX_HOME_LEARN_MORE_CARD_TITLE_1',
            paragraph: 'CX_HOME_LEARN_MORE_CARD_TEXT_1',
            icon: '/home/global-icon.svg',
            iconAlt: 'Global icon',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'CX_HOME_LEARN_MORE_CARD_REDIRECT_1',
            redirectRoute: '',
        },
        {
            title: 'CX_HOME_LEARN_MORE_CARD_TITLE_2',
            paragraph: 'CX_HOME_LEARN_MORE_CARD_TEXT_2',
            icon: '/home/lowest-rates.svg',
            iconAlt: 'Lowest icon',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'CX_HOME_LEARN_MORE_CARD_REDIRECT_2',
            redirectRoute: '',
        },
        {
            title: 'CX_HOME_LEARN_MORE_CARD_TITLE_3',
            paragraph: 'CX_HOME_LEARN_MORE_CARD_TEXT_3',
            icon: '/home/cashout-icon.svg',
            iconAlt: 'Cashout icon',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'CX_HOME_LEARN_MORE_CARD_REDIRECT_3',
            redirectRoute: '',
        }
    ];

    @bindable() mostPopularCurrencies = [];
    @bindable() isLoading = true;
    @bindable() timeLeft;
    timer;
    currencies;
    tradePageRoute;
    currencyBase;
    currencyChangeSubscription;
    currencyRates;
    availableCurrencies;
    urlParams;
    user;
    autoRestart: boolean = true;
    dataStatsCurrencies: DataCurrencyStats[] = [];
    pageLoadedSubscriber: Subscription;
    @bindable() pageContentArea: PageContentArea[];
    adminViewSubscriber: Subscription;
    viewingAsAdmin: boolean = false;

    async attached() {
        try {
            this.helpers.addLoadingComponent('home');
            await this.initializeRoutes();
            this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
            this.user = await this.sessionService.refreshProfile();
            this.handleEmailConfirmation();

            this.sessionService.activeCurrencies$.subscribe(async activeCurrencies => {
                if (activeCurrencies.length === 0) return;
                this.availableCurrencies = activeCurrencies;
                await this.loadCurrencyData();
                await this.loadStatsData();
            });
        } finally {
            this.helpers.validateLoading('home');
        }
    }

    async initializeRoutes() {
        await this.websiteService.getManyRoutes(this, ['faq', 'trade']);
        const homePage = await this.websiteService.getPage('home');
        this.pageContentArea = await this.pageContentAreaService.getByPageId(homePage.id);
    }

    async loadCurrencyData() {
        const [currentCurrency, currencyRates] = await Promise.all([
            this.sessionService.getCurrency(),
            this.coinbaseService.getExchangeRates('USD')
        ]);
        this.currencyBase = this.availableCurrencies.find(currency => currency.code === currentCurrency);
        this.currencyRates = currencyRates.data.rates;
    }

    async loadStatsData() {
        this.currencies = ['BTC', 'ETH', 'SOL', 'ADA'];
        [this.mostPopularCurrencies, this.dataStatsCurrencies] = await Promise.all([
            this.currencyService.getMultipleCurrencyStatsByCode(this.currencies),
            this.currencyService.getStatsDataCurrency(this.currencies)
        ]);

        this.mostPopularCurrencies.map(currency => {
            currency.priceUSD = currency.price;
            currency.price = currency.price * this.currencyRates[this.currencyBase.code];
            currency.base = this.currencyBase;
        });

        this.handleSubscriptions();
        this.learnMoreCards = await Promise.all(this.learnMoreCards.map(async (card) => {
            let cardParam = (await this.pageContentAreaService.getByKey(card.redirectParam))?.markup;
            cardParam = this.helpers.removeHtmlElements(cardParam);
            return {
                ...card,
                redirectRoute: cardParam ? `${this.faqPageRoute}?card=${cardParam}` : this.faqPageRoute
            };
        }));
        this.isLoading = false;
    }

    handleSubscriptions() {
        this.currencyChangeSubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            this.currencyBase = payload.currencySelected;
            this.timer?.au.controller.viewModel.resetCountdown();
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.isLoading = false;
        });

        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    async handleEmailConfirmation() {
        if (!this.user?.emailConfirmed && this.urlParams?.email && this.urlParams?.token) {
            try {
                const [firstName, successful] = await Promise.all([
                    this.sessionService.getUserFirstName(this.urlParams.email),
                    this.customerService.confirmEmail(this.urlParams.email, this.urlParams?.token)
                ]);
                if (successful) {
                    this.toastService.showToast(undefined, `Thank you ${firstName}, your email has been verified successfully.`, 'success');
                } else {
                    this.toastService.showToast('Error', 'Email token invalid or expired. Please ensure you open the latest email verification link or try again.', 'error');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    detached() {
        this.timer?.au.controller.viewModel.stop();
        this.currencyChangeSubscription?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.adminViewSubscriber?.dispose();
    }

    async goToFaq() {
        this.router.navigate(this.faqPageRoute);
    }

    async goToBuySell(crypto: string) {
        this.customerService.saveCurrencyPriceCode(crypto);
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            window.Intercom('showNewMessage', 'Hello, I need help');
        }
    }

    async countdownFinished() {
        try {
            this.mostPopularCurrencies = await this.currencyService.getMultipleCurrencyStatsByCode(this.currencies);
            this.mostPopularCurrencies.forEach(currency => {
                currency.priceUSD = currency.price;
                currency.price = currency.price * this.currencyRates[this.currencyBase.code];
                currency.base = this.currencyBase;
            });
        } catch (e) {
            this.autoRestart = false;
        }
    }

    goToLearnMore(e) {
        this.router.navigate(e);
    }

    @computedFrom('pageContentArea')
    get shouldRenderDropdownContent() {
        const key = 'HOME_DROPDOWN_CONTENT';
        const foundContent = this.pageContentArea?.find(x => x.key === key);
        if (!foundContent && !foundContent?.markup && !foundContent?.imagePath) {
            return false;
        }
        return true;
    }
}
