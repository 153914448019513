import { NavigationInstruction, Next } from 'aurelia-router';

export class TradePreactivateStep {
    run(navigationInstruction: NavigationInstruction, next: Next) {
        if (navigationInstruction.config.name.split('-').includes('trade')) {
            const baseUrl = navigationInstruction.fragment.split('/').filter(x => x);
            if (navigationInstruction.config.data && navigationInstruction.config.data.language) {
                baseUrl.shift();
            }
            const exceptions = ['buy', 'sell', 'swap', 'exchange', 'trade'];
            if (baseUrl.length > 0 && !exceptions.includes(baseUrl[0])) {
                navigationInstruction.router.navigateToRoute('404');
                return next.cancel();
            }
        }
        return next();
    }
}
