// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/phone_filled_v2.svg", import.meta.url);
// Module
var code = `<template>
    <div id="subscription">
        <div if.bind="!user.isSubscribed && !contentLoading && isDesktop" class="subscription__tabs--container">
            <cds-tabs
                tabs.bind="tabs"
                selected.trigger="setTab(\$event)"
                ref="tabsElement"
            ></cds-tabs>
        </div>
        <cx-card class.bind="\`main-container-subscriptions \${ user.isSubscribed ? 'main-container-subscriptions__subscribed' : '' }\`">
            <section if.bind="user.isSubscribed" id="subscribed" class="subscription__parent">                
                <div class="main-containers">
                    <article class="subscription__section subscription__benefits main-containers__benefits m-0">
                        <div class="subscription__benefits--header">
                            <h2 class="subscription__title" skeleton-loading="is-loading.bind:contentLoading;width:80px;height:28px;">
                                \${ isDesktop ? 'CX_SUBSCRIPTION' : 'CX_SETTINGS' | siteStrings:(isDesktop?'Subscription':'Settings') }
                            </h2>
                            <p class="subscription__desc m-0" if.bind="user.renewal" skeleton-loading="is-loading.bind:contentLoading;height:16px;">\${'CX_VIP_MEMBER_SINCE' | siteStrings}: \${userSubscriptionInfo.subscribedAt | dateFormatter:'format':'MMMM yyyy'}. \${'CX_MEMBERSHIP_END' | siteStrings} \${userSubscriptionInfo.renewalDate | dateFormatter:'format':'MMMM Do, yyyy'}</p>
                            <p class="subscription__desc subscription__notice" else skeleton-loading="is-loading.bind:contentLoading;height:16px;">
                                \${'CX_VIP_MEMBER_SINCE' | siteStrings}: \${userSubscriptionInfo.subscribedAt | dateFormatter:'format':'MMMM yyyy'}. \${'CX_SUBSCRIPTION_CANCELED_ON' | siteStrings:null:[dateFormatterValueConverter.toView(userSubscriptionInfo.renewalDate, 'format', 'MMMM Do, yyyy')]}
                                <span class="subscription__link" click.delegate="setRenewalFunction()">\${'CX_ENABLE_AUTO_RENEWAL' | siteStrings}</span>
                            </p>
                        </div>
                        <div class="main-containers__benefits--boxes cds-large-button--benefit">
                            <div
                                if.bind="contentLoading || loadingSubscriptionBenefits"
                                repeat.for="1 of 4"
                                skeleton-loading="is-loading.bind:contentLoading || loadingSubscriptionBenefits;height:72px;"
                            ></div>
                            <cds-large-button
                                else
                                class="benefit"
                                icon.bind="benefit.icon | iconPath"
                                alt="benefit_icon"
                                is-clickable.bind="false"
                                repeat.for="benefit of subscription.benefits"
                            >
                                <cx-page-content-area
                                    class="d-block subscription__box-desc"
                                    key="\${ benefit.boxText }"
                                ></cx-page-content-area>
                            </cds-large-button>
                        </div>
                    </article>
                    <article class="subscription__section--manage subscription__membership">
                        <div>
                            <h2 class="subscription__title" skeleton-loading="is-loading.bind:contentLoading;width:80px;height:28px;">
                                \${ isDesktop ? 'CX_SETTINGS' : 'CX_SUBSCRIPTION' | siteStrings:(isDesktop?'Settings':'Subscription')}
                            </h2>
                            <p class="subscription__desc m-0" skeleton-loading="is-loading.bind:contentLoading;height:16px;">\${'CX_SETTINGS_SUBTITLE' | siteStrings:'Review cashback and manage membership settings'}</p>
                        </div>
                        <div class="subscription__section--manage-options">
                            <cds-large-button
                                title.bind="sectionData[sections.Cashback].titleKey|siteStrings:sectionData[sections.Cashback].title"
                                description.bind="totalEarned | fiatCurrencyFormat:null:preferredCurrency & async"
                                onclick.call="setSection(sections.Cashback)"
                                is-disabled.bind="totalEarned <= 0"
                                icon="currency_exchange"
                                class="w-100 mw-100"
                                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                            ></cds-large-button>
                            <cds-large-button
                                title.bind="sectionData[sections.Manage].titleKey|siteStrings:sectionData[sections.Manage].title"
                                description.bind="sectionData[sections.Manage].descriptionKey|siteStrings:sectionData[sections.Manage].description"
                                onclick.call="setSection(sections.Manage)"
                                icon="manage_accounts"
                                class="w-100 mw-100"
                                skeleton-loading="is-loading.bind:contentLoading;force-div:true;height:var(--large-button-skeleton);"
                            ></cds-large-button>
                        </div>
                    </article>
                </div>
            </section>
            <section show.bind="!user.isSubscribed" id="unsubscribed" class="subscription__parent">
                <div class="subscription__top">
                    <h1 class="subscription__title" skeleton-loading="is-loading.bind:contentLoading;height:28px;">
                        \${'CX_SUBSCRIPTION' | siteStrings}
                    </h1>
                    <div class="d-flex align-items-center subscription__desc" skeleton-loading="is-loading.bind:contentLoading;height:16px;">
                        <span>\${'CX_MEMBERSHIP_ANUAL_PLAN' | siteStrings}</span>
                        <span if.bind="user.hasFreeTrial" class="highlight-yellow">\${'CX_MONTH_FREE' | siteStrings}</span>
                    </div>
                </div>
                <div class="main-containers">
                    <div class="main-containers__benefits">
                        <div class="d-block d-lg-none">
                            <h2 class="subscription__title" skeleton-loading="is-loading.bind:contentLoading;height:28px;">
                                \${'CX_SUBSCRIPTION_BENEFITS' | siteStrings:'Benefits'}
                            </h2>
                            <p class="subscription__desc" skeleton-loading="is-loading.bind:contentLoading;height:16px;">
                                \${'CX_SUBSCRIPTION_BENEFITS_SUBTITLE' | siteStrings:'Subscribe to Chicks VIP and receive exclusive perks'}
                            </p>
                        </div>
                        <div class="main-containers__benefits--boxes cds-large-button--benefit">
                            <div
                                if.bind="contentLoading || loadingSubscriptionBenefits"
                                repeat.for="1 of 4"
                                skeleton-loading="is-loading.bind:contentLoading || loadingSubscriptionBenefits;height:72px;"
                            ></div>
                            <cds-large-button
                                else
                                class="benefit"
                                icon.bind="benefit.icon | iconPath"
                                alt="benefit_icon"
                                is-clickable.bind="false"
                                repeat.for="benefit of subscription.benefits"
                            >
                                <cx-page-content-area
                                    class="d-block subscription__box-desc"
                                    key="\${ benefit.boxText }"
                                ></cx-page-content-area>
                            </cds-large-button>
                        </div>
                    </div>
                    <div class="main-containers__payment-method \${ !['cash-in-mail', 'cash-in-person'].includes(selectedPaymentMethod.paymentMethod.reference) ? '' : 'pb-0' }">                            
                        <div if.bind="!user.isSubscribed && !contentLoading && !isDesktop" class="subscription__tabs--container">
                            <cds-tabs
                                tabs.bind="tabs"
                                selected.trigger="setTab(\$event)"
                                selected-index.from-view="1"
                            ></cds-tabs>
                        </div>
                        <div class="payment-container \${ contentLoading ? 'border-0' : '' }">
                            <h2 class="subscription__title d-none d-lg-block" skeleton-loading="is-loading.bind:contentLoading;height:28px;">\${'CX_PAYMENT_METHOD' | siteStrings}</h2>
                            <p class="subscription__desc" skeleton-loading="is-loading.bind:contentLoading;height:16px;">\${'CX_PAYMENT_METHOD_SUBTITLE_SUBSCRIPTION' | siteStrings:null:[optionData.productType.toLowerCase()]}</p>
                            <div class="d-flex flex-column flex-sm-row payment-container__selector">
                                <cx-payment-method-selector
                                    class="payment-container__selector--payment-methods"
                                    selected-payment-method.two-way="selectedPaymentMethod"
                                    given-currency-selector.bind="preferredCurrency"
                                    input-class.bind="'payment-method-selector-class'"
                                    ignore-route.bind="true"
                                    is-cart-page.bind="true"
                                    cc-recently-saved.from-view="ccRecentlySaved"
                                    auto-select.bind="false"
                                    outlined.bind="false"
                                    skeleton-loading="is-loading.bind:contentLoading;"
                                ></cx-payment-method-selector>
                                <div
                                    show.bind="contentLoading"
                                    class="payment-container__button--skeleton"
                                    skeleton-loading="is-loading.bind:contentLoading;"
                                ></div>
                                <cds-button
                                    type="button"
                                    click.trigger="startOrder()"
                                    show.bind="!paymentProcessing && !contentLoading"
                                >\${ 'CX_PAY_NOW_SUBSCRIPTION' | siteStrings:null:[convertedTotalPrice] }</cds-button>
                                <cx-circular-progress
                                    if.bind="paymentProcessing"
                                    class="payment-container__progress"
                                    show-loading-text.bind="false"
                                    size.bind="50"
                                ></cx-circular-progress>
                            </div>
                            <div if.bind="user.hasFreeTrial">
                                <p class="m-0 mb-2 payment-container__message" skeleton-loading="is-loading.bind:contentLoading;">
                                    \${'CX_RECEIVE_BENEFITS_MESSAGE_FIRST' | siteStrings:null:[dateFormatterValueConverter.toView(freeTrialExpiresAt, 'format', 'MMMM Do')]}
                                </p>
                                <p class="m-0 payment-container__message" skeleton-loading="is-loading.bind:contentLoading;">
                                    \${'CX_RECEIVE_BENEFITS_MESSAGE_SECOND' | siteStrings:null:[dateFormatterValueConverter.toView(freeTrialExpiresAt, 'format', 'MMMM Do'),convertedTotalPrice,optionData.type]}
                                </p>    
                            </div>                                
                            <p else class="m-0 payment-container__message" skeleton-loading="is-loading.bind:contentLoading;">
                                \${'CX_RECURRING_BILLING_MESSAGE' | siteStrings:null:[convertedTotalPrice, optionData.type]}
                            </p>
                            <cx-line class="cx-line--thin cx-line--full subscription-divider second" skeleton-loading="is-loading.bind:contentLoading;"></cx-line>
                            <p class="m-0 payment-container__message default-anchor" skeleton-loading="is-loading.bind:contentLoading;">
                                \${'CX_BY_CONTINUING' | siteStrings}
                                <a class="payment-container__message--link" href="\${termsOfServicePageRoute}" target="_blank">\${'CX_TERMS_OF_SERVICE' | siteStrings}</a>
                                \${'CX_AND' | siteStrings}
                                <a class="payment-container__message--link" href="\${privacyPolicyPageRoute}" target="_blank">\${'CX_PRIVACY_POLICY' | siteStrings}</a>.
                            </p>
                        </div>
                        <div class="payment-information \${ !['cash-in-mail', 'cash-in-person'].includes(selectedPaymentMethod.paymentMethod.reference) && !showStepNotes ? 'p-0' : 'pt-3'}">                                
                            <cx-cash-in-mail
                                user.bind="user"
                                exchange.bind="exchange"
                                tracking-number-valid.bind="trackingNumberValid"
                                files-list.bind="userDocumentList"
                                view-model.ref="cashInMailRef"
                                if.bind="selectedPaymentMethod.paymentMethod.reference === 'cash-in-mail'"
                            ></cx-cash-in-mail>
                            <cx-cash-in-person
                                if.bind="selectedPaymentMethod.paymentMethod.reference === 'cash-in-person'"
                                user.bind="user"
                                meetup-address-valid.two-way="meetupAddressValid"
                                location.two-way="location"
                                selected-delivery.two-way="selectedDelivery"
                                date.two-way="date"
                                time.two-way="time"
                                loading.bind="loadingComponent"
                                exchange.bind="exchange"
                            ></cx-cash-in-person>
                            <cx-info-banner if.bind="showStepNotes">
                                <div innerhtml.bind="selectedPaymentMethod.paymentStepNotes"></div>
                            </cx-info-banner>
                            <cx-veriff
                                view-model.ref="veriffViewModel"
                                hidden-button.bind="true"
                                user.bind="user"
                                class="d-block mb-0"
                            ></cx-veriff>
                        </div>
                    </div>
                </div>
            </section>
        </cx-card> 
        <cx-card class.bind="'main-container-subscriptions phone-container'" if.bind="!contentLoading && selectedPaymentMethod && user && !phoneVerified && !loadingFromAuth">
            <div class="phone-verification d-flex align-items-center">
                <img src="${___HTML_LOADER_IMPORT_0___}" width="15" height="28" alt="Phone" loading="lazy">
                <span class="text-medium no-font-bp font-medium">
                    \${'CX_MOBILE_VERIFICATION' | siteStrings}
                </span> 
            </div>
            <cx-phone-input
                use-label-margin.bind="false"
                view-model.ref="phoneElement"
                phone-input.two-way="phoneInput"
                loading.two-way="loadingPhoneInput"
                input-style="form-control input-password"
                require-sms.bind="true"
                user.two-way="userPhone"
                copy-text-bellow.bind="'CX_PHONE_INFO_MESSAGE' | siteStrings"
            ></cx-phone-input>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;