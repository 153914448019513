// Module
var code = `<template>
    <section id="editorial-policy" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_TITLE'" class="title-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_LAST_UPDATED'" class="text-end last-update text-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTENT_PURPOSE_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTENT_PURPOSE_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTENT_CREATION_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTENT_CREATION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_ACCURACY_AND_TRANSPARENCY_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_ACCURACY_AND_TRANSPARENCY_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_COMPLIANCE_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_COMPLIANCE_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_DISCLAIMER_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_DISCLAIMER_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_UPDATES_AND_REVISIONS_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_UPDATES_AND_REVISIONS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTACT_INFORMATION_TITLE'" class="text-title font-bold title-1" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'EDITORIAL_POLICY_CONTACT_INFORMATION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>`;
// Exports
export default code;