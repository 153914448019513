// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/instagram-2.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/facebook-2.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/discord-2.svg", import.meta.url);
// Module
var code = `<template>
    <div id="author">
        <div class="container justify-content-center">
            <div if.bind="width > 992" class="d-flex breadcrumbs divider container flex-row">
                <cx-breadcrumbs breadcrumbs.bind="breadcrumbs" class="ps-0"></cx-breadcrumbs>
            </div>
            <a
                else
                href.bind="breadcrumbs[breadcrumbs.length - 2].href"
                class="return d-inline-flex align-items-center text-purple divider breadcrumbs"
            >
                <span class="material-icons back-button rounded-circle me-1">
                    chevron_left
                </span>
                Back
            </a>
            <div class="container justify-content-center d-flex">
                <img class="author-avatar" src="\${authorInfo.profileImagePath ? \`\${baseAwsEndpoint}\${authorInfo.profileImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\`}" alt="Author profile" loading="lazy">
            </div>
            <div class="container justify-content-center d-flex">
                <a href="\${authorInfo.instagramUrl}" target="_blank" rel="noopener noreferrer">
                    <img src="${___HTML_LOADER_IMPORT_0___}" alt="Instagram" class="social-icon" loading="lazy">
                </a>
                <a href="\${authorInfo.facebookUrl}" target="_blank" rel="noopener noreferrer">
                    <img src="${___HTML_LOADER_IMPORT_1___}" alt="Facebook" class="social-icon" loading="lazy">
                </a>
                <a href="\${authorInfo.discordUrl}" target="_blank" rel="noopener noreferrer">
                    <img src="${___HTML_LOADER_IMPORT_2___}" alt="Discord" class="social-icon" loading="lazy">
                </a>
            </div>
            <div class="container justify-content-center d-flex author-name">
                \${authorInfo.firstName | fullname: authorInfo.lastName}
            </div>
            <div class="container justify-content-center d-flex author-about text-center">
                \${authorInfo.about}
            </div>
            <div class="container justify-content-center d-flex author-description">
                \${authorInfo.bio}
            </div>
            <div class="container justify-content-center d-flex">
                <a href.bind="blogRoute" class="articles-link">
                    View All Articles
                </a>
            </div>
            <div class="container divider">
                <hr>
            </div>
            <div class="container d-flex justify-content-center">
                <cx-posts-cards blogs.bind="relatedPost" blog-route.bind="blogRoute"></cx-posts-cards>
            </div>
            <div class="container text-center mb-5 pagination-author">
                <cx-pagination count.two-way="count" page.two-way="page"></cx-pagination>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;