// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/wallet.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-wallet-input">
        <mdc-expandable class="purchase-flow-expandable" id="wallet-expandable" view-model.ref="walletInputExpandable"  open.two-way="expandableOpen">
            <div class="text-large-plus  d-flex align-items-center" slot="caption">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="caption-icon" alt="wallet" loading="lazy">
                \${'CX_WALLET_ADDRESS' | siteStrings}
            </div>
            <div show.bind="expandableOpen">
                <div class="d-flex align-items-center flex-row mb-4">
                    <span class="d-block text-darkergray explanation-text">
                        \${'CX_WALLET_ADDRESS_EXPLANATION' | siteStrings}
                    </span>
                    <cds-icon
                        id="address-input-tooltip"
                        icon="contact_support"
                        class="ms-1 vertical-align-sub"
                        color="#b8e5f2"
                        variant.bind="addressInputTooltipVariant"
                        mouseenter.trigger="addressInputTooltipVariant = 'filled'"
                        mouseleave.trigger="addressInputTooltipVariant = 'outlined'"
                    ></cds-icon>
                    <cds-tooltip
                        reference-id="address-input-tooltip"
                        placement="top-end"
                        show-arrow.bind="true"
                        class="wallet-address-tooltip"
                    >
                        \${'CX_WALLET_ADDRESS_TOOLTIP' | siteStrings}
                    </cds-tooltip>
                </div>
                <div class="position-relative d-inline-block \${requiresExtraInput ? 'double-input-wallet-address' : 'w-100'}">
                    <mdc-text-field
                        outlined
                        required.bind="true"
                        value.bind="walletAddress"
                        placeholder.bind="'CX_ENTER_WALLET_ADDRESS' | siteStrings:'':[cryptoCurrencySelected.description]"
                        keyup.delegate="walletUpdatedOnKeyPress(\$event)"
                        focusin.delegate="walletOnFocusIn()"
                        focusout.delegate="walletOnFocusOut()"
                        class="input-password w-100 cx-form-control \${!walletAddress || showMiniSpinnerWallet || !firedFunction ? '' : showErrorCheckMarkAddress ? 'cx-form-control--error' : 'cx-form-control--success'}"                    
                        ref="walletAddressInput"
                    ></mdc-text-field>
                    <span
                        class="circle-icon check material-icons-outlined"
                        alt="green check"
                        if.bind="showGreenCheckMarkAddress"
                    >check_circle</span>
                    <span
                        class="circle-icon error material-icons"
                        alt="error"
                        if.bind="showErrorCheckMarkAddress"
                    >error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerWallet" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div><div class="position-relative d-inline-block double-input-extra-field" if.bind="requiresExtraInput">
                    <mdc-text-field
                        outlined
                        required.bind="true"
                        value.bind="extraField"
                        placeholder="\${extraInputLabel}"                    
                        keyup.delegate="validateExtraInputAddress() & debounce:1000"
                        class="input-password w-100 cx-form-control \${isValidatingExtraField || !extraField || showMiniSpinnerExtraInput ? '' : !extraFieldValid ? 'cx-form-control--error' : 'cx-form-control--success'}"
                        ref="extraFieldInput"
                    ></mdc-text-field>
                    <span
                        class="circle-icon check material-icons-outlined"
                        alt="green check"
                        if.bind="!isValidatingExtraField && (extraFieldValid && extraField)"
                    >check_circle</span>
                    <span
                        class="circle-icon error material-icons"
                        alt="error"
                        if.bind="!isValidatingExtraField && (!extraFieldValid && extraField)"
                    >error_outline</span>
                    <mdc-circular-progress if.bind="showMiniSpinnerExtraInput" class="mini-spinner-icon" size="25" stroke-width="2"></mdc-circular-progress>
                </div>
            </div>
        </mdc-expandable>
    </div>
</template>
`;
// Exports
export default code;