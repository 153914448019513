// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/summary.svg", import.meta.url);
// Module
var code = `<template>
    <div id="delivery-date" class="box purchase-flow-spacing">
        <div if.bind="exchange.receivingPaymentMethod.paymentMethod.reference == 'cash-in-mail'" class="text-large-plus d-flex align-items-center">
            <span class="material-icons-outlined text-purple mail-icon">mail</span>
            Cash in Mail
        </div>
        <div if.bind="exchange.receivingPaymentMethod.paymentMethod.reference == 'cash-in-person'" class="text-large-plus d-flex align-items-center">
            <img src="${___HTML_LOADER_IMPORT_0___}" alt="Summary" loading="lazy" class="me-1">
            Cash in Person
        </div>
        <div if.bind="exchange.receivingPaymentMethod.paymentMethod.reference == 'paypal'" class="text-large-plus d-flex align-items-center">
            <span class="material-icons-outlined text-purple mail-icon">mail</span>
            PayPal
        </div>
        <div class="d-flex flex-column flex-sm-row box-container">
            <div repeat.for="deliveryOption of deliveryMethods" class="date-box \${deliveryOption.selected ? 'box-selected' : '' } d-flex flex-column align-items-center justify-content-center" click.delegate="selectDeliveryOption(deliveryOption)">
                <img src="\${baseAwsEndpoint}\${deliveryOption.imagePath}" alt.bind="deliveryOption.displayName" loading="lazy" class="box-icon \${deliveryOption.selected ? 'icon-selected' : '' }">
                <span class="title">\${deliveryOption.displayName}</span>
                <span class="sub-title">\${getDeliveryDate(deliveryOption)}</span>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;