// Module
var code = `<template>
    <div id="cx-payment-method-selector">
        <div class="text-end align-items-center position-relative dropdown">
            <button
                id="payment-selector-\${id}"
                class="cx-select cx-form-select payment-method-selector w-100 position-relative text-start cursor-pointer align-items-center d-flex text-normal"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                data-bs-auto-close="outside"
                aria-expanded="false"
                type="button"
                ref="paymentSelectorElement"
                focusin.delegate="handleTextFieldFocusIn()"
            >
                <div class="d-flex align-items-center w-100">
                    <div class="col payment-method-text-field">
                        <mdc-text-field
                            id="payment-selector-search-\${id}"
                            view-model.ref="input"
                            autocomplete="on"
                            label.bind="selectorLabel"
                            class="w-100 p-0 me-3 cx-form-select \${inputClass} \${selectedPaymentMethod ? 'selected' : ''}"
                            change.delegate="handleSearchChange(\$event)"
                            keyup.delegate="handleSearchChange(\$event)"
                        >
                            <mdc-icon mdc-text-field-icon leading class="material-icons material-icons-outlined">
                                <img
                                    if.bind="selectedPaymentMethod.paymentMethod.cardInfo.type && !focusIn"
                                    class="payment-method-image"
                                    src="\${handleCardImage(selectedPaymentMethod.paymentMethod.cardInfo.type)}"
                                    loading="lazy"
                                    alt="payment method image">
                                <img
                                    if.bind="selectedPaymentMethod.paymentMethod.imagePath && !selectedPaymentMethod.paymentMethod.cardInfo.type && !focusIn"
                                    class="aling-items-left payment-method-image"
                                    src="\${baseAwsEndpoint}\${selectedPaymentMethod.paymentMethod.imagePath}"
                                    loading="lazy"
                                    alt="currency-flag">
                                <span
                                    if.bind="!selectedPaymentMethod.paymentMethod.imagePath || focusIn"
                                    class="material-symbols-outlined"
                                >
                                    monetization_on
                                </span>
                            </mdc-icon>
                        </mdc-text-field>
                    </div>
                </div>
                <cds-icon class="expand-icon" icon="arrow_drop_down"></cds-icon>
            </button>
            <div id="payment-dropdown-menu-\${id}" class="dropdown-menu text-start expanded-selected \${dynamicDropdownStyles}" aria-labelledby="payment-selector-\${id}">
                <div class="w-100 d-flex justify-content-center align-items-center spin-loader p-5" if.bind="isLoading">
                    <mdc-circular-progress size="40" stroke-width="2"></mdc-circular-progress>
                </div>
                <section data-simplebar class="payment-method-scrollbar \${listContainerStyle}" if.bind="!isLoading">
                    <div>
                        <div class="row p-0 m-0" if.bind="!showCheckoutContainer && !showPayoutMethodForm">
                            <div
                                class="col-12 p-0 m-0"
                                repeat.for="paymentMethodWebsite of displayPaymentMethods"
                                show.bind="search ? paymentMethodWebsite.show : (checkForHidingPaymentMethods(paymentMethodWebsite) && !showAllRecent)"
                            >
                                <div class="mb-2 text-small d-flex justify-content-between align-items-center" if.bind="!search && \$index === 0 && recentThreePaymentMethods.length > 0">
                                    <div class="text-small text-darkergray">
                                        Recent
                                    </div>
                                    <div 
                                        if.bind="recentPaymentMethods.length > 3" 
                                        class="font-light d-flex align-items-center cursor-pointer text-purple"
                                        click.trigger="toggleAllRecent(true)"
                                    >
                                        See All
                                        <span class="material-icons next-button rounded-circle ms-1 me-2 text-regular text-purple">
                                            chevron_right
                                        </span>
                                    </div>
                                </div>
                                <div class="text-small mb-2 text-darkergray" if.bind="(!search || search === '') && \$index === recentThreePaymentMethods.length">
                                    Popular
                                </div>
                                <div class="text-small mb-2 text-darkergray" if.bind="(!search || search === '') && \$index === recentThreePaymentMethods.length + 3">
                                    All
                                </div>
                                <div class="text-small mb-2 text-darkergray" if.bind="search && search !== '' && \$index === firstResultIndex">
                                    Results
                                </div>
                                <cx-payment-method-item
                                    payment-method.bind="paymentMethodWebsite"
                                    selected-payment-method.bind="selectedPaymentMethod"
                                    is-last-item.bind="search && search !== '' ? \$index === lastResultIndex : \$last"
                                    index.bind="\$index"
                                ></cx-payment-method-item>
                            </div>
                            <!-- SHOW ALL RECENT PAYMENT METHODS -->
                            <div
                                class="col-12 p-0 m-0 payment-method-item-wrapper \${user && paymentMethod.paymentMethod.name === selectedPaymentMethod.paymentMethod.name ? 'position-relative' : ''}"
                                repeat.for="paymentMethod of recentPaymentMethods"
                                show.bind="!search && showAllRecent"
                            >
                                <div class="d-flex justify-content-between text-small pe-3 mb-2" if.bind="\$index === 0">
                                    <div class="text-small text-darkergray">Saved</div>
                                    <div class="text-purple font-light d-flex align-items-center cursor-pointer" click.trigger="toggleAllRecent(false)">
                                        <span class="material-icons text-purple rounded-circle ms-1 text-regular">chevron_left</span>
                                        Back
                                    </div>
                                </div>
                                <cx-payment-method-item
                                    payment-method.bind="paymentMethod"
                                    selected-payment-method.bind="selectedPaymentMethod"
                                    is-last-item.bind="\$last"
                                ></cx-payment-method-item>
                            </div>
                        </div>
                        <div class="credit-card-container \${ isEditingPaymentMethod ? 'opacity-25 pe-none' : '' }" if.bind="showCheckoutContainer">
                            <cx-credit-card-form
                                loading.bind="loading"
                                save-function.call="saveCCFunction()"                        
                                card-number.two-way="cardNumber"
                                card-month-year.two-way="cardMonthYear"
                                card-cvv.two-way="cardCvv"
                                billing.two-way="billing"
                                card-type.two-way="cardType"
                                stage.two-way="stageCardForm"
                                card-info.bind="cardInfoToForm"
                                user.bind="user"
                                name-on-card.bind="nameOnCard"
                                is-exp-date-valid.from-view="isExpDateValid"
                                billing-address-is-valid.from-view="billingAddressIsValid"
                                is-cvv-valid.from-view="isCvvValid"
                                is-cc-recently-saved.bind="!!ccRecentlySaved"
                                ignore-route.bind="ignoreRoute"
                            ></cx-credit-card-form>
                        </div>
                        <cx-payoutable-form
                            if.bind="showPayoutMethodForm"
                            payment-method.bind="selectedPaymentMethod"
                        ></cx-payoutable-form>
                    </div>
                    <div class="d-flex flex-column" if.bind="!resultsFound">
                        <cx-no-results
                            message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                        ></cx-no-results>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;