// Module
var code = `<template>
    <div id="cx-crypto-price-card" class="cursor-pointer" click.delegate="goToLearnMore()">
        <div class="head-container-card">
            <div class="d-flex flex-column">
                <img src="\${ baseAwsEndpoint }\${ currency.imagePath }" alt="\${ currency.symbol } icon" class="currency-logo" skeleton-loading="is-loading.bind:isLoading;">
                <span class="currency-name text-medium-static font-regular" skeleton-loading="is-loading.bind:isLoading;height:20px;width:100px;">\${ currency.name }</span>
            </div>
            <div class="d-flex flex-column justify-content-between price-container">
                <div class="d-flex align-items-center height-23 \${ !isLoaded ? 'skeleton-price skeleton-loading' : '' }" skeleton-loading="is-loading.bind:isLoading;width:130px;">
                    <span class="currency-price text-large-plus-static font-bold">\${ currency.base.symbol }</span>
                    <span id="currency-price-\${ currency.code }" class="currency-price text-large-plus-static font-bold"></span>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center \${ upwardTrend ? 'upward-trend' : 'downward-trend' } \${ !isLoaded ? 'skeleton-percentage skeleton-loading' : '' } height-margin margin-left-row-numbers" skeleton-loading="is-loading.bind:isLoading">
            <span id="arrow-trend-\${ currency.code }"></span>
            <div class="d-flex align-items-center">
                <span id="percentage-variation-\${ currency.code }" class="text-medium-static font-regular"></span>
                <span class="text-medium-static font-regular">%</span>
            </div>
        </div>
        <div class="container-graph" id.bind="'graph-' + currency.code" show.bind="isLoaded"></div>
        <div class="container-graph" show.bind="!isLoaded"></div>
        <div class="footer-container-card">
            <a aria-describedby="Learn more about \${currency.name}" href.bind="learnMoreRoute" click.delegate="learnMoreFunc()" skeleton-loading="is-loading.bind:isLoading;"
                class="learn-more-link text-medium-static font-regular d-flex align-items-center">
                <span>\${ ctaKey | siteStrings}</span>
                <span class="material-icons ms-2">
                    arrow_forward
                </span>
            </a>
        </div>
    </div>
</template>
`;
// Exports
export default code;