import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { FilterBlogsDto } from './models/blogs/requests/filter-blogs-dto';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class BlogService {
    path = 'BlogPost';
    blogs;
    blogsCount;

    constructor(private api: ApiService, private helper: Helper) {}

    async getPublishedBlogPosts(recordQuantity: number, page: number) {
        return await this.api.doGet(`${this.path}/byWebsite/${recordQuantity}/${page}?websiteShortCode=CX`);
    }

    async getPublishedBlogPostsCount() {
        if (!this.blogsCount) {
            this.blogsCount = await this.api.doGet(this.path + '/BlogsCountByWebsite?websiteShortCode=CX');
        }
        return this.blogsCount;
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getBlogBySlug(slug, forceFetch = false) {
        return await this.helper.fetchData(this.api, `${this.path}/GetBySlug/${slug}?websiteShortCode=CX`, 'getBlogBySlug', forceFetch);
    }

    async updateBlogPostViewCounter(id) {
        return await this.api.doPut(this.path + `/${id}/AddViewsCounter`, {});
    }

    async getStickyBlogPost() {
        return await this.api.doGet(`${this.path}/StickyPost?websiteShortCode=CX`);
    }

    async getByTag(tag, page, pageSize) {
        return await this.api.doGet(`${this.path}/GetByTag/${tag}/${pageSize}/${page}`);
    }

    async getBySearchByContent(expression, pageSize, page) {
        return await this.api.doGet(`${this.path}/SearchByContent/${expression}/${pageSize}/${page}`);
    }

    async filterBlogs(filterBlogsDto: FilterBlogsDto) {
        return await this.api.doPost(`${this.path}/DynamicFilter`, filterBlogsDto);
    }
}
