import './author.scss';
import { autoinject, observable } from 'aurelia-framework';
import { getAWSBucketEndpoint, apiEndpoint } from 'environment';
import { WebsiteService } from 'services/website-service';
import { BlogService } from 'services/blog-service';
import { BlogAuthorService } from 'services/blog-author-service';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class Author {
    constructor(
        private websiteService: WebsiteService,
        private blogService: BlogService,
        private blogAuthorService: BlogAuthorService,
        private router: Router,
        private eventAggregator: EventAggregator) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    baseAwsEndpoint: string;
    blog;
    environment = apiEndpoint();
    blogRoute;
    relatedPost;
    blogs;
    count = 1;
    isAttached = false;
    @observable page = 1;
    startingIndex: number;
    perPage;
    endingIndex;
    authorInfo;
    blogsCount;
    width;
    sizeChanged;
    breadcrumbs = [];
    homeRoute;

    async activate(params, routeConfig) {
        this.authorInfo = await this.blogAuthorService.getByAlias(params.alias);
        if (!this.authorInfo || routeConfig.route.includes('*childRoute')) {
            this.router.navigateToRoute('404');
        }
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.blogRoute = pages.find(x => x.name === 'Blog')?.routeName ?? 'blog';
        this.homeRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        this.isAttached = true;
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.width >= 579 && this.width < 992) {
            this.perPage = 2;
        } else if (this.width < 579) {
            this.perPage = 3;
        } else {
            this.perPage = 3;
        }
        this.breadcrumbs = [
            { title: 'Home', href: this.homeRoute },
            { title: 'Blogs', href: this.blogRoute },
            { title: 'Authors Page' }
        ];
        await this.pageChanged();
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            if (this.width >= 579 && this.width < 992) {
                this.perPage = 2;
            } else if (this.width < 579) {
                this.perPage = 3;
            } else {
                this.perPage = 3;
            }
            await this.pageChanged();
        });
    }

    async pageChanged() {
        if (this.isAttached) {
            this.blogs = await this.blogService.filterBlogs(
                {
                    page: this.page,
                    pageSize: this.perPage,
                    published: true,
                    authorAlias: this.authorInfo?.alias,
                }
            );
            this.relatedPost = this.blogs.blogPosts;
            this.blogsCount = this.blogs.totalCount;
            this.count = Math.ceil(this.blogsCount / this.perPage);
            if (this.page > this.count && this.count > 0) {
                this.page = this.count;
            }
        }
    }

    async tagClickHandler(tag) {
        this.router.navigateToRoute('blog-category', { category: tag.slug });
    }
}
