// Module
var code = `<template>
    <section id="copyright-policy" class="legal-page">
        <div class="header font-bold text-purple">
            <div class="header-title text-start">Copyright Policy</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_RIGHT_TITLE'" class="text-end last-update">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_INTRODUCTION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_CONTENT'" class="text-content">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_CONTACT_INFORMATION_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_CONTACT_INFORMATION_CONTENT'" class="text-content margened-paragraph">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_COUNTER_TITLE'" class="text-title font-bold">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COPYRIGHT_POLICY_COUNTER_CONTENT'" class="text-content margened-paragraph">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;