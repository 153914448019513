import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { PageByWebsite } from './models/page/pageByWebsite';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class WebsiteService {
    path = 'Website';
    pages: PageByWebsite[];

    constructor(private api: ApiService,
                private helper: Helper) {
    }

    async getPagesByWebsiteShortcode(): Promise<PageByWebsite[]> {
        if (this.pages) {
            return this.pages;
        }
        this.pages = await this.helper.fetchData(this.api, this.path + '/Pages?websiteShortCode=CX', 'getPagesByWebsiteShortcode');
        return this.pages;
    }

    async getAllBeforeExecution(func) {
        await this.getPagesByWebsiteShortcode();
        return func();
    }

    getPage = async(name : string): Promise<PageByWebsite> | null => await this.getAllBeforeExecution(() => this.pages.find(x => x.name.toLowerCase() === name.toLowerCase()));

    getRoute = async(name : string) => (await this.getPage(name))?.routeName;

    getRouteOrDefault = async(name : string, def : string|number = -1) => (await this.getRoute(name)) ?? (def !== -1 ? def : this.helper.toRoute(name));

    getManyBy = async(_this, names : string[], extra : string = 'Page', func = this.getPage) => {
        names.forEach(async name => {
            const variableName = `${this.helper.camelize(name)}${extra}`;
            _this[variableName] = await func(name);
        });
    };

    getManyPages = async(_this, names : string[]) => await this.getManyBy(_this, names);

    getManyRoutes = async(_this, names : string[]) => await this.getManyBy(_this, names, 'PageRoute', this.getRouteOrDefault);

    getPagesAndRoutes = async(_this, names : string[]) => {
        await this.getManyPages(_this, names);
        await this.getManyRoutes(_this, names);
    };

    getPagesDictionary = async() => await this.getAllBeforeExecution(() => {
        return this.pages.reduce((a, b) => {
            a[b.name] = b;
            return a;
        }, {});
    });
}
