// Module
var code = `<template>
    <div id="cx-select">
        <div class="text-end align-items-center position-relative dropdown">
            <button class="mdc-button mdc-button--touch" data-bs-toggle="dropdown" id="options-selector-\${id}">
                <mdc-icon leading class="material-symbols-outlined">\${selectedOption.icon ? selectedOption.icon :
                    icon}</mdc-icon>
                <div class="selected-option-wrapper">
                    <label>\${label}</label>
                    <div class="selected-option">\${selectedOption.label}</div>
                </div>
                <cds-icon icon="arrow_drop_down"></cds-icon>
            </button>

            <div id="options-dropdown-menu-\${id}" class="dropdown-menu" aria-labelledby="options-selector-\${id}">
                <section data-simplebar class="payment-method-scrollbar purple-scrollbar">
                    <div class="select-option-item" repeat.for="option of options"
                        click.delegate="selectOption(option)">
                        <mdc-icon class="material-symbols-outlined">\${option.icon}</mdc-icon>
                        <span>\${option.label}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;