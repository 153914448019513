import { baseUrl } from 'environment';

export class LangHelper {
    populateLinksHref(route: string, languageList: string[]) {
        if (route) {
            const cleanRoute = route.split('/').filter(e => e)[0];
            route = languageList.includes(cleanRoute) ? '/' : route;
            const head = document.getElementsByTagName('head')[0];
            const oldLinks = Array.from(head.querySelectorAll('link[rel="alternate"][hreflang]'));
            oldLinks.forEach(item => item.remove());
            const _baseUrl = baseUrl();
            languageList.forEach(lang => {
                const langUrl = ['en', 'en-us'].includes(lang.toLowerCase()) ? '' : lang;
                const builtUrl = new URL(langUrl + route, _baseUrl);
                const link = document.createElement('link');
                link.rel = 'alternate';
                link.hreflang = this.isEnglishLang(lang) ? 'x-default' : lang;
                let href = builtUrl.toString();
                href = href.endsWith('/') ? href.slice(0, -1) : href;
                link.href = href;
                head.append(link);
            });
        }
    }

    parseHrefLang(hrefLang) {
        if (this.isEnglishLang(hrefLang)) return '';
        return `${hrefLang}/`;
    }

    async handleLanguageLinks(currentLanguageHrefLang) {
        if (currentLanguageHrefLang === 'en') return;
        setTimeout(() => {
            const links = Array.from(document.getElementsByTagName('a'));
            links.forEach(link => {
                if (link.href.includes(baseUrl())) {
                    const newUrl = new URL(link.href);
                    link.href = `${newUrl.origin}/${currentLanguageHrefLang}${newUrl.pathname}`;
                }
            });
        }, 500);
    }

    isEnglishLang(hrefLang, ignoreLangs = ['en', 'en-us']) {
        return ignoreLangs.includes(hrefLang.toLowerCase());
    }
}
