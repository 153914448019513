// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/careers/chick-careers-banner.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/cloud-upload.svg", import.meta.url);
// Module
var code = `<template> 
    <section id="careers" class="main-content-padding legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="cx-container">
            <cx-page-content-area 
                key.bind="'CAREERS_FIRST_TITLE'"
                class="careers-f-title careers-title"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_BE_PART_CONTENT_1'"
                class="career-body text-medium careers-content-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_BE_PART_CONTENT_2'"
                class="career-body text-medium cx-separation careers-content-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_BE_PART_CONTENT_3'"
                class="career-body text-medium careers-content-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_BE_PART_CONTENT_4'"
                class="career-body text-medium careers-content-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
        </div>
        <div class="cx-container banner-2" skeleton-loading="is-loading.bind:pageLoading;">
            <div class="banner">
                <img src="${___HTML_LOADER_IMPORT_0___}" class="img-banner" alt="Careers" loading="lazy">
            </div>
        </div>
        <div class="cx-container">
            <cx-page-content-area 
                key.bind="'CAREERS_SECOND_TITLE'"
                class="careers-s-title careers-s-title-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-line width="60" alignment="start" color="purple" class="line-p divider-bar" skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-line>
            <cx-page-content-area 
                key.bind="'CAREERS_FIRST_SUBTITLE'"
                class="careers-subtitle careers-subtitle-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_DIVERSITY_CONTENT'"
                class="career-body text-medium culture-group careers-content-2"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <div class="light-grey-border-top content-separation divider-bar" skeleton-loading="is-loading.bind:pageLoading;"
            ></div>
            <cx-page-content-area 
                key.bind="'CAREERS_SECOND_SUBTITLE'"
                class="careers-subtitle careers-subtitle-2"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_SHIFT_CONTENT'"
                class="career-body text-medium culture-group careers-content-3"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <div class="light-grey-border-top content-separation divider-bar" skeleton-loading="is-loading.bind:pageLoading;"></div>
            <cx-page-content-area 
                key.bind="'CAREERS_THIRD_SUBTITLE'" 
                class="careers-subtitle careers-subtitle-3"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <cx-page-content-area 
                key.bind="'CAREERS_FRIENDLY_CONTENT'"
                class="career-body text-medium culture-group careers-content-4"
                skeleton-loading="is-loading.bind:pageLoading;"
            ></cx-page-content-area>
            <div class="light-grey-border-top content-separation cx-separation divider-bar" skeleton-loading="is-loading.bind:pageLoading;"></div>
        </div>
        <div class="cx-container cx-positions">
            <cx-page-content-area 
                key.bind="'CAREERS_POSITIONS_CONTENT'"
                class="career-body-positions text-medium career-body-positions-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            ><cx-page-content-area>
        </div>
        <div class="cx-container">
            <div class="card-container">
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;"
                click.delegate="scrollToFormWith(1)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_CREATIVE_AND_DESIGN' | siteStrings}
                        </h5>
                        <p class="card-text text">
                            \${'CX_REMOTE' | siteStrings}
                        </p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(2)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_CUSTOMER_OPERATIONS' | siteStrings}
                        </h5>
                        <p class="card-text text">Toronto / Canada</p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(3)">
                    <div>
                        <h5 class="card-title title">\${'CX_DEVELOPMENT' | siteStrings}</h5>
                        <p class="card-text text">
                            \${'CX_REMOTE' | siteStrings}
                        </p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(4)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_LEGAL' | siteStrings}
                        </h5>
                        <p class="card-text text">
                            \${'CX_REMOTE' | siteStrings}
                        </p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(5)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_DIGITAL_SECURITY' | siteStrings}
                        </h5>
                        <p class="card-text text">
                            \${'CX_REMOTE' | siteStrings}
                        </p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(6)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_MARKETING' | siteStrings}
                        </h5>
                        <p class="card-text text">Toronto / Canada</p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(7)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_CHANNELS' | siteStrings}
                        </h5>
                        <p class="card-text text">
                            \${'CX_REMOTE' | siteStrings}
                        </p>
                    </div>
                </div>
                <div class="cx-card align-items-center text-center cx-card-1" skeleton-loading="is-loading.bind:pageLoading;" click.delegate="scrollToFormWith(8)">
                    <div>
                        <h5 class="card-title title">
                            \${'CX_SOCIAL_MEDIA' | siteStrings}
                        </h5>
                        <p class="card-text text">\${'CX_REMOTE' | siteStrings}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="cx-container">
            <div class="row row-cols-1 row-cols-md-1 row-cols-xl-2 d-flex align-items-center form-margin">
                <div class="col box-f-title">
                    <div class="text-extra-large">
                        <cx-page-content-area key.bind="'CAREERS_FOURTH_TITLE'" class="careers-fo-title careers-fo-title-1" skeleton-loading="is-loading.bind:pageLoading;">
                        </cx-page-content-area>
                        <div class="career-body">
                            <p class="text-medium">
                                <cx-page-content-area key.bind="'CAREERS_CONTACT_CONTENT'" class="careers-c-content careers-c-content-1" skeleton-loading="is-loading.bind:pageLoading;">
                                </cx-page-content-area>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col contact-f-container contact-1" skeleton-loading="is-loading.bind:pageLoading;" ref="form">
                    <form class="career-contact container pt-5 ms-xl-5" submit.delegate="sendEmail()" name="contact-form" id="contact-form">
                        <label class="form-label">\${'CX_POSITION' | siteStrings}</label>
                        <mdc-select
                            outlined
                            class="cx-form-control w-100 text-regular mb-3 cx-select"
                            required.bind="true"
                            value.bind="category"
                            selected-index.bind="optionSelected"
                            change.delegate="changeSelectHandler(\$event)"
                            click.delegate="handleClick(\$event)"
                        >
                            <section data-simplebar class="lilac-scrollbar">
                                <mdc-list focusout.delegate="handleFocusOut(\$event)">
                                    <mdc-list-item repeat.for="option of selectOptions" value.bind="option" class="mdc-list">
                                        \${optionsMap[option] | siteStrings}
                                    </mdc-list-item>
                                </mdc-list>
                            </section>
                        </mdc-select>
                        <label class="form-label mt-2">
                            \${'CX_FULL_NAME' | siteStrings}
                        </label>
                        <mdc-text-field
                            outlined
                            autocomplete="on"
                            value.two-way="name"
                            required.bind="true"
                            type="text"
                            placeholder="John Smith"
                            class="input-password input-size w-100 mb-3 cx-form-control"
                        ></mdc-text-field>
                        <label class="form-label mt-2">
                            \${'CX_EMAIL' | siteStrings}
                        </label>
                        <div class="position-relative d-flex align-items-center">
                            <mdc-text-field
                                outlined
                                autocomplete="on"
                                value.two-way="email & validate"
                                required.bind="true"
                                type="text"
                                placeholder="johnsmith@gmail.com"
                                id="input-email"
                                class="input-password input-size w-100 mb-3 cx-form-control \${ showGreenCheckMarkEmail ? 'cx-form-control--success' : '' } \${ showErrorCheckMarkEmail ? 'cx-form-control--error' : '' }"
                                keyup.delegate="emailUpdatedOnKeyPress(\$event)"
                                focusin.delegate="emailUpdatedOnFocusIn()"
                                focusout.delegate="checkEmailValidation()"
                                ref="emailInput"
                            ></mdc-text-field>
                            <span if.bind="showGreenCheckMarkEmail" class="circle-icon check text-large position-absolute material-icons-outlined" alt="green check">check_circle</span>
                            <span if.bind="showErrorCheckMarkEmail" class="circle-icon position-absolute error text-large material-icons" alt="error">error_outline</span>
                            <mdc-circular-progress if.bind="showMiniSpinnerEmail" class="mini-spinner-icon position-absolute" size="20" stroke-width="2"></mdc-circular-progress>
                        </div>
                        <label class="form-label mt-2">
                            \${'CX_COMMENTS' | siteStrings}
                        </label>
                        <div 
                            id="drop-zone-outside"
                            dragenter.trigger="dragEnterHandler(\$event)"
                            dragleave.trigger="dragLeaveHandler(\$event)"
                            drop.trigger="dropHandler(\$event)"
                            class="cx-form-control mt-2 d-flex justify-content-center cx-drop-zone-outside form-control message-area"
                        >
                            <mdc-text-field
                                focusin.trigger="emailFocus(\$event)"
                                focusout.delegate="emailFocus(\$event)"
                                textarea
                                outlined
                                spellcheck="false"
                                rows="5"
                                required.bind="true"
                                end-aligned.bind="textareaEndAligned"
                                value.bind="message"
                                class="input-password bg-transparent form-control w-100">
                            ></mdc-text-field>
                            <div class="wrapper-sm d-flex flex-row" id="wrapper-sm">
                                <div class="drop-zone-sm me-2 h-100" role="button">
                                    <label class="cursor-pointer" for="file-input">
                                        <span class="material-icons text-blue">attach_file</span>
                                    </label>
                                    <input 
                                        class="d-none"
                                        id="file-input"
                                        type="file"
                                        change.delegate="changeHandler(\$event)"
                                        accept="image/*, video/*, .txt, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .pdf, .odp, .ods, .odt, .xml"
                                        multiple />
                                </div>
                                <div class="cx-first-files-list d-flex">
                                    <mdc-list class="w-100 ps-0">
                                        <mdc-list-item class="pe-1" repeat.for="file of tempFiles" value.bind="option">
                                            <div if.bind="showItem(\$index)" class="d-flex align-items-center ">
                                                <div
                                                    class="file-name font-regular text-regular text-black cursor-default">
                                                    \${helper.sanitizeHtml(file.name, true)}
                                                </div>
                                                <span if.bind="file.isLoaded" class="text-navy-blue text-small" click.delegate="deleteItem(\$index)" role="button">X</span>
                                                <mdc-circular-progress 
                                                    if.bind="!file.isLoaded"
                                                    size="18"
                                                    stroke-width="2"
                                                ></mdc-circular-progress>
                                            </div>
                                        </mdc-list-item>
                                    </mdc-list>
                                </div>
                                <div class="ms-2 justify-self-end flex-shrink-1" show.bind="tempFiles.length > 4 ? true : false">
                                    <span class="material-icons text-medium align-text-bottom pt-2 dropup btn-group" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                        more_horiz
                                    </span>
                                    <section data-simplebar>
                                        <mdc-list class="dropdown-menu cx-dropdown-files-list">
                                            <mdc-list-item class="pe-2" repeat.for="file of tempFiles" value.bind="option">
                                                <div if.bind="!showItem(\$index)" class="d-flex align-items-center ">
                                                    <div class="file-name font-regular text-regular text-black cursor-default">
                                                        \${helper.sanitizeHtml(file.name, true)}
                                                    </div>
                                                    <span if.bind="file.isLoaded" class="text-navy-blue text-small" click.delegate="deleteItem(\$index)" role="button">X</span>
                                                    <mdc-circular-progress 
                                                        if.bind="!file.isLoaded"
                                                        size="18"
                                                        stroke-width="2"
                                                    ></mdc-circular-progress>
                                                </div>
                                            </mdc-list-item>
                                        </mdc-list>
                                    </section>
                                </div>
                            </div>
                            <div id="drop-zone" class="flex-column">
                                <div class="drop-zone-lg col flex-column align-items-center justify-content-center">
                                    <img src="${___HTML_LOADER_IMPORT_1___}" alt="cloud icon" loading="lazy"/>
                                    <div>\${'CX_DRAG_FILES' | siteStrings}</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-1">
                            <cx-button
                                class="pt-3 cx-btn"
                                button-style.bind="'primary primary-style ticket-btn send-btn text-regular font-medium'"
                                button-text.bind="'CX_SEND_US_MESSAGE' | siteStrings"
                                button-icon="send"
                            ></cx-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>`;
// Exports
export default code;