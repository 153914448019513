import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class BlockActionLogService {
    path = 'BlockActionLog';

    constructor(private api: ApiService) {}

    async create(data) {
        return await this.api.doPost(`${this.path}`, data);
    }
}
