import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { AdvancedOrderSearchRequest, AdvancedOrderSearchResponse, Order, OrderOperationType, OrderPaginationRequest, OrderRequest, OrderServiceOrderTotalInfoResponse } from './models/order';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class OrderService {
    path = 'Order';
    ocePath = 'OrderCryptoExchange';
    orders;

    constructor(private api: ApiService, private helper: Helper) { }

    getAll() {
        if (this.orders) return this.orders;
        this.orders = this.api.doGet(this.path + '?websiteShortCode=' + websiteShortCode());
        return this.orders;
    }

    getById(id: number, orderPath?: string) {
        return this.api.doGet(`${this.path}/${id}`, { small: true, path: orderPath });
    }

    async startOrder(data: OrderRequest) {
        return this.api.doPost(this.path, data);
    }

    async resendReceipt(orderId: number) {
        return this.api.doPost(this.path + '/' + orderId + '/ResendReceipt', false);
    }

    async getOrderCryptoExchangeByOrderId(orderId: number) {
        return this.api.doGet(this.ocePath + '/GetByOrderIdWithUpdatedValues/' + orderId);
    }

    async getPaginated(page: number, recordQuantity: number, statusList: string[]) {
        const getAllStatus = statusList.indexOf('all') > -1;
        const status = !getAllStatus ? statusList.join(',') : null;
        const url = this.helper.toParams(`${this.path}/ListOrdersPagination`, {
            websiteShortCode: websiteShortCode(),
            page,
            recordQuantity,
            status
        });
        return this.api.doGet(url);
    }

    getCXListWithPagination(request: OrderPaginationRequest): Promise<Order[] | null> {
        return this.api.doGet(this.helper.toParams((this.path + '/CGListOrdersPagination'), request));
    }

    getOrdersCountByWebsiteByUserId(request: OrderPaginationRequest, forceFetch = false): Promise<number | null> {
        return this.helper.fetchData(this.api, this.helper.toParams((this.path + '/GetOrdersCountByWebsiteByUserId'), request), 'getOrdersCountByWebsiteByUserId', forceFetch);
    }

    async doAdvancedSearch(request: AdvancedOrderSearchRequest): Promise<AdvancedOrderSearchResponse | null> {
        return await this.api.doGet(`${this.path}/AdvancedSearch`, { ...request });
    }

    async createTopUpBalanceOrder(order: OrderRequest): Promise<Order | null> {
        return await this.api.doPost(`${this.path}/CreateTopUpBalanceOrder`, order);
    }

    async createSubscriptionOrder(order: OrderRequest): Promise<Order | null> {
        return await this.api.doPost(`${this.path}/CreateSubscriptionOrder`, order);
    }

    async getCustomerTotalPriceOrders(transactionType: OrderOperationType) {
        return await this.api.doGet(`${this.path}/GetCustomerTotalPriceOrders`, { transactionType, websiteShortCode: websiteShortCode() });
    }

    async getCustomerOrdersTotalInfo(isTransactionType: boolean = false) {
        return await this.api.doGet(this.path + '/GetCustomerOrdersTotalInfo', { websiteShortCode: websiteShortCode(), isTransactionType }) as OrderServiceOrderTotalInfoResponse[];
    }
}
