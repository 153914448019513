import './cx-hero.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { LangHelper } from 'resources/helpers/lang-helper';
import { LanguageRouteValueConverter } from 'resources/value-converters/language-route';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { PageContentArea } from 'services/models/page/pageContentArea';

@autoinject()
export class CxHero {
    @bindable pages: PageByWebsite[];
    @bindable homePageContentArea: PageContentArea;
    @bindable licenses: string;
    @bindable firstText: string;
    @bindable secondText: string;
    faqRoute: string;
    tradeRoute: string;
    trustPilotStarRating: string;
    amountOfStars: number;
    halfStar: boolean;
    semiSesquiStar: boolean;
    viewingAsAdmin: boolean;
    adminViewSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    isLoading = true;

    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private pageContentAreaService: PageContentAreaService,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private langHelper: LangHelper,
        private languageRouteValueConverter: LanguageRouteValueConverter
    ) { }

    async attached() {
        try {
            this.helper.addLoadingComponent('cx-hero');

            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
            this.tradeRoute = pages.find(x => x.name === 'Trade')?.routeName ?? 'trade';
            const pageContentArea = await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Home')?.id);

            this.helper.getTrustPilotStarRatingVariables(this, pageContentArea);
            this.handleEventSubscriptions();
        } finally {
            this.helper.validateLoading('cx-hero');
        }
    }

    detached() {
        this.adminViewSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.isLoading = false;
        });
    }

    navigateToTrade() {
        this.eventAggregator.publish('page-loaded', false);
        this.router.navigate(this.tradeRoute);
    }
}
