import './cx-phone-input.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import intlTelInput from 'intl-tel-input';
import { firebaseConfig } from 'environment';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { User } from 'services/models/user/user';
import { SimplebarOverride } from 'resources/simplebar_override';
import { Helper } from 'resources/helpers/helper';
import { PageContentAreaService } from 'services/page-content-area-service';

interface ExtendedUser extends User {
    phoneNumberInReview?: boolean;
}

// Extend the existing IntlTelInput.Options interface due to package not updated to latest version
interface ExtendedIntlTelInputOptions extends intlTelInput.Options {
    showSelectedDialCode?: boolean;
}

type PhoneInputState = 'success' | 'error' | 'loading' | 'typing' | 'inactive';
type AuthCodeState = 'success' | 'error' | 'loading' | 'inactive' | 'typing' | '';

@autoinject()
export class CxPhoneInput {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    stages = {
        ENTERING_PHONE: 1,
        ENTERING_CODE: 2,
        VERIFIED: 3,
    };

    phoneElement;
    authCode: string;
    sentPhone: boolean;
    verificationStage = this.stages.ENTERING_PHONE;
    @bindable requireSms: boolean;
    @bindable verifiedFunction;
    @bindable user: ExtendedUser;
    @bindable phoneInput: intlTelInput.Plugin;
    @bindable inputStyle: boolean;
    @bindable enteringToken: boolean;
    @bindable loading: boolean;
    @bindable labelStyle: string;
    @bindable labelText: string;
    @bindable containLabel: boolean;
    @bindable flowPage: boolean;
    @bindable firstMessage = true;
    @bindable copyTextBellow = 'We\'ll send you a text message with a code';
    @bindable useLabelMargin: boolean = true;

    showMessage: boolean;
    phoneInputState: PhoneInputState;
    authCodeState: AuthCodeState;
    justReset = true;
    errorTimeout: NodeJS.Timeout;
    profileErrorTimeout: NodeJS.Timeout | number;
    firedFunction: boolean;
    showMiniSpinnerPhone: boolean;
    miniSpinnerPhoneStopWatch: NodeJS.Timeout;
    showMiniSpinnerAuthCode: boolean;
    miniSpinnerAuthCodeStopWatch: NodeJS.Timeout;
    whiteXMark: boolean;
    whiteAuthCodeXMark: boolean;
    toastPhoneInputSent: boolean;
    toastAuthCodeSent: boolean;
    phoneInputFocusInStopWatch: NodeJS.Timeout;
    authCodeFocusInStopWatch: NodeJS.Timeout;
    checkIfPhoneAuthValidTimeout: NodeJS.Timeout | number;
    submitPhoneTimeout: NodeJS.Timeout | number;
    submitCodeTimeout: NodeJS.Timeout | number;
    verifyUserPhoneTimeout: NodeJS.Timeout | number;
    profileSubmitTimeout: NodeJS.Timeout | number;
    afterPhoneVerifTimeout;
    timeouts: NodeJS.Timeout[];

    inputContainer;
    lastEventTrigered: 'focusOut' | '';
    arrowRotated = false;
    arrowTrigered: HTMLElement;

    countryToUse: string;
    parent;
    countryCodeSelector: HTMLElement;
    phoneNumber;
    phoneSubscriber: Subscription;
    firebaseUserCredential;
    recaptchaVerifier: RecaptchaVerifier;
    verificationId: string;
    recaptchaWidgetId: number;
    firebaseProvider: PhoneAuthProvider;
    usingTwilio;
    urlParams;

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private inputNumericValueChecker: InputNumericValueChecker,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private helper: Helper,
        private pageContentAreaService: PageContentAreaService
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async attached() {
        this.loading = true;

        initializeApp(firebaseConfig());

        this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
        this.user = await this.sessionService.refreshProfile();

        if (!this.user && this.urlParams.email && this.urlParams.token) {
            return;
        }

        this.phoneInput = intlTelInput(this.phoneElement, {
            showSelectedDialCode: true,
            utilsScript: require('intl-tel-input/build/js/utils.js'),
            preferredCountries: ['us', 'ca', 'gb'],
        } as ExtendedIntlTelInputOptions);

        this.countryToUse = this.user?.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : this.user?.country
                ? this.user.country.toLowerCase()
                : await this.sessionService.getCountry();
        if (this.countryToUse && this.countryToUse !== 'N/A') {
            this.phoneInput.setCountry(this.countryToUse.toLowerCase());
        }
        if (this.user?.phoneNumber) {
            this.phoneNumber = this.user.phoneNumber;
            this.phoneInput.setNumber(this.user.phoneNumber);
        }
        if (this.user?.phoneNumberConfirmed && this.user?.phoneNumber) {
            if (this.phoneInput.isValidNumber()) {
                this.phoneInputState = 'success';
            } else {
                this.phoneInputState = 'error';
            }
        }
        else if (this.phoneInput)
            this.phoneInputState = 'typing';

        this.overrideITI();

        this.handleEventSubscriptions();
        this.loading = false;
    }

    async detached() {
        this.phoneInput?.destroy();
        this.phoneSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', (payload) => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
                this.user.phoneNumberInReview = false;
                this.resetPhoneInput();
            }
        });
    }

    overrideITI() {
        if (!this.countryCodeSelector) return;
        const selectOuterContainer = this.countryCodeSelector.querySelector('.iti__flag-container');
        const selectContainer = this.countryCodeSelector.querySelector('.iti__arrow');
        if (selectContainer) selectContainer.innerHTML += '<div class="override-arrow-container"></div>';
        const selectContainerForArrow = this.countryCodeSelector.querySelector('.override-arrow-container');
        const newArrowIcon = '<img class="global-arrow-icon" src="/icons/arrow-white.svg" alt="arrow icon" loading="lazy">';
        if (selectContainerForArrow) selectContainerForArrow.innerHTML = newArrowIcon;
        if (!this.user?.phoneNumberConfirmed) {
            const handleClick = () => {
                const countryList = this.inputContainer?.querySelector('.iti__country-list');
                if (!countryList) return;
                const instance = SimplebarOverride.instances.get(countryList);
                if (instance) instance.unMount();
                new SimplebarOverride(countryList);
            };
            if (!selectOuterContainer) return;
            selectOuterContainer.addEventListener('click', handleClick);
        }
    }

    async phoneInputError(msg: string) {
        this.showMiniSpinnerPhone = false;
        this.phoneInputState = 'error';
        this.toastPhoneInputSent = true;
        await this.toastService.showToast('Error', msg, 'error');
    }

    async phoneAuthInputError(msg: string) {
        this.showMiniSpinnerAuthCode = false;
        this.authCodeState = 'error';
        this.toastAuthCodeSent = true;
        await this.toastService.showToast('Error', msg, 'error');
    }

    getPhoneData() {
        const cc = this.phoneInput.getSelectedCountryData().dialCode;
        const num = this.phoneInput.getNumber().slice(1 + cc.length);
        const cf = this.phoneInput.getSelectedCountryData().iso2;
        return { cc, num, cf };
    }


    async submitPhone() {
        let result = false;
        const { cc, num, cf } = this.getPhoneData();
        try {
            this.usingTwilio = false;
            this.submitPhoneTimeout = this.registerTimeout(this.submitPhoneTimeout as NodeJS.Timeout, async () => {
                await this.registerPhone(cc, num, cf);
            }, 1000);
            result = true;
        } catch (e) {
            console.log(e);
        }
        return result;
    }

    async codeNotSent() {
        if (this.requireSms) {
            await this.submitPhone();
        }
    }

    async setPhoneNumberNotInReview() {
        this.verificationStage = this.stages.ENTERING_PHONE;
        this.enteringToken = false;
        this.clearAuthCode();
        this.authCodeState = '';
        this.parent.shouldShowPhoneNumber = true;
        this.user.phoneNumberInReview = false;
        clearTimeout(this.afterPhoneVerifTimeout);
        this.usingTwilio = false;
        this.eventAggregator.publish('user-updated', {
            user: await this.sessionService.getProfile(),
        });
    }

    async callInstead() {
        if (this.phoneInput.isValidNumber()) {
            const { cc, num } = this.getPhoneData();
            if (!cc || !num) {
                await this.toastService.showToast('Error', 'Please enter both your country code and phone number.', 'error');
                return;
            }
            try {
                const result = await this.customerService.requestCall();
                if (result) {
                    await this.toastService.showToast('You will receive a call with a code.', 'Please enter the code provided to confirm the phone number.', 'info');
                    this.phoneInputState = null;
                    this.verificationStage = this.stages.ENTERING_CODE;
                    this.enteringToken = true;
                    this.parent.shouldShowPhoneNumber = false;
                    this.user.phoneNumberInReview = true;
                    this.usingTwilio = true;
                    this.eventAggregator.publish('user-updated', {
                        user: await this.sessionService.refreshProfile(),
                    });
                } else {
                    this.firedFunction = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
        }
    }

    getElementStateClass(state: PhoneInputState | AuthCodeState, inputType?: 'phone' | 'authCode') {
        this.changeStatesForInput(state, inputType);
        switch (state) {
            case 'success':
                if (inputType === 'phone') {
                    return 'cx-input--success';
                }
                return 'cx-form-control--success';
            case 'error':
                if (inputType === 'phone') {
                    return 'cx-input--error';
                }
                return 'cx-form-control--error';
            case 'inactive':
                return 'cx-form-control--warning';
            case 'typing':
                if (inputType === 'phone') {
                    return 'cx-input--typing';
                }
                return 'cx-form-control--typing';
            case 'loading':
                if (inputType === 'phone') {
                    return 'cx-input--loading';
                }
                return 'cx-form-control--loading';
            default:
                return 'cx-input';
        }
    }

    changeStatesForInput(state: PhoneInputState | AuthCodeState, inputType: 'phone' | 'authCode') {
        if (!state || !inputType) return;
        switch (state) {
            case 'success':
                switch (inputType) {
                    case 'phone':
                        this.showMiniSpinnerPhone = false;
                        this.whiteXMark = false;
                        break;
                    case 'authCode':
                        this.showMiniSpinnerAuthCode = false;
                        this.whiteAuthCodeXMark = false;
                        break;
                }
                return;
            case 'error':
            case 'inactive':
                switch (inputType) {
                    case 'phone':
                        this.showMiniSpinnerPhone = false;
                        this.whiteXMark = false;
                        break;
                    case 'authCode':
                        this.showMiniSpinnerAuthCode = false;
                        this.whiteAuthCodeXMark = false;
                        break;
                }
                return;
            case 'typing':
                switch (inputType) {
                    case 'phone':
                        this.showMiniSpinnerPhone = false;
                        this.whiteXMark = true;
                        break;
                    case 'authCode':
                        this.showMiniSpinnerAuthCode = false;
                        this.whiteAuthCodeXMark = true;
                        break;
                }
                return;
            case 'loading':
                switch (inputType) {
                    case 'phone':
                        this.showMiniSpinnerPhone = true;
                        this.whiteXMark = false;
                        break;
                    case 'authCode':
                        this.showMiniSpinnerAuthCode = true;
                        this.whiteAuthCodeXMark = false;
                        break;
                }
                return;
        }
    }

    validateInput(event: KeyboardEvent) {
        if (!this.inputNumericValueChecker.toView(event)) return false;
        const charCode = (event.which) ? event.which : event.keyCode;
        const ctrlPlusKey = event.ctrlKey && [65, 67, 86].includes(charCode);
        if ([37, 38, 39, 40, 8, 46].includes(charCode) || ctrlPlusKey) return true;
        const { iso2, dialCode } = this.phoneInput.getSelectedCountryData();
        const num = this.phoneInput.getNumber().slice(1 + dialCode.length) + event.key;
        const validationError = this.getValidationError(num, iso2);
        return !(validationError === PhoneNumberUtil.ValidationResult.TOO_LONG || (this.phoneInput.getNumber().slice(1) + event.key).length > 15);
    }

    getValidationError(number: string, countryCode: string) {
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const numberObj = phoneUtil.parseAndKeepRawInput(number, countryCode);
            return phoneUtil.isPossibleNumberWithReason(numberObj);
        } catch (e) {
            switch (e.message) {
                case 'Invalid country calling code': return PhoneNumberUtil.ValidationResult.INVALID_COUNTRY_CODE;
                case 'Phone number too short after IDD':
                case 'The string supplied is too short to be a phone number': return PhoneNumberUtil.ValidationResult.TOO_SHORT;
                case 'The string supplied is too long to be a phone number': return PhoneNumberUtil.ValidationResult.TOO_LONG;
                default: return -99;
            }
        }
    }

    async startPhoneVerification(ev: KeyboardEvent) {
        if (this.verificationStage !== this.stages.ENTERING_PHONE)
            return;
        if (ev?.key === 'Enter') {
            await this.phoneFocusOut();
            return;
        }
        const { dialCode } = this.phoneInput.getSelectedCountryData();
        const num = this.phoneInput.getNumber().slice(1 + dialCode.length);
        if (num === dialCode) return this.clearPhoneInput();
        this.firedFunction = false;
        this.toastPhoneInputSent = false;
        if (this.phoneNumber && this.phoneNumber.length) this.phoneInputState = 'typing';
        else this.phoneInputState = 'inactive';
        clearTimeout(this.profileSubmitTimeout as NodeJS.Timeout);
        this.afterPhoneVerifTimeout = this.registerTimeout(this.afterPhoneVerifTimeout, async () => {
            if (this.phoneNumber && !this.firedFunction) {
                await this.afterPhoneVerif();
            }
        }, 2000);
    }

    async validatePhoneInput() {
        if (!this.phoneInput)
            return false;

        if (!this.phoneInput.isValidNumber()) {
            await this.phoneInputError('Please enter a valid phone number to proceed with your phone verification.');
            return false;
        }

        const { cc, num } = this.getPhoneData();
        if (!cc || !num) {
            await this.phoneInputError('Please enter both your country code and phone number.');
            return false;
        }
        return true;
    }

    async phoneFocusOut() {
        this.whiteXMark = false;
        this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch, this.submitPhoneTimeout, this.profileSubmitTimeout, this.afterPhoneVerifTimeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.checkIfPhoneValid();
    }

    async afterPhoneVerif() {
        this.justReset = false;
        this.phoneInputState = 'loading';
        this.timeouts = [this.profileErrorTimeout as NodeJS.Timeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch, this.submitPhoneTimeout as NodeJS.Timeout, this.profileSubmitTimeout as NodeJS.Timeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);

        this.profileSubmitTimeout = this.registerTimeout(this.profileSubmitTimeout as NodeJS.Timeout, async () => {
            this.checkIfPhoneValid();
        }, 4000);
    }

    async checkIfPhoneValid() {
        if (!await this.validatePhoneInput()) return;
        if (this.requireSms) {
            this.firedFunction = true;
            const response = await this.submitPhone();
            if (response) {
                this.phoneInputState = 'success';
                return;
            }
            this.phoneInputState = 'error';
            this.firedFunction = false;
            return;
        }
        this.showMiniSpinnerPhone = false;
        this.phoneInputState = 'success';
        this.profileErrorTimeout = this.registerTimeout(this.profileErrorTimeout as NodeJS.Timeout, async () => {
            this.showMiniSpinnerPhone = false;
            this.phoneInputState = 'success';
            this.toastPhoneInputSent = false;
        }, 1000);
    }

    clearPhoneInput() {
        this.timeouts = [this.profileErrorTimeout as NodeJS.Timeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.phoneInput.setNumber('');
        this.phoneNumber = null;
        this.justReset = true;
        this.phoneInputState = null;
        this.usingTwilio = false;
    }

    clearAuthCode() {
        this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.authCode = null;
    }

    async authCodeFocusOut() {
        this.whiteAuthCodeXMark = false;
        this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch, this.submitCodeTimeout as NodeJS.Timeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.authCode) {
            if (
                this.authCode?.length === (this.usingTwilio ? 7 : 6) &&
                    this.authCodeState !== 'error' &&
                    !this.firedFunction
            ) {
                const response = await this.submitCode();
                this.showMiniSpinnerAuthCode = false;
                if (response) {
                    this.authCodeState = 'success';
                } else {
                    this.authCodeState = 'error';
                }
            } else if (!this.firedFunction) {
                this.authCodeState = 'error';
                if (!this.toastAuthCodeSent) {
                    await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                }
            }
        } else this.authCodeState = 'inactive';
    }

    authCodeFocusIn() {
        this.firedFunction = false;
        this.showMiniSpinnerAuthCode = false;
        this.toastAuthCodeSent = false;
        this.authCodeState = 'typing';
        this.whiteAuthCodeXMark = true;

        this.authCodeFocusInStopWatch = setTimeout(() => {
            if (this.authCode) {
                this.authCodeUpdatedOnKeyPress();
            }
        });
    }

    async authCodeUpdatedOnKeyPress(ev?: KeyboardEvent) {
        if (ev?.key === 'Enter') {
            await this.authCodeFocusOut();
        } else {
            this.authCodeState = 'typing';
            this.showMiniSpinnerAuthCode = false;
            this.whiteAuthCodeXMark = true;
            this.timeouts = [
                this.errorTimeout,
                this.miniSpinnerAuthCodeStopWatch,
                this.authCodeFocusInStopWatch,
                this.submitCodeTimeout as NodeJS.Timeout,
            ];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            if (this.authCode) {
                this.miniSpinnerAuthCodeStopWatch = setTimeout(() => {
                    this.authCodeState = 'loading';
                }, 1000);

                this.submitCodeTimeout = this.registerTimeout(
                    this.submitCodeTimeout as NodeJS.Timeout,
                    async () => {
                        if (this.authCode?.length === (this.usingTwilio ? 7 : 6)) {
                            this.firedFunction = true;
                            const response = await this.submitCode();
                            this.showMiniSpinnerAuthCode = false;
                            if (response) {
                                this.authCodeState = 'success';
                            } else {
                                this.authCodeState = 'error';
                                this.firedFunction = false;
                            }
                        } else {
                            this.errorTimeout = setTimeout(async () => {
                                if (this.authCode?.length !== (this.usingTwilio ? 7 : 6)) {
                                    this.showMiniSpinnerAuthCode = false;
                                    this.authCodeState = 'error';
                                    this.toastAuthCodeSent = true;
                                    await this.toastService.showToast(
                                        'Error',
                                        'Please enter a valid verification code.',
                                        'error'
                                    );
                                }
                            }, 2000);
                        }
                    },
                    2000
                );
            }
        }
    }

    async resetPhoneInput() {
        this.user = await this.sessionService.refreshProfile();
        this.countryToUse = this.user.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : await this.sessionService.getCountry();
        this.phoneInput.setCountry(this.countryToUse?.toLowerCase());
        this.phoneInput.setNumber(this.user.phoneNumber);
        this.phoneNumber = this.user.phoneNumber;
    }

    async submitCode(): Promise<boolean> {
        let result = false;

        if (this.authCode?.length === (this.usingTwilio ? 7 : 6)) {
            try {
                let codeSubmitted = false;
                const codeIsValid = this.usingTwilio ? true : await this.isCodeValid(this.verificationId, this.authCode);

                if (codeIsValid) {
                    const updateUser = await this.customerService.verifyPhone(
                        this.authCode,
                        this.usingTwilio,
                        this.verificationId,
                        await this.firebaseUserCredential?.user?.getIdToken()
                    );

                    if (updateUser) {
                        codeSubmitted = true;
                        await this.toastService.showToast(undefined, 'Phone number has been validated successfully', 'success');
                        this.showMiniSpinnerAuthCode = false;
                        this.authCodeState = 'success';
                        this.user.phoneNumberConfirmed = true;
                        this.user.phoneNumberInReview = false;
                        result = true;

                        if (this.verifiedFunction) {
                            this.verifiedFunction(result);
                        }

                        this.verifyUserPhoneTimeout = this.registerTimeout(
                            this.verifyUserPhoneTimeout as NodeJS.Timeout,
                            async () => {
                                this.verificationStage = this.stages.VERIFIED;
                                this.eventAggregator.publish('phone-updated', {
                                    successful: true,
                                });
                                this.eventAggregator.publish('user-updated', {
                                    user: await this.sessionService.refreshProfile(),
                                });
                                this.verifyUserPhoneTimeout = null;
                            },
                            1000
                        );
                    }
                }

                if (!codeSubmitted || !codeIsValid) {
                    result = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.phoneAuthInputError(
                `Please enter a valid ${this.usingTwilio ? 7 : 6}-digit verification code to proceed with your phone verification.`
            );
        }

        return result;
    }

    enteringTokenChanged() {
        if (!this.enteringToken) {
            this.verificationStage = this.stages.ENTERING_PHONE;
            this.phoneInputState = 'success';
            this.firedFunction = false;
            this.clearAuthCode();
            this.authCodeState = '';
        }
    }

    displayMessage() {
        this.showMessage = true;
    }

    async registerPhone(cc: string, num: string, cf: string) {
        const registerPhone = await this.customerService.registerPhone(cc, num, cf);

        if (registerPhone) {
            const auth = getAuth();
            if (!this.recaptchaVerifier) {
                this.recaptchaVerifier = new RecaptchaVerifier(auth, 'firebase-button', {
                    'size': 'invisible',
                    'callback': () => true
                });
                this.recaptchaWidgetId = await this.recaptchaVerifier.render();
            }
            this.firebaseProvider = new PhoneAuthProvider(auth);

            try {
                const verificationId = await this.firebaseProvider.verifyPhoneNumber(`+${cc + num}`, this.recaptchaVerifier);
                await this.toastService.showToast('Info', 'A code has been sent to your mobile device.', 'info');
                this.phoneInputState = null;
                this.verificationStage = this.stages.ENTERING_CODE;
                clearTimeout(this.afterPhoneVerifTimeout);
                this.parent.shouldShowPhoneNumber = false;
                this.user.phoneNumberInReview = true;
                this.eventAggregator.publish('user-updated', { user: await this.sessionService.refreshProfile() });
                this.verificationId = verificationId;
            } catch (error) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.recaptchaVerifier as any).recaptcha.reset(this.recaptchaWidgetId);
                if (this.helper.includesSome(error?.message, ['too-many-requests', 'error-code:-39'])) {
                    this.toastService.showToast('Error', 'You have requested phone verification too many times recently. Ref: FB', 'error');
                } else {
                    this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
                }
                this.phoneInputState = 'error';
                this.firedFunction = false;
            }
        }
    }

    async isCodeValid(verificationId: string, authCode: string) {
        let result = false;
        const auth = getAuth();
        const credential = PhoneAuthProvider.credential(verificationId, authCode);
        try {
            this.firebaseUserCredential = await signInWithCredential(auth, credential);
            if (this.firebaseUserCredential) result = true;
        } catch (error) {
            if (error.message?.includes('invalid-verification-code')) {
                this.toastService.showToast('Error', 'Please enter a valid 6 digit verification code to proceed with your phone verification.', 'error');
            } else {
                this.toastService.showToast('Error', 'Please enter a valid verification code to proceed with your phone verification.', 'error');
            }
        }
        return result;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    registerTimeout(timeOut: NodeJS.Timeout, func: Function, time?: number) {
        clearTimeout(timeOut);
        return setTimeout(func, time ?? null);
    }

}
