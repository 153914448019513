// Module
var code = `<template>
    <div id="global-dropdown" class="position-absolute" data-simplebar></div>
    <div class="page-host" data-event='load' id="main-page-host">
        <navbar
            user.bind="user"
            navBar-loading.bind="navbarLoading"
            pages.bind="pages"
            show-banner.bind="triggeredBanner"
            if.bind="isMainPage"
            show-reading-progress.bind="helper.includesSome(router.currentInstruction.config.name, ['terms-of-service', 'cookies', 'privacy-policy', 'copyright-policy'])"
            button-styling.bind="buttonStyling"
            navbar-logo.bind="navbarLogo"
            currency-options.bind="currencyOptions"
        ></navbar>
        <div class="d-flex flex-column router-view-footer">
            <router-view swap-order="with" class="page-host-content"></router-view>
            <div class="mt-auto" if.bind="isMainPage" class="page-host-footer">
                <footer
                ></footer>
            </div>
        </div>
        <div if.bind="snackbars.length > 0">
            <cds-snackbar
                repeat.for="snackbar of snackbars"
                message.bind="snackbar.message"
                type.bind="snackbar.toastType"
                detached.trigger="toastRemoved(snackbar)"
            ></cds-snackbar>
        </div>
    </div>
    <div show.bind="validateRender">
        <div class="position-absolute dev-help cursor-pointer d-flex justify-content-center align-items-center" click.delegate="triggerDevHelp()">
            <span class="material-icons-outlined text-white">question_mark</span>
        </div>
        <div class="position-absolute help-container d-flex" if.bind="devHelpContainer">
            <span class="dev-copied d-flex justify-content-center align-items-center" if.bind="showCopied">Copied! ✂️</span>
            <span class="text-darkergray">
                This component is for internal use, it only shows in the QA and DEV environment, feel free to extend it with any constantly used piece of information like Crypto Wallet Addresses or Tracking Numbers for quicker testing and development.
            </span>
            <div class="mt-2 font-bold text-cx-purple d-flex flex-column">
                <span class="cursor-pointer help-option" click.delegate="triggerCopy('ETH')">Copy ETH Wallet Address</span>
                <span class="cursor-pointer help-option" click.delegate="triggerCopy('BTC')">Copy BTC Wallet Address</span>
                <span class="cursor-pointer help-option" click.delegate="triggerCopy('Tracking')">Copy EasyPost Tracking Number</span>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;