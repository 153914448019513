import { Currency } from '../purchase-flow/exchange';

export class CurrencyCryptoPriceCard {
    code: string = '';
    priceUSD: number = 0;
    price: number = 0;
    base: Currency = new Currency();
    changePercentLast24: number = 0;
    redirectToRoute?: string;
    ready: boolean = false;
}
export interface CurrencyRate {
    code: string;
    value: number;
}
export interface Rate {
    code: string;
    buy?: number;
    sell?: number;
    change24h?: number;
}
