import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { PageContentArea } from './models/page/pageContentArea';
import { observable, signalBindings, singleton } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';
import { BindingSignaler } from 'aurelia-templating-resources';

// Using `scoped: false` ensures this service has a single shared instance
// across all route containers (rather than a new instance per route).
@singleton({ scoped: false })
export class PageContentAreaService {
    path = 'PageContentArea';
    @observable retrievedContent: PageContentArea[] = [];
    @observable pageContentSiteStrings: PageContentArea[] = [];
    @observable englishSiteStrings: PageContentArea[] = [];
    private retrievedContentDict: { [key: string]: PageContentArea[] } = {};

    viewingAsAdmin: boolean;

    constructor(
        private api: ApiService,
        private eventAggregator : EventAggregator,
        private helper: Helper,
        private sessionService : SessionService,
        private signaler: BindingSignaler
    ) {
        this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
        this.signaler.signal('site-strings-updated');
    }

    async getById(id: number) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByKey(key: string) {
        const foundContent = this.retrievedContent?.find(x => x.key === key);
        return foundContent || null;
    }

    getSiteStringByKey(key: string, origin: string = 'siteStrings') {
        const foundContent = this[origin]?.find(x => x.key === key);
        return foundContent || null;
    }

    getSiteStringByKeyFromSubject(key: string) {
        const siteStrings = this.sessionService.siteStringsSubject.getValue();
        const foundContent = siteStrings.find(x => x.key === key);
        return foundContent || null;
    }

    getImageAltByKey = async(key) => {
        const content = await this.getByKey(key);
        return content?.imageAlt;
    };

    async getByPageId(pageId: number): Promise<PageContentArea[]> {
        const key = `${pageId}_${websiteShortCode()}`;
        if (this.retrievedContentDict[key]) {
            return this.retrievedContentDict[key];
        }

        await this.getAndSetContent(pageId, key);
        return this.retrievedContent;
    }

    async getSiteStringsPage(siteStringsPageId: number) : Promise<void> {
        const pageId = siteStringsPageId;
        const key = `${pageId}_${websiteShortCode()}`;

        if (this.retrievedContentDict[key]) {
            return;
        }
        await this.getAndSetContent(pageId, key);
        this.setSiteStrings(this.retrievedContentDict[key]);
    }

    private setSiteStrings(siteStrings: PageContentArea[]) {
        this.sessionService.siteStrings = siteStrings;
        this.sessionService.siteStringsSubject.next(siteStrings);
        signalBindings('site-strings-updated');
        this.eventAggregator.publish('site-strings-updated', siteStrings);
    }

    private async getAndSetContent(pageId: number, key: string) {
        const currentLanguage = await this.sessionService.getLanguage();

        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: currentLanguage?.hrefLang || 'en'
        };
        const content: PageContentArea[] = await this.api.doGet(this.path + '/ByPage', params);
        content?.forEach((e) => {
            if (!this.retrievedContentDict[key]) {
                this.retrievedContentDict[key] = [];
            }
            if (!this.retrievedContentDict[key].find(x => x.key === e.key)) {
                this.retrievedContentDict[key].push(e);
            }
        });
        if (this.retrievedContentDict?.[key]) this.retrievedContent.push(...this.retrievedContentDict[key]);
        this.publishContent();
    }

    async getByPageIdAndGame(pageId: number, gamePage: string) {
        if (!this.retrievedContent.some(x => x.key?.includes(`TRADE_${gamePage}`)) && pageId && gamePage) {
            const params = {
                pageId: pageId,
                websiteShortCode: websiteShortCode(),
                gamePage: gamePage
            };
            const content = await this.api.doGet(this.path + '/ByPageAndGame', params);
            this.retrievedContent.push(...content);
        }
        this.publishContent();
        return this.retrievedContent;
    }

    async getSiteStrings(pageId: number): Promise<PageContentArea[]> {
        const currentLanguage = await this.sessionService.getLanguage();

        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: currentLanguage?.hrefLang || 'en'
        };

        this.pageContentSiteStrings = await this.api.doGet(this.path + '/ByPage', params);
        this.sessionService.siteStringsSubject.next(this.pageContentSiteStrings);
        signalBindings('site-strings-updated');
        this.eventAggregator.publish('site-strings-updated', this.pageContentSiteStrings);
        return this.pageContentSiteStrings;
    }

    async getEnglishSiteStrings(pageId: number): Promise<PageContentArea[]> {
        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode(),
            hrefLang: 'en'
        };
        this.englishSiteStrings = await this.api.doGet(this.path + '/ByPage', params);

        return this.pageContentSiteStrings;
    }

    getSiteString = (key: string, fallback: string = '') => {
        try {
            const viewingAsAdmin = this.sessionService.getAdminView();


            if (viewingAsAdmin) {
                return `SS-Key: { ${key} }`;
            } else {
                const content = this.getSiteStringByKeyFromSubject(key);
                const parser = new DOMParser();
                const parsedString = parser.parseFromString(content?.markup || '', 'text/html');
                const value = parsedString?.body?.innerText === 'undefined' ? fallback : parsedString?.body?.innerText;

                return value;
            }
        } catch (e) {
            console.error('Error in getSiteString:', e);
            return key;
        }
    };

    formatSiteString = (str: string, params: (string|number)[]) => {
        const viewingAsAdmin = this.sessionService.getAdminView();
        if (viewingAsAdmin) {
            return str;
        } else {
            return String.raw({
                raw: str.split('${}')
            }, ...params);
        }
    };

    publishContent() {
        this.helper.debounce(this, 'sendingContent', 'contentTimeout', 350, () =>
            this.eventAggregator.publish('retrieved-content-changed', this.retrievedContent)
        );
    }

    displaySiteString(value: string, fallback = '', params: (string|number)[] = []) {
        try {
            const viewingAsAdmin = this.sessionService.getAdminView();
            fallback ??= '';

            if (viewingAsAdmin) {
                return `SS-Key: { ${value} }`;
            } else {
                const content = this.getSiteStringByKeyFromSubject(value);
                const parser = new DOMParser();
                const parsedString = parser.parseFromString(content?.markup, 'text/html');
                const str = parsedString?.body?.innerText === 'undefined' ? fallback : parsedString?.body?.innerText;
                if (params.length > 0) {
                    return String.raw({
                        raw: str.split('${}')
                    }, ...params);
                }
                return str;
            }
        } catch (e) {
            console.log(e);
            return value;
        }
    }
}
