// Module
var code = `<template>
        <article class="trade-step" repeat.for="listItem of listContent">
            <div class="lc-icon" skeleton-loading="is-loading.bind:pageLoading;">
                <cds-icon icon.bind="listItem.iconPath" label.bind="listItem.alt" variant="outlined"
                    color="#310FA2"></cds-icon>
            </div>
            <div>
                <cx-page-content-area key.bind="listItem.header"
                    class="content-title list-content-title \${pageLoading ? 'd-flex mt-2' : ''}"
                    alternative-key.bind="listItem.alternativeHeader"
                    skeleton-loading="is-loading.bind:pageLoading;width:300px; height:60px;"></cx-page-content-area>
                <cx-page-content-area key.bind="listItem.body"
                    class="content-body content-body-small \${pageLoading ? 'd-flex mt-2' : ''}"
                    alternative-key.bind="listItem.alternativeBody"
                    skeleton-loading="is-loading.bind:pageLoading;width:300px; height:60px;"></cx-page-content-area>
            </div>
        </article>
    </section>
</template>
`;
// Exports
export default code;