// Module
var code = `<template>
    <div id="cx-user-dropdown" class="d-flex align-items-center cursor-pointer h-100 \${isHovered ? 'content-hovered' : ''}"
        mouseenter.trigger="onMouseHover()" mouseleave.trigger="onMouseLeave()" click.delegate="handleDropdownClick()">
        <cx-user-avatar if.bind="width > desktop" user.bind="user" is-navbar="true" is-edit.bind="false" use-spacing.bind="true"
            is-hovered.bind="isHovered" skeleton-loading="is-loading.bind:pageLoading;" class="cx-user-avatar-space"
            data-cy="customer-portal-dropdown-user"></cx-user-avatar>
        <div class="dropdown user-info" element.ref="categoryElement">
            <button class="btn text-regular \${ buttonClass } d-flex align-items-center align-middle" type="button"
                id="dropdownMenuButton" element.ref="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"
                data-cy="customer-portal-dropdown-button">
                <div if.bind="width > desktop"
                    class="username d-inline-block align-middle text-truncate text-capitalize fw-medium \${userClass}"
                    skeleton-loading="is-loading.bind:pageLoading;">\${ firstName }</div>
                <cds-icon if.bind="width > desktop" icon="keyboard_arrow_down" class="p-0 arrow \${isHovered ? 'arrow-down' : ''}" aria-hidden="true"
                    variant="filled" skeleton-loading="is-loading.bind:pageLoading;"></cds-icon>
                <div id="cx-user-avatar" if.bind="width <= desktop" skeleton-loading="is-loading.bind:pageLoading;">
                    <div class="user-img position-relative">
                        <div class="user-profile-container">
                            <cds-icon icon="person" class="user-profile" variant.bind="isHovered ? 'filled' : 'outlined'"></cds-icon>
                        </div>
                    </div>
                </div>
            </button>
            <ul class="dropdown-menu \${class} text-regular" aria-labelledby="dropdownMenuButton" ref="dropdownMenu">
                <cx-customer-portal-navigation user.bind="user" is-home.bind="true" view-model.ref="navigation" is-dropdown.bind="true"
                    data-cy="customer-portal-dropdown-navigation">
                </cx-customer-portal-navigation>
            </ul>
        </div>
    </div>
</template>
`;
// Exports
export default code;