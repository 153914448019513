// Module
var code = `<template>
    <section id="accessibility" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start title-container">
                <cx-page-content-area key.bind="'ACCESSIBILITY_TITLE'" class="tos-title font-bold" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'ACCESSIBILITY_LAST_UPDATED'" class="text-end last-update" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_MAIN_CONTENT'" class="text-content text-content-first tech-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_OUR_COMMITMENT_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_OUR_COMMITMENT_CONTENT'" class="text-content tech-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_WHAT_WE_ARE_DOING_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_WHAT_WE_ARE_DOING_CONTENT'" class="text-content tech-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_LIMITATIONS_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_LIMITATIONS_CONTENT'" class="text-content tech-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_FEEDBACK_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_FEEDBACK_CONTENT'" class="text-content tech-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_ASSISTIVE_TECHNOLOGIES_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_ASSISTIVE_TECHNOLOGIES_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_CONTACT_US_TITLE'" class="text-title font-bold tech-title" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'ACCESSIBILITY_CONTACT_US_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
            `;
// Exports
export default code;