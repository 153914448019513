/* eslint-disable @typescript-eslint/no-explicit-any */

export class SeoContentSection {
    constructor(
        public children: SeoContentItem[]
    ) { }

    setProp(prop: string, value: any): this {
        (this as any)[prop] = value;
        return this;
    }
}

export class SeoContentQuadCard {
    iconPath: string;
    title: string;
    text: string;
    alternativeIconPath: string;
    alternativeTitle: string;
    alternativeText: string;
}

export class SeoContentListItem {
    iconPath: string;
    header: string;
    body: string;
    alternativeIconPath: string;
    alternativeHeader: string;
    alternativeBody: string;
}

export class SeoContentItem {
    private customProperties: { [key: string]: any } = {};

    constructor(
        public title?: string,
        public paragraph?: string,
        public titleStyle?: string,
        public paragraphStyle?: string,
        public image?: string,
        public imageFirst?: boolean
    ) { }

    setProp(prop: string, value: any): this {
        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            (this as any)[prop] = value;
        } else {
            this.customProperties[prop] = value;
        }
        return this;
    }

    getProp(prop: string): any {
        return this.customProperties[prop];
    }

    hasProp(prop: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.customProperties, prop);
    }

    getCustomProperties(): any {
        return this.customProperties;
    }
}
