// Module
var code = `<template>
    <section id="code-of-ethics" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TITLE'" class="title-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_LAST_UPDATED'" class="text-end last-update text-last" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_MAIN_CONTENT'" class="tos-main-content text-content-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CUSTOMER_FIRST_TITLE'" class="text-title font-bold title-2" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CUSTOMER_FIRST_CONTENT'" class="text-content text-content-2" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TRANSPARENCY_TITLE'" class="text-title font-bold title-3" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_TRANSPARENCY_CONTENT'" class="text-content text-content-3"  skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMPILANCE_TITLE'" class="text-title font-bold title-4" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMPILANCE_CONTENT'" class="text-content text-content-4" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_SECURITY_TITLE'" class="text-title font-bold title-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_SECURITY_CONTENT'" class="text-content text-content-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_FAIR_TRADING_PRACTICES_TITLE'" class="text-title font-bold title-6" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_FAIR_TRADING_PRACTICES_CONTENT'" class="text-content text-content-6" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_PRIVACY_TITLE'" class="text-title font-bold title-7" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_PRIVACY_CONTENT'" class="text-content text-content-7" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CONTINUOUS_IMPROVEMENT_TITLE'" class="text-title font-bold title-8" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_CONTINUOUS_IMPROVEMENT_CONTENT'" class="text-content text-content-8" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMMUNITY_RESPONSABILITY_TITLE'" class="text-title font-bold title-8" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'CODE_OF_ETHICS_COMMUNITY_RESPONSABILITY_CONTENT'" class="text-content text-content-8" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;