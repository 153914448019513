import './order-completed.scss';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { SessionService } from 'services/session-service';
import { OrderService } from 'services/order-service';
import { CustomerService } from 'services/customer-service';
import { CurrencyService } from 'services/currency-service';
import { AdvancedOrderSearchOrder, Order, SmallOrder } from 'services/models/order';
import { ToastService } from 'services/toast-service';
import { ImageService } from 'services/image-service';
import { InvoiceService } from 'services/invoice-service';
import { getAWSBucketEndpoint } from 'environment';
import { EasyPostService } from 'services/easypost-service';
import { WebsiteService } from 'services/website-service';
import { SignalrService } from 'services/signalr-service';
import moment from 'moment-timezone';
import {
    Currency,
    NuveiPaymentMethod,
    OrderCryptoExchanges,
    OrderType,
    PayoutableInfo
} from 'services/models/purchase-flow/exchange';
import { HubConnection } from '@microsoft/signalr';
import { TrackingData } from 'services/models/purchase-flow/tracking-data';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { CurrencyTypes } from 'resources/helpers/enums';
import { Helper } from 'resources/helpers/helper';
import { ITableHeader } from 'resources/elements/cx-table/cx-table';
import { DesktopBreakpoint, SizeChangedEvent, UserUpdatedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { User } from 'services/models/user/user';
import { RateCurrencyFormatValueConverter } from 'resources/value-converters/rate-currency-formatter';
import { NuveiService } from 'services/nuvei-service';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class OrderCompleted {
    constructor(
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private orderService: OrderService,
        private router: Router,
        private toastService: ToastService,
        private currencyService: CurrencyService,
        private imageService: ImageService,
        private invoiceService: InvoiceService,
        private easyPostService: EasyPostService,
        private customerService: CustomerService,
        private websiteService: WebsiteService,
        private SignalrService: SignalrService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private helper: Helper,
        private rateCurrencyValueConverter: RateCurrencyFormatValueConverter,
        private nuveiService: NuveiService,
        private signaler: BindingSignaler,
        private pageContentAreaService: PageContentAreaService
    ) {
        this.helper.widthHandler(this);
    }

    user: User;
    userSubscriber: Subscription;
    widthSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    order: Order;
    updatedOrderCryptoExchange: OrderCryptoExchanges;
    vaultedNuveiCards: NuveiPaymentMethod[] | null;
    deliveryFee: number;
    currencies;
    cryptoCurrencies;
    creationDate;
    youGive;
    youReceive;
    transactionFee;
    exchangeRate;
    recipient;
    detailsElement;
    walletAddress;
    baseResults;
    baseCryptoResults;
    targetResults;
    targetCryptoResults;
    generatingPdf;
    pages;
    connection: HubConnection;
    width: number = 0;
    desktop: number = DesktopBreakpoint;

    requestedAddressUpdate = false;
    showSupportMessage = false;

    showErrorMark;
    showCheckMark;
    showPendingMark;

    fileUploaded;
    addressUploading;
    isAddressInReview;
    selectedFiles;
    fileName;
    couponDiscountApply: string;

    baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');

    trackingData: TrackingData;
    validCoupon;

    isManualOrder = false;
    timerInterval = null;
    blocked;
    cxVeriff;
    userVeriffData;

    isSelling : boolean = false;
    timezone: string;
    autoRestart: boolean = true;
    isExchangeOrder: boolean = true;
    mappedOrderSubscriptionRow: SmallOrder;

    tableHeaders: ITableHeader[] = [
        { title: 'id', class: 'id-header-width text-center' },
        { title: 'amount', class: 'column-header-width' },
        { title: 'type', class: 'column-header-width' },
        { title: 'website', class: 'column-header-width' },
        { title: 'date', class: 'column-header-width' },
        { title: 'status', class: 'status-header-width' }
    ];

    mappedOrderRow: AdvancedOrderSearchOrder;
    exchangeRateTooltip = '';
    breadcrumbs = [];
    homePageRoute: string;
    customerPortalPageRoute: string;
    contactUsPageRoute: string;
    isOrderSubscription: boolean = false;
    pageLoading: boolean = true;
    isHistory: boolean = false;
    activeCurrencies: Currency[];

    async activate(params) {
        [this.user, this.order] = await Promise.all([
            this.sessionService.refreshProfile(),
            this.orderService.getById(params.id, 'ordercompleted'),
        ]);
        this.blocked = params.blocked;
        this.isHistory = params?.history;

        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        } else if (!this.order) {
            this.router.navigateToRoute('404');
            return;
        }
        this.websiteService.getManyRoutes(this, ['Contact Us', 'Home', 'Customer Portal']);
        this.isExchangeOrder = this.order?.orderCryptoExchanges?.length > 0;
        if (!this.isExchangeOrder) {
            this.isOrderSubscription = this.order.products?.[0]?.product.game.shortName === 'SUBSCRIPTION';
            this.mappedOrderRow = {
                websiteName: this.order.website.name,
                date: new Date(this.order.createdDate).toLocaleDateString(),
                type: this.order.orderType?.name,
                currencyUsed: this.order.currencyUsed,
                fulfilled: this.order.fulfilled,
                status: this.order.status.split(':')[1],
                total: this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0,
                websiteCode: this.order.website.shortCode,
                id: this.order.id
            };

            if (this.isOrderSubscription) {
                this.tableHeaders =
                    [
                        { title: 'product details', class: 'subscription-header-width' },
                        { title: 'valid until', class: 'valid-column-header-width' },
                        { title: 'price', class: 'column-header-width' },
                        { title: 'status', class: 'status-subs-header-width' }
                    ];
                this.mappedOrderSubscriptionRow = {
                    id: this.order.id,
                    coinFrom: '',
                    coinFromSymbol: '',
                    coinTo: '',
                    targetCurrency: new Currency(),
                    baseCurrency: new Currency(),
                    coinToSymbol:'',
                    fee: this.order.feePrice,
                    quantity: this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0,
                    price: this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0,
                    rate: this.order.currencyRateUsed,
                    status: this.order.status.split(':')[1],
                    fulfilled: this.order.fulfilled,
                    isExchangeOrder: this.isExchangeOrder,
                    products: this.order.products,
                };
            }
        }
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('order-completed');
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            [this.userVeriffData, this.isAddressInReview, this.activeCurrencies] = await Promise.all([
                this.customerService.getVeriffUserData(this.user.id),
                this.sessionService.getAddressVerificationInReview(this.user.id),
                this.currencyService.getActiveCurrenciesByWebsite()
            ]);

            this.setBreadCrumbs();

            this.currencies = this.activeCurrencies.filter(e => e.type === CurrencyTypes.Fiat);
            this.cryptoCurrencies = this.activeCurrencies.filter(e => e.type === CurrencyTypes.Crypto);
            this.creationDate = moment(this.order.createdDate).format(('MMMM Do YYYY, h:mm:ss a'));
            this.timezone = moment.tz().zoneAbbr();

            if (this.order.paymentMethod.reference.includes('nuvei')) {
                this.vaultedNuveiCards = await this.nuveiService.getVaultedCards();
            }

            if (this.order.easyPostTrackingId) {
                this.trackingData = await this.easyPostService.getTrackingData(this.order.easyPostTrackingId);
            }

            if (this.order.couponCode) {
                this.validCoupon = this.order.couponCode;
            }

            if (this.isAddressInReview) {
                this.fileName = 'Under review';
                const fileInput = document.getElementById('fileHolder');
                fileInput?.classList.add('input-password_warning');
            } else {
                this.fileName = 'Please select document';
            }

            this.handleEventSubscriptions();

            if (!this.isExchangeOrder) return;

            this.baseResults = this.getResultByCurrency(this.currencies, 'baseCurrency');
            this.baseCryptoResults = this.getResultByCurrency(this.cryptoCurrencies, 'baseCurrency');
            this.targetResults = this.getResultByCurrency(this.currencies, 'targetCurrency');
            this.targetCryptoResults = this.getResultByCurrency(this.cryptoCurrencies, 'targetCurrency');

            this.youGive = this.baseResults ? this.baseResults.symbol + this.order.orderCryptoExchanges[0].amountBase : this.order.orderCryptoExchanges[0].amountBase + ' ' + this.baseCryptoResults.symbol ;
            this.youReceive = this.targetResults ? this.targetResults.symbol + this.order.orderCryptoExchanges[0].amountTarget : this.order.orderCryptoExchanges[0].amountTarget + ' ' + this.targetCryptoResults.symbol;

            this.walletAddress = this.order.orderCryptoExchanges[0].walletAddress?.substring(0, 10) + '...' + this.order.orderCryptoExchanges[0].walletAddress?.substring(24, 34);

            await this.handleManualOrder();

            this.updatedOrderCryptoExchange = this.order.orderCryptoExchanges[0];
            this.isSelling = !this.updatedOrderCryptoExchange.walletAddress;
            this.calculateCouponDiscount();
            this.getExchangeRateTooltip();
            this.deliveryFee = this.updatedOrderCryptoExchange.targetCurrency.type === CurrencyTypes.Crypto ?
                this.updatedOrderCryptoExchange.deliveryFee :
                this.updatedOrderCryptoExchange.amountBase * ((this.updatedOrderCryptoExchange.deliveryMethod?.markupPercent || 0) + (this.updatedOrderCryptoExchange.deliveryMethod?.baseFee || 0)) / 100;
            this.countdownFinished();
            if (!this.user.notUsingTemporaryPassword && (!this.user.closedTempPasswordBanner || this.helper.checkIfBannerHavePassedSpecificDates(this.user.closedTempPasswordBannerDate))) {
                this.eventAggregator.publish('banner-updated', { successful: 'info', text: 'We\'ve noticed you haven\'t changed your temporary password yet. To safeguard your account, please', clickFunctionName: 'resetPassword' });
            }
            if (this.blocked) {
                this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: this.order.hostedUrl });
            }

            this.connection = await this.SignalrService.getSignalRConnection();
            this.connection.on('SendCryptoExchangeOrderFulfillment', async result => {
                this.order = { ...this.order, ...result };
                await this.handleManualOrder();
                this.stopIntervalLoop();
            });
        } finally {
            this.helper.validateLoading('order-completed');
        }
    }

    @computedFrom('validCoupon')
    get couponExplanation() {
        return `The coupon code used is ${this.validCoupon.code} which provides a ${this.validCoupon.value}${this.validCoupon.type === 'Percent' ? '%' : ''} bonus on orders above $${this.validCoupon.minimumPurchase}.`;
    }

    setBreadCrumbs() {
        const operationTypeRoute = this.getOperationTypeRoute();
        let secondBreadcrumb;
        if (this.isExchangeOrder && !this.isHistory) {
            secondBreadcrumb = {
                title: operationTypeRoute,
                href: `${this.customerPortalPageRoute}/${operationTypeRoute.toLowerCase()}`
            };
        } else {
            if (this.isOrderSubscription) {
                secondBreadcrumb = {
                    title: 'Purchased',
                    href: `${this.customerPortalPageRoute}/purchased`
                };
            } else {
                secondBreadcrumb = {
                    title: 'History',
                    href: `${this.customerPortalPageRoute}/balance/history`
                };
            }
        }

        this.breadcrumbs = [
            { title: 'Home', href: this.homePageRoute },
            secondBreadcrumb,
            { title: `Order details: ${this.order.id}`, name: 'order-completed', isActive: true }
        ];

        this.eventAggregator.publish('breadcrumb-update', { breadcrumbs: this.breadcrumbs });
    }

    getResultByCurrency = (currencies: Currency[], prop: string) => currencies.find(e => e.reference === this.order.orderCryptoExchanges[0][prop].reference);

    getOperationTypeRoute() {
        if (!this.order.orderCryptoExchanges) return;
        if (this.order.orderCryptoExchanges[0]?.operationType === 'S') return 'Purchased';
        if (this.order.orderCryptoExchanges[0]?.operationType === 'B') return 'Sold';
        return 'Exchange';
    }

    detached() {
        this.userSubscriber?.dispose();
        this.widthSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.stopIntervalLoop();
    }

    getFormattedOrderTotal(swapType: 'give' | 'receive') {
        const orderTotal = this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0;

        if (swapType === 'give' && orderTotal < 0) return Promise.resolve(0);
        if (swapType === 'receive' && orderTotal >= 0) return Promise.resolve(0);

        const avoidConversion = Boolean(this.order.paymentMethodId);
        return this.currencyFormatValueConverter.toViewFiatOnly(Math.abs(orderTotal), '(0,0.000)', this.order.currencyUsed, avoidConversion);
    }

    getFormattedAmount(swapType: 'give' | 'receive') {
        if (this.isExchangeOrder) {
            if (!this.updatedOrderCryptoExchange) return '';
            const property = swapType === 'receive' ? 'Target' : 'Base';
            return this.helper.formatByCurrency(
                this.updatedOrderCryptoExchange[`amount${property}`],
                this.updatedOrderCryptoExchange[`${property.toLowerCase()}Currency`],
                this.currencyFormatValueConverter
            );
        }

        return this.getFormattedOrderTotal(swapType);
    }

    get orderYouGive() {
        return this.getFormattedAmount('give');
    }

    get orderYouReceive() {
        return this.getFormattedAmount('receive');
    }

    get orderTransactionFee() {
        if (!this.isExchangeOrder) {
            const feeTotal = this.order.paymentFee ?? 0;
            if (feeTotal === 0) return 0;

            return this.currencyFormatValueConverter.toViewFiatOnly(
                Math.abs(feeTotal),
                '(0,0.000000)',
                this.order.currencyUsed,
                true
            );
        }

        if (!this.updatedOrderCryptoExchange) return '';

        if (this.updatedOrderCryptoExchange.targetCurrency.type === CurrencyTypes.Crypto &&
            this.updatedOrderCryptoExchange.baseCurrency.type !== CurrencyTypes.Crypto) {
            return this.helper.formatByCurrency(
                this.updatedOrderCryptoExchange.cryptoFee,
                this.updatedOrderCryptoExchange.targetCurrency,
                this.currencyFormatValueConverter
            );
        }

        if ((this.updatedOrderCryptoExchange.targetCurrency.type === CurrencyTypes.Crypto &&
            this.updatedOrderCryptoExchange.baseCurrency.type === CurrencyTypes.Crypto) || this.isSelling) {
            return this.helper.formatByCurrency(
                this.updatedOrderCryptoExchange.paymentFee,
                this.updatedOrderCryptoExchange.targetCurrency,
                this.currencyFormatValueConverter
            );
        }

        return this.helper.formatByCurrency(
            this.updatedOrderCryptoExchange.paymentFee,
            this.updatedOrderCryptoExchange.baseCurrency,
            this.currencyFormatValueConverter
        );
    }

    get orderDeliveryFee() {
        if (this.isExchangeOrder) {
            if (!this.updatedOrderCryptoExchange) return '';
            return this.helper.formatByCurrency(
                this.deliveryFee ?? 0,
                this.updatedOrderCryptoExchange.baseCurrency,
                this.currencyFormatValueConverter
            );
        }

        const fee = this.order.deliveryFee ?? 0;
        if (fee === 0) return 0;

        return this.currencyFormatValueConverter.toViewFiatOnly(
            fee,
            '(0,0.000000)',
            this.order.currencyUsed,
            true
        );
    }

    get orderAmountCharged() {
        if (this.isExchangeOrder) {
            return this.orderYouGive;
        }

        const orderTotal = this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0;
        return orderTotal > 0 ? this.orderYouGive : this.orderYouReceive;
    }

    get showDeliveryFee() {
        if (this.isExchangeOrder) {
            return (this.order.orderDetail?.cashInPersonDetail?.meetingType || this.order.orderDetail?.cashInMailDetail?.deliveryType || this.order.orderDetail?.paypalDetail?.deliveryType) && this.deliveryFee;
        }

        return this.order.deliveryFee > 0;
    }

    get showTransactionFee() {
        return this.isExchangeOrder || this.order.paymentFee > 0;
    }

    get orderPaymentMethod() {
        if (this.isExchangeOrder) {
            return this.order.receivingMethod?.name;
        }

        return this.order.paymentMethod.name;
    }

    get showYouGive() {
        const orderTotal = this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0;
        return this.isExchangeOrder || orderTotal >= 0;
    }

    get showYouReceive() {
        const orderTotal = this.order.convertedCurrencyTotal ?? this.order.totalPrice ?? 0;
        return this.isExchangeOrder || orderTotal < 0;
    }

    async updateOrderCryptoExchange() {
        if (!this.user) {
            this.stopIntervalLoop();
            return;
        }
        this.updatedOrderCryptoExchange = await this.orderService.getOrderCryptoExchangeByOrderId(this.order.id);
        if (!this.updatedOrderCryptoExchange) {
            this.updatedOrderCryptoExchange = this.order.orderCryptoExchanges[0];
        }
        this.deliveryFee = this.updatedOrderCryptoExchange.targetCurrency.type === CurrencyTypes.Crypto ?
            this.updatedOrderCryptoExchange.deliveryFee :
            this.updatedOrderCryptoExchange.amountBase * ((this.updatedOrderCryptoExchange.deliveryMethod?.markupPercent || 0) + (this.updatedOrderCryptoExchange.deliveryMethod?.baseFee || 0)) / 100;
        this.calculateCouponDiscount();
        this.getExchangeRateTooltip();
        this.signaler.signal('update-receive-tooltip-content');
    }

    async handleManualOrder() {
        this.isManualOrder = (await this.helper.getCategoryByIdAndName(this.order.paymentMethod.paymentMethodCategoryId, 'Manual') || await this.helper.getCategoryByIdAndName(this.order.receivingMethod?.paymentMethodCategoryId, 'Manual')) && !this.order.status.includes('complete');
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe(UserUpdatedEvent, payload => {
            this.user = payload.user;
        });

        this.widthSubscriber = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    stopIntervalLoop() {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
    }

    async openIntercom() {
        if (window.Intercom) {
            window.Intercom('showNewMessage', 'Hello! I have a question about order #' + this.order?.id);
        }
    }

    showMessage(section) {
        switch (section) {
            case 'address':
                this.requestedAddressUpdate = true;
                break;
            default:
                this.showMessage(section);
        }
        this.showSupportMessage = true;
    }

    triggerDocumentSelector() {
        const fileInput = document.getElementById('documentSelector');
        fileInput.click();
    }

    async handleAddressUpload(file) {
        const fileInput = document.getElementById('fileHolder');
        if (this.isAddressInReview) {
            this.isAddressInReview = false;
            fileInput.classList.remove('input-password_warning');
        }
        this.fileName = file[0].name;
        if (file) {
            const formData = this.imageService.buildFormData(file);
            try {
                this.addressUploading = true;
                const response = await this.imageService.postClientDocument(formData, 2, 2);
                if (response?.id) {
                    this.addressUploading = false;
                    this.toastService.showToast('Uploaded successfully', 'Your file has been uploaded and was set for review', 'success');
                    this.isAddressInReview = true;
                    this.fileName = 'Under review';
                    this.showPendingMark = true;
                    fileInput.classList.add('input-password_warning');
                    setTimeout(async() => {
                        this.user.addressVerificationInReview = true;
                        this.eventAggregator.publish('user-updated', { user: this.user });
                    }, 1000);
                } else {
                    this.addressUploading = false;
                    this.toastService.showToast('Document failed to upload', 'Please review the uploaded file', 'error');
                }
            } catch (e) {
                this.addressUploading = false;
                this.toastService.showToast('Document failed to upload', 'Please review the uploaded file', 'error');
            }
        }
    }

    async resendReceipt() {
        const response = this.orderService.resendReceipt(this.order.id);
        if (response) {
            this.toastService.showToast('Succes', 'A copy of your receipt has been sent to you.', 'success');
        } else {
            this.toastService.showToast('Error', 'Failed to send copy of receipt. Contact Livechat for assistance.', 'error');
        }
    }

    async handleExportingPdf() {
        if (!this.generatingPdf) {
            this.generatingPdf = true;
            try {
                const result = await this.invoiceService.generatePdfReceipt(this.order.id);
                if (result) {
                    const linkElement = document.createElement('a');
                    linkElement.href = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
                    linkElement.download = `cx_order_${this.order.id}_${moment.utc().local().format('MM/DD/YYYY hh:mm a')}.pdf`;
                    linkElement.click();
                    this.toastService.showToast('Success', 'Order PDF successfully downloaded', 'success');
                }
            } catch (e) {
                console.log(e);
                this.toastService.showToast('Error', 'Something went wrong! Please try again.', 'error');
            } finally {
                this.generatingPdf = false;
            }
        }
    }

    async openCxVeriff() {
        await this.cxVeriff.au.controller.viewModel.startVerification();
    }

    calculateCouponDiscount() {
        if (!this.validCoupon) return '';

        const discount = this.updatedOrderCryptoExchange?.couponAmountCalculated;
        if (this.updatedOrderCryptoExchange?.targetCurrency?.type === CurrencyTypes.Crypto)
            this.couponDiscountApply = ` +${discount.toFixed(6)} ${this.updatedOrderCryptoExchange?.targetCurrency.symbol}`;
        else
            this.couponDiscountApply = ` + ${this.updatedOrderCryptoExchange?.targetCurrency.symbol}${discount.toFixed(2)}`;
    }

    /**
     * Function that triggers when timer ends, the timer is the one to invoque this function.
     */
    async countdownFinished() {
        if (this.isManualOrder) {
            this.updateOrderCryptoExchange();
        }
    }

    getExchangeRateTooltip() {
        const rate = this.isSelling ? this.updatedOrderCryptoExchange?.rate : 1 / this.updatedOrderCryptoExchange?.rate;
        let exchangeRate = '';

        if (this.isSelling) {
            exchangeRate = `${this.rateCurrencyValueConverter.toView(
                rate,
                this.updatedOrderCryptoExchange?.targetCurrency?.symbol,
                null) } / 1.00 ${this.updatedOrderCryptoExchange?.baseCurrency.symbol}`;
        } else {
            exchangeRate = `${this.currencyFormatValueConverter.toView(
                rate,
                this.updatedOrderCryptoExchange?.baseCurrency?.symbol,
                this.updatedOrderCryptoExchange?.baseCurrency.type,
                this.updatedOrderCryptoExchange?.baseCurrency?.isStable) } / 1.00 ${this.updatedOrderCryptoExchange?.targetCurrency?.symbol}`;
        }

        if (this.isFiat2Fiat) {
            exchangeRate = `${this.currencyFormatValueConverter.toView(
                rate,
                this.updatedOrderCryptoExchange?.targetCurrency?.symbol,
                this.updatedOrderCryptoExchange?.targetCurrency.type,
                this.updatedOrderCryptoExchange?.targetCurrency?.isStable) } / 1.00 ${this.updatedOrderCryptoExchange?.baseCurrency?.code}`;
        }

        if (this.isCrypto2Crypto) {
            exchangeRate = `${this.currencyFormatValueConverter.toView(
                1 / this.updatedOrderCryptoExchange?.rate,
                this.updatedOrderCryptoExchange?.baseCurrency?.code,
                this.updatedOrderCryptoExchange?.baseCurrency.type,
                this.updatedOrderCryptoExchange?.baseCurrency?.isStable,
                null)}/ 1.00 ${this.updatedOrderCryptoExchange?.targetCurrency?.code}`;
        }

        this.exchangeRateTooltip = `The exchange rate used is ${exchangeRate}, which is the live-mid market rate and is updated every 15 seconds.`;
    }

    getOrderCardData(): PayoutableInfo | null {
        if (this.order?.cardLast4 || this.order?.cardLastFour) return { address: `****${this.order.cardLast4 ?? this.order.cardLastFour}`, code: this.order.cardType };
        if (this.order?.paymentMethod?.reference.includes('nuvei')) {
            const foundCard = this.vaultedNuveiCards?.find(x => x.userPaymentOptionId.toString() === this.order.orderDetail?.nuveiOrderDetail?.userPaymentOptionId);
            if (foundCard) return { address: foundCard.upoData.ccCardNumber, code: foundCard.upoData.brand, additionalInformation: foundCard.upoData.ccNameOnCard };
        }

        return null;
    }

    get paymentMethodDetails(): PayoutableInfo | null {
        if (this.isWithdrawOrder) {
            const foundMethod = this.user.receivingPaymentMethods.find(x => x.paymentMethodId === this.order.paymentMethodId);
            if (foundMethod) return { address: foundMethod.address, code: foundMethod.code, type: foundMethod.transactionAddressType };
        }

        const cardData = this.getOrderCardData();
        if (cardData) return cardData;

        return null;
    }

    get isWithdrawOrder() {
        return this.order?.orderType?.id === OrderType.Withdraw;
    }

    get showSimplePaymentMethod() {
        return !this.paymentMethodDetails;
    }

    get paymentMethodDescription() {
        if (!this.paymentMethodDetails.type) return `${this.helper.getCardName(this.paymentMethodDetails.code)} ${this.paymentMethodDetails.address.slice(-8)}`;
        return this.paymentMethodDetails.address;
    }

    get paymentMethodHolderName() {
        if (this.paymentMethodDetails.additionalInformation) return this.paymentMethodDetails.additionalInformation;
        let name = this.user.firstName;
        if (this.user.lastName) name = `${name} ${this.user.lastName}`;
        return name;
    }

    get methodImageSource() {
        if (!this.paymentMethodDetails.type) return this.helper.getCardImageType(this.paymentMethodDetails.code);
        return `${this.baseAwsEndpoint}${this.order?.paymentMethod?.imagePath}`;
    }

    get isFiat2Fiat() {
        return this.updatedOrderCryptoExchange.baseCurrency.type === 'F' && this.updatedOrderCryptoExchange.targetCurrency.type === 'F';
    }

    get isCrypto2Crypto() {
        return this.updatedOrderCryptoExchange.baseCurrency.type === 'C' && this.updatedOrderCryptoExchange.targetCurrency.type === 'C';
    }
}
