import './privacy-policy.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';

@autoinject()
export class PrivacyPolicy {
    pageLoading: boolean = true;
    pageRoutes;
    pageLoaded;
    routerSubscription: Subscription;
    width;
    sizeChanged: Subscription;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private helper: Helper,
        private eventAggregator: EventAggregator,
    )
    {
        this.pageContentAreaService = pageContentAreaService;
        this.websiteService = websiteService;
        this.helper = helper;
        this.eventAggregator = eventAggregator;
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('privacy');
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.handleEventSubscriptions();
            await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Privacy Policy')?.id);
            setTimeout(() => {
                const cookiesContainer = document.querySelector('.cookies')?.querySelectorAll('p');
                cookiesContainer?.forEach((e, index) => {
                    index > 2 && index < 7 ? e.style.marginLeft = '20px' : e.style.marginLeft = '0';
                });
            }, 500);

        } finally {
            this.helper.validateLoading('privacy');
        }
    }

    handleEventSubscriptions() {
        this.pageLoaded = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }
}
