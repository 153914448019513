// Module
var code = `<template>
    <section id="bug-bounty" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold py-2 cx-background text-light">
            <div class="mx-auto header-title text-start title-1" skeleton-loading="is-loading.bind:pageLoading;">Bug Bounty</div>
        </div>
        <div class="container text-medium">
            <cx-page-content-area 
                key.bind="'BUG_BOUNTY_LAST_UPDATED'"
                class="text-end last-update text-1"
                skeleton-loading="is-loading.bind:pageLoading;"
            >
            </cx-page-content-area>
            <cx-page-content-area 
                key.bind="'BUG_BOUNTY_CONTENT'"
                class="text-content content-1"
                trigger-border-change="true"
                skeleton-loading="is-loading.bind:pageLoading;"
            >
            </cx-page-content-area>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>`;
// Exports
export default code;