import './balance-withdraw.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { SessionService } from 'services/session-service';
import { User } from 'services/models/user/user';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PasswordVerificationState, UserVerification } from 'resources/extensions/user_verification';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';
import {
    NuveiAddUPOCreditCardResponse,
    NuveiPaymentDataRequest,
    OrderType,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    RecentlySavedCC
} from 'services/models/purchase-flow/exchange';
import { observable, computedFrom } from 'aurelia-framework';
import { CurrencyChangedEvent, ForceCurrencyEvent, MethodPayoutableChangedEvent } from 'resources/constants';
import { CurrencyService } from 'services/currency-service';
import { ToastService } from 'services/toast-service';
import { CxVeriff } from 'resources/elements/cx-veriff/cx-veriff';
import { ProductService } from 'services/product-service';
import { baseUrl, websiteShortCode } from 'environment';
import { NuveiService } from 'services/nuvei-service';
import { OrderService } from 'services/order-service';
import { Order, OrderRequest } from 'services/models/order';
import { Router } from 'aurelia-router';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { ICurrencyChangedEvent, IForceCurrencyEvent, IMethodPayoutableUpdated } from 'types/events';
import { IMdcTooltip, TooltipStyling } from 'resources/helpers/tooltip-styling';
import { CreditCardHelper } from 'resources/helpers/credit-card-helper';
import { Toast, ToastType } from 'services/models/toast';
import { SignalrService } from 'services/signalr-service';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { HubConnection } from '@microsoft/signalr';
import { PaymentRequestService } from 'services/payment-request-service';
import { ICreatePaymentRequest, PaymentRequestCategory } from 'services/models/payment-request';
import { BlockActionLogService } from 'services/block-action-log-service';

@autoinject()
export class BalanceWithdraw extends UserVerification {
    user: User;
    convertedBalance = '';
    currencySubscriber: Subscription;
    methodPayoutableSubscriber: Subscription;
    balancePendingTooltipRef: IMdcTooltip;
    balanceTooltipRef: IMdcTooltip;
    currencySymbol = '';
    conversionRate: number | null = null;
    usdAmount: number | null = 0;
    transactionFee: number | null = 0;
    twoFactorStep = false;
    loadingOrder = false;
    customerPortalPageRoute?: string;
    @observable amount: number | null;
    @observable currentCurrency: string;
    @observable selectedPaymentMethod: PaymentMethodWebsite;
    @observable withdrawPassword: string | null;
    tempAmount: number;
    iconByState = {
        [PasswordVerificationState.Success]: 'check_circle_outline',
        [PasswordVerificationState.Error]: 'error_outline'
    };

    ccRecentlySaved: RecentlySavedCC | null;
    veriffViewModel: CxVeriff;

    connection: HubConnection;
    blacklistEvent = 'BlacklistResolution';

    private redirectPaymentMethods = ['ideal', 'sofort', 'checkout', 'google-pay-direct', 'apple-pay-direct'];
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;
    savedWithdrawPassword: string;

    constructor(
        private sessionService: SessionService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private currencyService: CurrencyService,
        private toastService: ToastService,
        private productService: ProductService,
        private nuveiService: NuveiService,
        private orderService: OrderService,
        private router: Router,
        private deliveryDateConverter: DeliveryDateConverter,
        private tooltipStyling: TooltipStyling,
        private signalRService: SignalrService,
        private websiteService: WebsiteService,
        private pageContentAreaService: PageContentAreaService,
        private paymentRequestService: PaymentRequestService,
        private blockActionLogService: BlockActionLogService,
        eventAggregator: EventAggregator,
        paymentMethodService: PaymentMethodWebsiteService,
        customerService: CustomerService,
        helper: Helper,
        creditCardHelper: CreditCardHelper
    ) {
        super(eventAggregator, paymentMethodService, customerService, helper, creditCardHelper);
    }

    async activate() {
        this.user = await this.sessionService.getProfile();
        this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.getTotalBalance());
        this.customerPortalPageRoute = await this.websiteService.getRoute('Customer Portal');
        await this.setCurrencySymbol();
    }

    attached() {
        try {
            this.helper.addLoadingComponent('balance-withdraw');
            this.startVerificationListeners();
            this.handleEventSubscriptions();

            this.signalRService.getSignalRConnection().then((connection) => {
                if (!connection) {
                    return;
                }
                this.connection = connection;
                this.connection.on(this.blacklistEvent, async() => {
                    this.user = await this.sessionService.getProfile(true);
                });
            });
        } finally {
            this.helper.validateLoading('balance-withdraw');
        }
    }

    detached() {
        this.clearVerificationListeners();
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.currencySubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, async(payload: ICurrencyChangedEvent) => {
            this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.getTotalBalance(), null, payload.currencySelected.originalCode);
            await this.setCurrencySymbol(payload.currencySelected.originalCode);
        });

        this.methodPayoutableSubscriber = this.eventAggregator.subscribe(MethodPayoutableChangedEvent, (payload: IMethodPayoutableUpdated) => {
            if (this.selectedPaymentMethod?.paymentMethodId !== payload.paymentMethod.id) return;
            this.selectedPaymentMethod.paymentMethod.payoutableInfo = payload.paymentMethod.payoutableInfo;
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    async setCurrencySymbol(newCurrency?: string) {
        const currency = newCurrency ?? await this.sessionService.getCurrency();
        if (currency !== this.currentCurrency) this.currentCurrency = currency;
        this.currencySymbol = this.currencyFormatValueConverter.getCurrencyPrefix(this.currentCurrency);
    }

    async userVerificationUpdated(user: User) {
        await this.checkForVerification(user, this.amount, this.selectedPaymentMethod?.paymentMethod);
    }

    async phoneVerificationUpdated(phoneConfirmed: boolean) {
        if (!phoneConfirmed) {
            this.user.phoneVerified = false;
        }

        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async autoTriggerOrder() {
        await this.sessionService.refreshProfile();
        await this.startOrder();
    }

    async validateAmountStep() {
        await this.canCreateOrder();
    }

    async startOrder() {
        const createOrder = await this.validateVeriff();
        if (!createOrder) return;

        this.loadingOrder = true;
        const orderData = await this.buildOrder();
        const order = await this.orderService.createTopUpBalanceOrder(orderData);

        if (!order) {
            this.toastService.showToast('Error', 'Failed to create order', 'error');
            this.payEnabled = true;
            this.loadingOrder = false;
            return;
        }

        await this.createPaymentRequest(order);

        if (order.hostedUrl) {
            //Coinbase Commerce Order & iDEAL (BlueSnap) & Coinpayments & BTCPay & BitCart & Sofort & Skrill Direct
            this.finish(order, order.hostedUrl);
        } else if (order.g2aToken) {
            //G2A Orders
            this.finish(order, 'https://checkout.pay.g2a.com/index/gateway?token=' + order.g2aToken);
        } else if (order.paypalOrderAggregate && order.paypalOrderAggregate.paymentUrl) {
            //Paypal orders
            this.finish(order, order.paypalOrderAggregate.paymentUrl);
        }

        this.toastService.showToast(undefined, 'Your withdrawal request is now in process. Depending on the payment method and amount requested it may take up to 7 days to complete.', ToastType.SUCCESS);
        this.payEnabled = true;
        this.twoFactorStep = false;
        this.loadingOrder = false;
        this.tempAmount = this.amount = null;
        this.withdrawPassword = null;
    }

    async createPaymentRequest(order: Order) {
        const currency = await this.currencyService.getCurrencyByCode(this.currentCurrency);

        const request: ICreatePaymentRequest = {
            amount: parseFloat(order.convertedCurrencyTotal.toFixed(2)),
            paymentAddress: this.selectedPaymentMethod?.paymentMethod.payoutableInfo?.address,
            reason: 'Withdraw request from user.',
            requesterId: order.userId,
            paymentMethodId: order.paymentMethodId,
            confirmationEmail: order.user.email,
            orderId: order.id,
            category: PaymentRequestCategory.BalanceWithdraw,
            status: 'Pending',
            comment: '',
            refundType: '',
            currencyId: currency.id
        };

        const response = await this.paymentRequestService.add(request);

        if (!response) {
            this.toastService.showToast('Error', 'Failed to create payment request.', ToastType.ERROR);
        }
    }

    async validateVeriff() {
        this.payEnabled = false;
        const checkForStartVerification = await this.checkForStartVerification();

        if (checkForStartVerification) {
            this.veriffViewModel.startVerification();
            return false;
        }

        return true;
    }

    openLivechat(order: Order) {
        if (!window.Intercom) return;
        window.Intercom('showNewMessage', `Hello! I have a question about order #${order.id}`);
    }

    handleTwoFactorBack() {
        this.twoFactorStep = false;
        this.passwordVerificationState = null;
        this.tooManyAttemps = false;
    }

    async canCreateOrder() {
        const paymentMethodValid = await this.validatePaymentMethod();
        if (!paymentMethodValid) return false;

        return await this.validateWithdraw();
    }

    async validatePaymentMethod() {
        if (!this.selectedPaymentMethod?.paymentMethod) return false;

        if (this.selectedPaymentMethod.paymentMethod.usesCard && !this.selectedPaymentMethod.paymentMethod.cardInfo) {
            this.toastService.showToast('Payment method error', 'Missing card information', ToastType.ERROR);
            return false;
        }

        return true;
    }

    async validateWithdraw() {
        const currentAmountAvailable = this.getTotalBalance();
        const has2faPass = await this.getHasWithdrawPassword();
        const verifiedLevel2 = this.user.phoneNumberConfirmed && this.user.emailConfirmed && this.user.idVerified;
        const verifiedLevel3 = verifiedLevel2 && this.user.addressVerified;

        if (!verifiedLevel2) {
            this.showInfo(`To make a withdraw, level 2 verification is required. Please visit <a href="${this.customerPortalPageRoute}/verification" class="text-decoration-underline">${this.customerPortalPageRoute}/verification</a>.`);
            return false;
        }

        if (this.usdAmount <= this.transactionFee) {
            this.showInfo('The withdrawal fee surpasses your current balance. Please reconsider your withdrawal amount or verify your balance and try again.');
            return false;
        }

        if (!has2faPass) {
            await this.showInfo('To withdraw funds from your balance please first set up your withdraw password in security settings.');
            return false;
        }

        if (this.usdAmount > currentAmountAvailable) {
            await this.showInfo(`The maximum amount available to withdraw including your pending balance is ${this.convertedBalance}.`);
            return false;
        }

        if (this.selectedPaymentMethod.minimumWithdrawAmount && this.selectedPaymentMethod?.minimumWithdrawAmount > this.usdAmount) {
            await this.showInfo(`The minimum amount to withdraw is $${this.selectedPaymentMethod.minimumWithdrawAmount}.`);
            return false;
        }

        if (this.selectedPaymentMethod.maximumWithdrawAmount && this.selectedPaymentMethod?.maximumWithdrawAmount < this.usdAmount) {
            await this.showInfo(`The maximum amount to withdraw is $${this.selectedPaymentMethod.maximumWithdrawAmount}.`);
            return false;
        }

        if (!this.helper.includesSome(this.selectedPaymentMethod.paymentMethod.name, ['PayPal Direct', 'PayPal'])) {
            if (this.usdAmount > this.user.balance && !verifiedLevel3) {
                await this.showInfo('The amount requested to withdraw can only be done through Paypal. Please change the amount requested, or the payment method and try again.');
                return false;
            }
        }

        this.twoFactorStep = true;
        return true;
    }

    async showInfo(message: string) {
        await this.toastService.showToast(undefined, message, ToastType.INFO);
    }

    private getTotalBalance() {
        return (this.user.balance ?? 0) + (this.user.pendingBalance ?? 0);
    }

    async finish(order: Order, url: string) {
        if (this.selectedPaymentMethod.paymentMethod.reference.includes('bluesnap')) {
            await this.sessionService.refreshProfile();
        }

        if (!url) return;

        if (this.helper.includesSome(order.paymentMethod.reference, this.redirectPaymentMethods)) {
            this.router.navigate(url);
            return;
        }

        const checkOutWindow = window.open(url);

        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: url });
        }
    }

    async buildOrder(): Promise<OrderRequest | null> {
        const productsResponse = await this.productService.getProductsWithFilter('Balance');

        if (!productsResponse?.length) return;

        const product = productsResponse[0];
        let pylonPaymentData: PylonPaymentDataRequest;
        let nuveiPaymentData: NuveiPaymentDataRequest;

        switch (this.selectedPaymentMethod.paymentMethod.reference) {
            case 'pylon': {
                pylonPaymentData = {
                    cardReferenceID: null,
                    baseUrl: baseUrl(),
                };
                break;
            }
            case 'nuvei': {
                let nuveiCardSaved: NuveiAddUPOCreditCardResponse | null = null;
                if (this.ccRecentlySaved) nuveiCardSaved = await this.nuveiService.addCreditCard(this.ccRecentlySaved);

                nuveiPaymentData = {
                    cvv: this.ccRecentlySaved?.cvv,
                    userPaymentOptionId: nuveiCardSaved ? nuveiCardSaved.userPaymentOptionId : parseInt(this.selectedPaymentMethod.paymentMethod.cardInfo.cardId ?? '0')
                };
                break;
            }
        }

        return {
            websiteShortCode: websiteShortCode(),
            totalPrice: this.usdAmount,
            products: [{
                productId: product.id,
                quantity: 1,
                price: this.usdAmount,
                serviceFullName: `Withdraw ${product.name}`
            }],
            currencyUsed: this.currentCurrency,
            paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
            status: `${this.selectedPaymentMethod.paymentMethod.reference}:created`,
            address: this.ccRecentlySaved?.billingAddress?.address,
            city: this.ccRecentlySaved?.billingAddress?.city,
            state: this.ccRecentlySaved?.billingAddress?.state,
            country: this.ccRecentlySaved?.billingAddress?.country,
            zip: this.ccRecentlySaved?.billingAddress?.zip,
            referralLinkUrl: this.sessionService.getReferralLink(),
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            cardLastFour: this.ccRecentlySaved?.ccCardNumber?.slice(-4),
            cardType: this.ccRecentlySaved?.ccCardNumber ? this.creditCardHelper.getType(this.ccRecentlySaved.ccCardNumber) : null,
            pylonPaymentData,
            nuveiPaymentData,
            cardCvv: this.ccRecentlySaved?.cvv,
            orderTypeId: OrderType.Withdraw,
            isWithdraw: true,
            currentWithdrawPassword: this.savedWithdrawPassword
        };
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }

    setTransactionFee() {
        if (!this.selectedPaymentMethod) {
            this.transactionFee = 0;
            return;
        }

        if (this.usdAmount > 0) {
            const withdrawFee = this.selectedPaymentMethod.withdrawFee ?? 0;
            const baseFee = this.selectedPaymentMethod.baseWithdrawFee ?? 0;
            this.transactionFee = parseFloat(((this.usdAmount * (withdrawFee / 100)) + baseFee).toFixed(2));
            return;
        }

        this.transactionFee = 0;
    }

    setTooltipStyle(element: IMdcTooltip, pending = false) {
        if (!pending) this.tooltipStyling.setArrowPosition(element, 'BELOW_START');
        if (pending) this.tooltipStyling.addWidthClass(element);
        this.tooltipStyling.setMaxWidth(element, pending ? 340 : 372);
    }

    showToast = (data: Toast) => {
        this.toastService.showToast(data.title, data.message, data.toastType);
    };

    async selectedPaymentMethodChanged(newValue: PaymentMethodWebsite) {
        const eventData: IForceCurrencyEvent = {
            currentPaymentMethodSelected: newValue,
            currency: await this.sessionService.getCurrency(),
            forceSelector: true
        };
        const previousPaymentMethod = await this.sessionService.getPreviousPaymentMethod();
        if (previousPaymentMethod !== newValue.paymentMethod.reference) {
            await this.sessionService.savePreviousPaymentMethod(newValue.paymentMethod.reference);
            await this.sessionService.refreshProfile();
        }
        this.eventAggregator.publish(ForceCurrencyEvent, eventData);
        this.setTransactionFee();
        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async currentCurrencyChanged(newValue: string | null) {
        if (!newValue || newValue === 'USD') this.conversionRate = 1;
        else this.conversionRate = await this.currencyService.getConvertionRateToUSD(newValue, true);

        if (this.amount) this.usdAmount = this.amount * this.conversionRate;
        if (this.selectedPaymentMethod) this.setTransactionFee();
    }

    async amountChanged(newValue: number | null) {
        if (newValue) this.usdAmount = newValue * this.conversionRate;
        else this.usdAmount = null;

        if (this.selectedPaymentMethod) this.setTransactionFee();
        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async withdrawPasswordChanged(newValue: string | null) {
        this.savedWithdrawPassword = newValue;
        await this.verifyWithdrawPassword(newValue);
        if (this.passwordVerificationState !== PasswordVerificationState.Success) return;
        await this.startOrder();
    }

    @computedFrom('usdAmount', 'selectedPaymentMethod', 'payEnabled', 'veriffStep', 'selectedPaymentMethod.paymentMethod.payoutableInfo')
    get disabledPayButton() {
        return !this.usdAmount
            || !this.selectedPaymentMethod
            || (!this.payEnabled && !this.veriffStep)
            || !this.selectedPaymentMethod.paymentMethod.payoutableInfo;
    }

    @computedFrom('passwordVerificationState', 'tooManyAttempts', 'loadingOrder')
    get passwordInputDisabled() {
        return this.passwordVerificationState === PasswordVerificationState.Loading || this.tooManyAttemps || this.loadingOrder;
    }

    @computedFrom('passwordVerificationState')
    get passwordInputIcon() {
        return this.iconByState[this.passwordVerificationState] ?? '';
    }

    @computedFrom('passwordVerificationState')
    get withdrawPasswordInputStyle() {
        if (!this.passwordVerificationState) {
            return '';
        }
        const name = PasswordVerificationState.enumName(this.passwordVerificationState);
        return `input-outline--${name.toLowerCase()}`;
    }

    getTooltipText(title: string, message: string) {
        const data = {
            title: title,
            message: '',
            toastType: ToastType.TOOLTIP
        };
        if (!this.user) return data;
        data.message = `All balances are stored as USD, so the amount in other currencies will fluctuate. ${message}`;
        return data;
    }

    @computedFrom('user', 'user.totalBalance', 'user.pendingBalance', 'getTotalBalance')
    get tooltipText() {
        const message = `The balance available, which includes the pending balance converted to USD is $${this.getTotalBalance()}.`;
        return this.getTooltipText('Balance available', message);
    }

    @computedFrom('user', 'user.pendingBalance')
    get balancePendingTooltipText() {
        const message = `\n\n$${this.user.pendingBalance} USD can only be withdrawn immediately through PayPal due to the sale of an account(s). After six months your pending balance will retroactively be added to your regular cash balance where you can withdraw in any method.\n\nFor users who have a profile spend of 10k or more with level 3 verification, this restriction is removed.`;
        return this.getTooltipText('Balance pending', message);
    }

    @computedFrom('usdAmount', 'transactionFee')
    get totalWithFees() {
        return this.usdAmount - this.transactionFee;
    }

    @computedFrom('conversionRate')
    get invertedRate() {
        return 1 / this.conversionRate;
    }

    @computedFrom('amount', 'convertedBalance')
    get buttonText() {
        return this.getButtonText();
    }

    async getButtonText() {
        return this.pageContentAreaService.getSiteString('CX_WITHDRAW') + ' • ' + await this.currencyFormatValueConverter.toViewFiatOnly(this.amount || 0, null, null, true);
    }

    blurNumberAmount() {
        if (this.tempAmount > 999999) {
            this.tempAmount = 999999;
        }
        this.amount = this.tempAmount;
        this.setTransactionFee();
    }

    focusTextAmount() {
        this.amount ? this.tempAmount = this.amount : this.tempAmount;
    }

    async checkForStartVerification() {
        if (this.user.idVerified) return false;

        const amountRequiresVeriff = await this.requiresVeriff(this.user, this.selectedPaymentMethod.paymentMethod, this.usdAmount);
        if (amountRequiresVeriff) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in Withdraw', reason: 'Thresholds' });
            return true;
        }

        if (this.veriffStep) {
            return true;
        }

        if (this.sessionService.checkVeriffBlacklistUser()) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in Withdraw', reason: 'User Blacklisted' });
            return true;
        }

        return false;
    }
}
