// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/purchase-flow/summary.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/purchase-flow/user.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/purchase-flow/payment_details.svg", import.meta.url);
// Module
var code = `<!-- NOTE: For this Page, every section that is used solely for an specific payment method should have its own component conditionally rendered, for instance, the tracking section should be a component of its own, only shown 
when Cash In Mail is the selected Payment Method. -->

<template>
    <section id="purchase-flow">
        <div class="container">
            <div class="box summary-box purchase-flow-spacing">
                <template if.bind="loading">
                    <!-- SKELETON UI HAS TO BE ADDED HERE -->
                    <div class="skeleton-loader">
                        <div class="skeleton-line"></div>
                        <div class="skeleton-line"></div>
                        <div class="skeleton-line"></div>
                    </div>
                </template>
                <template else>
                    <div class="text-large-plus">
                        <img src="${___HTML_LOADER_IMPORT_0___}" alt="Summary" loading="lazy">
                        \${'CX_SUMMARY' | siteStrings}
                    </div>
                    <div class="text-regular text-black purchase-flow-spacing">
                        <div class="summary-content-grid-row">
                            <div>
                                \${'CX_YOU_GIVE' | siteStrings}
                            </div>
                            <div class="grid-justify-self-end">
                                <cx-currency-text-input-selector
                                    class="currency-component"
                                    currency-list.bind="exchange.giveCurrencyOptions"
                                    min-amount.bind="paymentMethodToUse.minimum * usdRates[exchange.giveSelectedCurrency.code]"
                                    max-amount.bind="paymentMethodToUse.maximum * usdRates[exchange.giveSelectedCurrency.code]"
                                    payment-method-selected-name.bind="paymentMethodToUse.paymentMethod.name"
                                    currency-selected.two-way="exchange.giveSelectedCurrency"
                                    exchange-currency-selected.two-way="exchange.receiveSelectedCurrency"
                                    amount.two-way="exchange.amountGifted"
                                    active-currencies.bind="activeCurrencies"
                                    intent="give"
                                    state="toText"
                                    most-popular-currencies.bind="mostPopularGiveCurrencies"
                                    other-currencies.bind="otherGiveCurrencies"
                                ></cx-currency-text-input-selector>
                            </div>
                        </div>
                        <div class="summary-content-grid-row">
                            <div>
                                \${'CX_TRANSACTION_FEE' | siteStrings}
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    aria-label="tooltip"
                                    id="transaction-fee-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="transaction-fee-icon"
                                    placement="right"
                                    show-arrow="true">
                                    \${'CX_TRANSACTION_FEE_TOOLTIP' | siteStrings}
                                </cds-tooltip>
                            </div>
                            <div class="grid-justify-self-end">
                                <template if.bind="exchange.receiveSelectedCurrency.type === 'F'">
                                    <span>\${exchange.transactionFee | currencyFormat:exchange.receiveSelectedCurrency.symbol:exchange.receiveSelectedCurrency.type:exchange.receiveSelectedCurrency.isStable}</span>
                                </template>
                                <template else>
                                    <span if.bind="exchange.receiveSelectedCurrency.type === 'C'">\${exchange.cryptoFee | currencyFormat:exchange.receiveSelectedCurrency.symbol:exchange.receiveSelectedCurrency.type:exchange.receiveSelectedCurrency.isStable}</span>
                                    <span else>\${exchange.cryptoFee | currencyFormat:exchange.giveSelectedCurrency.symbol:exchange.giveSelectedCurrency.type:exchange.giveSelectedCurrency.isStable}</span>
                                </template>
                            </div>
                        </div>
                        <div class="summary-content-grid-row" if.bind="exchange.deliveryFeeBeforeRateApplied">
                            <div>
                                \${'CX_DELIVERY_FEE' | siteStrings}
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    aria-label="tooltip"
                                    id="delivery-fee-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="delivery-fee-icon"
                                    placement="right"
                                    show-arrow="true">
                                    \${'CX_DELIVERY_FEE_TOOLTIP' | siteStrings}
                                </cds-tooltip>
                            </div>
                            <div class="grid-justify-self-end">
                                \${exchange.deliveryFeeBeforeRateApplied | currencyFormat:exchange.giveSelectedCurrency.symbol:exchange.giveSelectedCurrency.type:exchange.giveSelectedCurrency.isStable}
                            </div>
                        </div>
                        <cx-coupon-input
                            class="d-none"
                            view-model.ref="couponInputViewModel"
                            valid-coupon.two-way="exchange.validCoupon"
                            you-give-amount.bind="exchange.amountGifted"
                            you-receive-amount.bind="exchange.amountReceived"
                            you-receive-currency.bind="exchange.receiveSelectedCurrency"
                            from-checkout.bind="true"
                        ></cx-coupon-input>
                        <div class="summary-content-grid-row" if.bind="exchange.validCoupon">
                            <div>
                                \${'CX_COUPON' | siteStrings}
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    aria-label="tooltip"
                                    id="coupon-code-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="coupon-code-icon"
                                    placement="right"
                                    show-arrow="true">
                                    \${couponTooltip()}
                                </cds-tooltip>
                            </div>
                            <div class="grid-justify-self-end">
                                <span class="coupon-discount">\${couponDiscount}</span>
                            </div>
                        </div>
                        <div class="summary-content-grid-row">
                            <div class="receive-content-wrapper">
                                \${'CX_YOU_RECEIVE' | siteStrings}
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    aria-label="tooltip"
                                    id="receive-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    reference-id="receive-icon"
                                    placement="right"
                                    show-arrow="true">
                                        <div class="d-flex flex-column" receive-tooltip-content="exchange.bind: exchange"></div>
                                </cds-tooltip>
                            </div>
                            <div class="grid-justify-self-end">
                                <cx-currency-text-input-selector
                                    class="currency-component"
                                    currency-list.bind="exchange.receiveCurrencyOptions"
                                    currency-selected.two-way="exchange.receiveSelectedCurrency"
                                    exchange-currency-selected.two-way="exchange.giveSelectedCurrency"
                                    amount.two-way="exchange.amountReceived"
                                    active-currencies.bind="activeCurrencies"
                                    intent="receive"
                                    state="toText"
                                    most-popular-currencies.bind="mostPopularReceiveCurrencies"
                                    other-currencies.bind="otherReceiveCurrencies"
                                ></cx-currency-text-input-selector>
                            </div>
                        </div>
                        <div class="summary-content-grid-row" if.bind="selectedDelivery">
                            <div>\${'CX_DELIVERY_TIME' | siteStrings}</div>
                            <div class="grid-justify-self-end">
                                \${getDeliveryDate(selectedDelivery)}
                            </div>
                        </div>
                        <div class="summary-content-grid-row" if.bind="exchange.receiveSelectedCurrency.type === 'F' || (exchange.receiveSelectedCurrency.type === 'C' && isWalletAddressValid)">
                            <div class="d-flex align-items-center">
                                \${'CX_RECIPIENT' | siteStrings}
                                <mdc-icon class="ms-1 material-symbols-outlined tooltip-icon vertical-align-sub"
                                    if.bind="(exchange.receiveSelectedCurrency.type === 'F' && user) || exchange.receiveSelectedCurrency.type === 'C'"
                                    aria-label="tooltip"
                                    id="recipient-icon">
                                    contact_support
                                </mdc-icon>
                                <cds-tooltip 
                                    if.bind="exchange.receiveSelectedCurrency.type === 'F' && user"
                                    reference-id="recipient-icon"
                                    class="recipient-tooltip"
                                    placement="right"
                                    show-arrow="true">
                                    \${getRecipientText()}
                                </cds-tooltip>
                                <cds-tooltip 
                                    if.bind="exchange.receiveSelectedCurrency.type === 'C'"
                                    reference-id="recipient-icon"
                                    placement="right"
                                    show-arrow="true">
                                    \${exchange.walletAddress}
                                </cds-tooltip>
                            </div>
                            <div class="grid-justify-self-end">
                                <div class="recipient d-flex" if.bind="exchange.receiveSelectedCurrency.type === 'F' && exchange.receivingPaymentMethod.paymentMethod.imagePath">
                                    <img
                                        alt="Recipient"
                                        src="\${baseAwsEndpoint}\${exchange.receivingPaymentMethod.paymentMethod.imagePath}"
                                        loading="lazy"
                                    >
                                    \${exchange.receivingPaymentMethod.paymentMethod.name}
                                </div>
                                <div class="tooltip-container" if.bind="exchange.receiveSelectedCurrency.type === 'C'">
                                    \${shortWalletAddress}
                                    <div class="tooltip-detail text-small">
                                        <span class="caption">\${exchange.walletAddress}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="purchase-flow-spacing">
                        <cx-timer
                            time-limit.bind="15"
                            auto-restart.bind="autoRestart"
                            time-left.two-way="exchange.timeLeft"
                            text="The live mid-market rates will update every 15 seconds until you checkout."
                            element.ref="timer"
                        ></cx-timer>
                    </div>
                </template>
            </div>
            <!-- Cash in Mail, Cash in Person and PayPal - Delivery type options  -->
            <cx-delivery-selector exchange.bind="exchange" selected-delivery.two-way="selectedDelivery" delivery-methods.bind="deliveryMethods" if.bind="deliveryOptionsValidate && deliveryMethods"></cx-delivery-selector>
            <!-- Payment Section -->
            <div class="box purchase-flow-spacing">
                <!--Back Button  -->
                <div class="d-flex back-button">
                    <div class="back text-regular d-flex align-items-center" click.delegate="backToPreviousStep()">
                        <span class="material-icons me-1">chevron_left</span>
                        \${'CX_BACK' | siteStrings}
                    </div>
                </div>
                <div class="d-grid d-sm-flex justify-content-between payment-top border-0 h-auto pb-4">
                    <div class="text-large-plus mb-3 mb-sm-0" if.bind="!user">
                        <img src="${___HTML_LOADER_IMPORT_1___}" alt="User" loading="lazy">
                        \${'CX_CHECKOUT' | siteStrings}
                    </div>
                    <div class="text-large-plus d-flex align-items-center mb-3 mb-sm-0" if.bind="user">
                        <img src="${___HTML_LOADER_IMPORT_2___}" class="caption-icon" alt="User" loading="lazy">
                        \${'CX_PAYMENT_DETAILS' | siteStrings}
                    </div>
                    <div class="grid-justify-self-end \${isCryptoPayValid || trackingNumberValid ? 'payment-crypto-grid' : ''}">
                        <div class="d-flex flex-column flex-sm-row align-items-center">
                            <cx-payment-method-selector
                                class="payment-component mb-3 mb-sm-0 \${isCryptoPayValid || trackingNumberValid ? 'me-2' : ''}"
                                element.ref="myPaymentSelector"
                                selected-payment-method.two-way="exchange.selectedPaymentMethod"
                                payment-methods.two-way="paymentMethods"
                                show-crypto-payment-method.bind="exchange.giveSelectedCurrency.type === 'C'"
                                given-currency-selected.bind="exchange.giveSelectedCurrency"
                                receiving-currency-selected.bind="exchange.receiveSelectedCurrency"
                                usd-rates.bind="usdRates"
                                loading.bind="loading"
                                billing.from-view="billing"
                                card-type.from-view="cardType"
                                card-number.from-view="cardNumber"
                                cc-recently-saved.from-view="ccRecentlySaved"
                            ></cx-payment-method-selector>
                            <div class="d-flex justify-content-center loading-container" if.bind="stateSpinner === 'processing'">
                                <mdc-circular-progress
                                    size="50"
                                    stroke-width="4"
                                ></mdc-circular-progress>
                            </div>
                            <cx-button
                                if.bind="isCryptoPayValid"
                                class="w-100 \${true ? '' : 'pe-none'} \${stateSpinner !== 'processing' ? '' : 'd-none'}"
                                button-text.bind="'CX_PAY_NOW' | siteStrings"
                                button-style="w-100 purple-background font-medium text-regular \${true ? 'text-white' : ''} \${disabledPayButton ? 'primary-disabled' : ''}"
                                button-icon-style.bind="'text-regular'"
                                click.delegate="startOrder()"
                                button-disabled.bind="disabledPayButton"
                            ></cx-button>
                        </div>
                    </div>
                </div>
                <!-- This should by dynamic on which payment method is selected -->
                <div class="checkout-area">
                    <div if.bind="!user">
                        <cx-auth-purchase-flow class="d-block" logging-in.two-way="loggingIn"></cx-auth-purchase-flow>
                    </div>
                    <!-- Not sure if validation should be made so the email HAS to be verified -->
                    <div class="user-logged" if.bind="user">
                        <!-- Validation when payment method selected requires Phone Verification and user doesnt have phone confirmed.-->
                        <div class="phone-container" if.bind="amountRequiresPhoneVeriff && !user.phoneNumberConfirmed">
                            <cx-phone-input
                                element.ref="phoneInputRef"
                                verification-stage.two-way="verificationStage"
                                phone-input.two-way="phoneInput"
                                loading.two-way="loadingPhoneInput"
                                input-style="cx-form-control form-control input-password"
                                require-sms.bind="true"
                                flow-page.bind="true"
                                user.two-way="userPhone"
                            ></cx-phone-input>
                            <div class="row mt-2" show.bind="!userPhone.phoneNumberInReview && !user.phoneNumberConfirmed">
                                <div class="col-12 col-lg-8 text-small text-gray font-regular my-auto pe-lg-0">
                                        By continuing you agree to ChicksX Inc's
                                    <span class="link-styling"><a class="text-small text-purple" href="\${termsOfServiceRoute | languageRoute & async}" target="_blank">Terms of Service</a></span>
                                        and
                                    <span class="link-styling"><a class="text-small text-purple" href="\${privacyPolicyRoute | languageRoute & async}" target="_blank">Privacy Policy</a></span>.
                                </div>
                            </div>
                        </div>
                        <!-- Section: Cash In Person -->
                        <cx-cash-in-person
                            exchange.bind="exchange"
                            user.bind="user"
                            meetup-address-valid.two-way="meetupAddressValid"
                            location.two-way="location"
                            selected-delivery.two-way="selectedDelivery"
                            date.two-way="date"
                            time.two-way="time"
                            view-model.ref="cashInPersonRef"
                            loading.bind="loading"
                            show.bind="!(exchange.receiveSelectedCurrency.type !== 'C' && exchange.receivingPaymentMethod.paymentMethod.reference !== 'cash-in-person') && (!amountRequiresPhoneVeriff || user.phoneNumberConfirmed) && methodFlow === 'meetup'"
                        ></cx-cash-in-person>
                        <div class="wallet-address" show.bind="( amountRequiresPhoneVeriff ? user.phoneNumberConfirmed : true ) && exchange.receiveSelectedCurrency.type === 'C' && (methodFlow !== 'meetup' || meetupAddressValid)">
                            <cx-wallet-input
                                crypto-currency-selected.bind="exchange.receiveSelectedCurrency"
                                valid.two-way="isWalletAddressValid"
                                wallet-address.two-way="exchange.walletAddress"
                                extra-field.two-way="exchange.extraField"
                                view-model.ref="walletInput"
                            ></cx-wallet-input>
                        </div>
                        <!-- Physical Address extension -->
                        <cx-address-input 
                            address.two-way="address" 
                            countries.bind="countries"
                            country.two-way="country" 
                            states.bind="states"
                            state.two-way="state" 
                            city.two-way="city" 
                            zip.two-way="zip" 
                            selected-option.two-way="selectedOption"
                            show.bind="methodFlow === 'delivery' && ( amountRequiresPhoneVeriff ? user.phoneNumberConfirmed : true )"
                            view-model.ref="addressInputRef"
                            loading.bind="loading"
                        ></cx-address-input>
                        <cx-document-upload
                            user.bind="user"
                            show.bind="false"
                            view-model.ref="documentUploadRef"
                        ></cx-document-upload>
                        <cx-payment-information
                            exchange.bind="exchange"
                            show.bind="showStepNotes"
                            view-model.ref="paymentInformationRef"
                        ></cx-payment-information>
                        <!-- Section: Cash In Mail -->
                        <cx-cash-in-mail
                            user.bind="user"
                            exchange.bind="exchange"
                            tracking-number-valid.bind="trackingNumberValid"
                            files-list.bind="userDocumentList"
                            view-model.ref="cashInMailRef"
                            show.bind="isWalletAddressValid && methodFlow === 'tracking-number'"
                        ></cx-cash-in-mail>
                    </div>
                </div>
            </div>       
        </div>
    </section>
</template>
`;
// Exports
export default code;