import './security.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { validateTrigger, ValidationController } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { ToastService } from 'services/toast-service';
import { DialogContent, DialogContentType } from 'resources/elements/cx-confirm-dialog/dialog-content';
import { CxConfirmDialog } from 'resources/elements/cx-confirm-dialog/cx-confirm-dialog';
import { User } from 'services/models/user/user';
import { UserUpdatedEvent } from 'resources/constants';
import { IUserUpdatedEvent } from 'types/events';
import { Helper } from 'resources/helpers/helper';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';


@autoinject()
export class Security {
    constructor(
        private helper: Helper,
        private router: Router,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private validator: ValidationController,
        private toastService: ToastService,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.validator = validator;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    pageLoading: boolean = true;
    user: User;
    userSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    dialogModule: CxConfirmDialog;
    options = [
        {
            route: 'reset-password',
            title: 'Reset password',
            titleKey: 'CX_RESET_PASSWORD',
            description: 'Change your sign in password',
            descriptionKey: 'CX_RESET_PASSWORD_DESCRIPTION',
            icon: 'admin_panel_settings',
            isOutlined: false,
        },
        {
            route: 'two-fa-settings',
            title: '2FA settings',
            titleKey: 'CX_2FA_SETTINGS',
            description: 'Create or reset your 2FA token',
            descriptionKey: 'CX_2FA_SETTINGS_DESCRIPTION',
            icon: 'phonelink_lock',
            isOutlined: false,
        },
        {
            route: 'withdraw',
            title: 'Withdraw password',
            titleKey: 'CX_WITHDRAW_PASSWORD',
            description: 'Create or reset your password to withdraw balance',
            descriptionKey: 'CX_WITHDRAW_PASSWORD_DESCRIPTION',
            icon: 'shield',
            isOutlined: true,
        },
        {
            route: 'delete-account',
            title: 'Delete account',
            titleKey: 'CX_DELETE_ACCOUNT',
            description: 'Delete your Chicks Group account',
            descriptionKey: 'CX_DELETE_ACCOUNT_DESCRIPTION',
            icon: 'delete',
            isOutlined: true,
        }
    ];

    dialogContent: DialogContent[];
    tablet: number = 768;
    initPromise: Promise<void | User>;

    async activate() {
        this.pageLoading = true;
        this.initPromise = this.sessionService.refreshProfile()
            .then(user => {
                this.user = user;
                if (!this.user) {
                    this.router.navigateToRoute('home');
                    return;
                }
            });

        this.dialogContent = [
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_1', 'Are you sure you want to delete your account?'), 1, DialogContentType.Title),
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_2', 'We hope to see you soon!'), 2, DialogContentType.Title),
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_3', 'Deleting your account is permanent and will remove all information such as messages, avatars, saved cards, balance, and profile settings. We recommend that you withdraw any balance before continuing. To buy, sell or swap in the future you would need to create a new account. Are you sure you want to delete your account?')),
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_4', 'Please enter your password to delete your account. Deleting your account is permanent and will remove all information such as messages, avatars, saved cards, balance, and profile settings. We recommend that you withdraw any balance before continuing. To buy, sell or swap in the future you would need to create a new account.'), 2),
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_5', 'Deleting your account is permanent and will remove all contact, including messages, avatars, and profile settings. Make sure to withdraw your balance before. Are you sure you want to delete your account?'), 1, DialogContentType.Content, this.tablet),
            new DialogContent(this.siteStringsValueConverter.toView('CX_SECURITY_DIALOG_6', 'You account has Two Factor Authentication activated, please provide your code to conclude with the account deletion.'), 3)
        ];
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('security');
            await this.initPromise;

            if (this.user) {
                const [emailInReview, idVerification, addressVerification, selfieVerification] = await Promise.all([
                    this.sessionService.getEmailInReview(),
                    this.sessionService.getIdVerificationInReview(this.user.id),
                    this.sessionService.getAddressVerificationInReview(this.user.id),
                    this.sessionService.getSelfieVerificationInReview(this.user.id)
                ]);

                this.user.emailInReview = emailInReview;
                this.user.idVerificationInReview = idVerification;
                this.user.addressVerificationInReview = addressVerification;
                this.user.selfieVerificationInReview = selfieVerification;
            }

            this.handleEventSubscriptions();
        } finally {
            this.helper.validateLoading('security');
        }
    }

    detached() {
        this.userSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe(UserUpdatedEvent, (payload: IUserUpdatedEvent) => {
            this.user = payload.user;
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    openModal() {
        this.dialogModule?.dialog?.open();
    }

    redirectTo = (route: string) => {
        if (route !== 'delete-account') this.router.navigate(`security/${route}`);
        else {
            this.openModal();
        }
    };
}
