import { ToastType } from './models/toast';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class ToastService {
    constructor(private eventAggregator: EventAggregator) {
    }

    defaultTitles = {
        [ToastType.SUCCESS]: 'Success'
    };

    async showToast(title: string, message: string, toastType: string, url?: string, urlName?: string, clickHereActive: boolean = false, actionLabel?: string, screenTime: number = 2500, imgIcon?: string) {
        title ??= this.defaultTitles[toastType] ?? toastType.toPascal();
        this.eventAggregator.publish('toast', { title, message, toastType, imgIcon, forceOut: false, screenTime, url, urlName, clickHereActive, actionLabel });
    }
}
