// Module
var code = `<template>
    <div id="cx-form-get-started" class="position-relative amount-field-activated">

        <form class="get-started-form">
         <!-- Desktop -->

            <div class="d-none d-lg-flex justify-content-between align-items-end" if.bind="isDesktop">
                <div id="id-exchange-selector" class="exchange-type-selector" skeleton-loading="is-loading.bind:isLoading; height:48px;">
                    <div class="button-animated d-none d-lg-block" ref="exchangeBtnAnimated"></div>
                    <div class="button-group row no-gutters">
                      <button
                        repeat.for="exchangeOption of exchangeOptions"
                        id="\${exchangeOption.value}"
                        type="button"
                        disabled.bind="exchangeTypeClicked"
                        class="col-4 \${exchangeSelectorIsActive(exchangeOption.value, exchangeType)} \${!animationRunning ? 'button-hover' : ''}"
                        click.delegate="changeExchangeType(exchangeOption.value)"
                      >
                        \${exchangeOption.label}
                      </button>
                    </div>
                </div>
                <div id="id-exchange-sub-selector" class="exchange-sub-type-selector" if.bind="showExchangeSubOptions && !isLoading">
                    <cds-button
                        repeat.for="exchangeOption of exchangeSubOptions"
                        type="button"
                        variant="filled-tonal"
                        class="btn-subselector font-small \${exchangeOption.value === exchangeType && giveCurrencyOptions.length ? 'active' : ''}"
                        id="sub-\${exchangeOption.value}"
                        click.trigger="changeExchangeType(exchangeOption.value)"
                    >
                        \${exchangeOption.label}
                    </cds-button>
                </div>
            </div>

            <div class="exchange-grid-container d-none d-lg-block" if.bind="isDesktop">
                <div class="exchange-grid">
                    <div class="you-give">
                        <cx-amount-currency-selector
                            tooltip-ref-id="give-amount-currency-selector"
                            skeleton-loading="is-loading.bind:isLoading"
                            currency-list.bind="giveCurrencyOptions"
                            currency-selected.two-way="giveSelectedCurrency"
                            exchange-currency-selected.bind="receiveSelectedCurrency"
                            intent="give"
                            active-currencies.bind="activeCurrencies"
                            amount.two-way="amountGifted"
                            default-amount.bind="amountGiftedDefault"
                            selected-payment-method.bind="selectedPaymentMethod"
                            max-amount.bind="maximumAmount"
                            page-loading.bind="isLoading"
                        ></cx-amount-currency-selector>
                    </div>
                    <div class="invert-currency cursor-pointer"
                        skeleton-loading="is-loading.bind:isLoading; width:40px; height:40px;"
                        click.trigger="invertCurrencies() & debounce:400">
                        <cds-icon
                            class="icon"
                            alt="exchange icon"
                            icon="swap_horiz">
                        </cds-icon>
                    </div>
                    <div class="you-receive">
                        <cx-amount-currency-selector
                            tooltip-ref-id="receive-amount-currency-selector"
                            skeleton-loading="is-loading.bind:isLoading"
                            currency-list.bind="receiveCurrencyOptions"
                            currency-selected.two-way="receiveSelectedCurrency"
                            exchange-currency-selected.bind="giveSelectedCurrency"
                            intent="receive"
                            active-currencies.bind="activeCurrencies"
                            amount.two-way="amountReceived"
                            default-amount.bind="amountReceivedDefault"
                            selected-payment-method.bind="selectedPaymentMethod"
                            max-amount.bind="maximumReceiveAmount"
                            page-loading.bind="isLoading"
                        ></cx-amount-currency-selector>
                    </div>
                    <cx-amount-payment-selector
                        if.bind="isDesktop"
                        skeleton-loading="is-loading.bind:isLoading;"
                        ref="cxAmountPaymentSelectorEl"
                        class="flex-grow-1"
                        exchange-type.bind="exchangeType"
                        give-selected-currency.bind="giveSelectedCurrency"
                        receive-selected-currency.bind="receiveSelectedCurrency"
                        selected-payment-method.two-way="selectedPaymentMethod"
                    ></cx-amount-payment-selector>
                    <cx-button
                        skeleton-loading="is-loading.bind:isLoading"
                        class="exchange text-nowrap"
                        button-text.bind="'CX_CHECKOUT_NOW' | siteStrings"
                        button-style="w-100 h-100 font-medium text-medium text-white blue-gradient-second-background"
                        button-icon-style.bind="'text-regular'"
                        click.delegate="tryAddExchange()"
                    ></cx-button>
                </div>
                <cx-coupon-input
                    if.bind="isDesktop"
                    skeleton-loading="is-loading.bind:isLoading"
                    view-model.ref="couponInputViewModel"
                    text="\${'CX_ENTER_COUPON_CODE' | siteStrings}"
                    valid-coupon.two-way="validCoupon"
                    applied-discount.two-way="appliedDiscount"
                    you-give-amount.bind="amountGifted"
                    you-receive-amount.bind="amountReceived"
                    you-receive-currency.bind="receiveSelectedCurrency"
                    coupon-open.two-way="couponOpen"
                    from-trade.bind="true"
                    rate.bind="currentRate"
                ></cx-coupon-input>
            </div>

            <!-- Mobile and Tablet -->

            <div class="exchange-container d-lg-none" if.bind="!isDesktop">
                <div id="id-exchange-selector" class="exchange-type-selector \${isLoading ? 'border-0' : ''}">
                    <cx-select
                        skeleton-loading="is-loading.bind:isLoading"
                        options.bind="exchangeOptionsForTabletAndMobile"
                        selected-option-value.bind="exchangeType"
                        label="Operation"
                        icon="shopping_cart"
                        id="exchange-options-select"
                        option-selected.call="changeExchangeType(\$event)"
                    >
                    </cx-select>
                    <div class="exchange-input-container">
                        <cx-amount-currency-selector
                            show.bind="currentIntent === 'give'"
                            tooltip-ref-id="give-amount-currency-selector-mobile"
                            page-loading.bind="isLoading"
                            currency-list.bind="giveCurrencyOptions"
                            currency-selected.two-way="giveSelectedCurrency"
                            exchange-currency-selected.bind="receiveSelectedCurrency"
                            intent="give"
                            active-currencies.bind="activeCurrencies"
                            amount.two-way="amountGifted"
                            default-amount.bind="amountGiftedDefault"
                            selected-payment-method.bind="selectedPaymentMethod"
                            max-amount.bind="maximumAmount"
                        ></cx-amount-currency-selector>
                        <cx-amount-currency-selector
                            show.bind="currentIntent === 'receive'"
                            tooltip-ref-id="receive-amount-currency-selector-mobile"
                            page-loading.bind="isLoading"
                            currency-list.bind="receiveCurrencyOptions"
                            currency-selected.two-way="receiveSelectedCurrency"
                            exchange-currency-selected.bind="giveSelectedCurrency"
                            intent="receive"
                            active-currencies.bind="activeCurrencies"
                            amount.two-way="amountReceived"
                            default-amount.bind="amountReceivedDefault"
                            selected-payment-method.bind="selectedPaymentMethod"
                            applied-discount.bind="appliedDiscount"
                            max-amount.bind="maximumReceiveAmount"
                        ></cx-amount-currency-selector>
                        <div class="invert-currency-mobile"
                            click.delegate="changeCurrentIntent() & debounce: 400"
                            skeleton-loading="is-loading.bind:isLoading;height:21px;width:150px;">
                            <cds-icon
                                class="exchange-icon-mobile"
                                alt="exchange icon"
                                icon="swap_horiz"
                                color="#310FA2">
                            </cds-icon>
                            <div if.bind="currentIntent === 'give'">
                                \${'CX_YOU_RECEIVE' | siteStrings} \${amountReceived} \${receiveSelectedCurrency.code}
                            </div>
                            <div else>
                                \${'CX_YOU_GIVE' | siteStrings} \${amountGifted} \${giveSelectedCurrency.code}
                            </div>
                        </div>
                        <cx-coupon-input
                            if.bind="!isDesktop"
                            page-loading.bind="isLoading"
                            view-model.ref="couponInputViewModel"
                            text="\${'CX_ENTER_COUPON_CODE' | siteStrings}"
                            coupon.two-way="coupon"
                            valid-coupon.two-way="validCoupon"
                            state.two-way="couponInputState"
                            applied-discount.two-way="appliedDiscount"
                            you-give-amount.bind="amountGifted"
                            you-receive-amount.bind="amountReceived"
                            you-receive-currency.bind="receiveSelectedCurrency"
                            coupon-open.two-way="couponOpen"
                            from-trade.bind="true"
                            rate.bind="currentRate"
                        ></cx-coupon-input>
                    </div>
                </div>
                <div class="currency-selector-container">
                    <div class="you-receive">
                        <cx-currency-selector
                            skeleton-loading="is-loading.bind:isLoading"
                            page-loading.bind="isLoading"
                            currency-list.bind="receiveCurrencyOptions"
                            currency-selected.two-way="receiveSelectedCurrency"
                            exchange-currency-selected.two-way="giveSelectedCurrency"
                            intent="receive"
                            label="\${'CX_YOU_RECEIVE' | siteStrings}"
                            active-currencies.bind="activeCurrencies"
                        ></cx-currency-selector>
                    </div>
                    <div class="you-give">
                        <cx-currency-selector
                            skeleton-loading="is-loading.bind:isLoading"
                            page-loading.bind="isLoading"
                            currency-list.bind="giveCurrencyOptions"
                            currency-selected.two-way="giveSelectedCurrency"
                            exchange-currency-selected.two-way="receiveSelectedCurrency"
                            intent="give"
                            label="\${'CX_YOU_GIVE' | siteStrings}"
                            active-currencies.bind="activeCurrencies"
                        ></cx-currency-selector>
                    </div>
                    <cx-amount-payment-selector
                        if.bind="!isDesktop"
                        skeleton-loading="is-loading.bind:isLoading;"
                        ref="cxAmountPaymentSelectorEl"
                        exchange-type.bind="exchangeType"
                        give-selected-currency.bind="giveSelectedCurrency"
                        receive-selected-currency.bind="receiveSelectedCurrency"
                        selected-payment-method.two-way="selectedPaymentMethod"
                    ></cx-amount-payment-selector>
                </div>
                <div class="add-exchange-btn-fixed">
                    <cx-button
                        skeleton-loading="is-loading.bind:isLoading"
                        class="exchange text-nowrap"
                        button-text.bind="'CX_CHECKOUT_NOW' | siteStrings"
                        button-style="w-100 font-medium text-medium text-white blue-gradient-second-background"
                        button-icon-style.bind="'text-regular'"
                        click.delegate="tryAddExchange()"
                    ></cx-button>
                </div>
            </div>
        </form>
        <cx-timer
            class="d-none"
            mdc-tooltip="value.bind: timer;"
            mouseover.trigger="mouseOver('timer')"
            time-limit.bind="15"
            auto-restart.bind="autoRestart"
            time-left.two-way="timeLeft"
            element.ref="timer"
            class="text-normal"
        ></cx-timer>
    </div>
</template>
`;
// Exports
export default code;