// Module
var code = `<template>
    <div id="cx-currency-dropdown" ref="currencyDropdownComponent" mouseleave.trigger="closeContainerIfLeftOnSidesOrTop(\$event)">
        <button
            ref="mainButtonDropdown"
            class="d-flex flex-row position-relative main-dropdown-menu btn"
            type="button"
            mouseover.delegate="handleCurrencyNavigationContainer('open')"
            data-cy="currency-dropdown-button">
            <img if.bind="currentLanguage.imagePath" class="selected-flag" src.bind="currentLanguage.isDefault ? currentLanguage.imagePath : \`\${baseAwsEndpointLanguage}\${currentLanguage.imagePath}\`" alt="currentLanguage.name" width="30px" height="20px">
            <span else class="material-icons-outlined">image_not_supported</span>
            <span>\${currentLanguage.name} / \${savedCurrency.code}</span>
            <span class="material-icons arrow alt-customer-dropdown-icon-down font-arrow" aria-hidden="true">keyboard_arrow_down</span>
        </button>
        <div ref="currencyAccordion" class="main-currency-accordion position-absolute opacity-0 invisible" mouseleave.trigger="handleCurrencyNavigationContainer('close')">
            <cx-circular-progress show.bind="languageBarLoading" class="spin-loader mt-5"></cx-circular-progress>
            <div show.bind="!languageBarLoading">
                <h2 if.bind="languages.length" class="text-large-plus">\${'CX_LANGUAGE' | siteStrings}</h2>
                <cx-language-slider
                    show.bind="languages.length"
                    ref="languageSlider"
                    languages.bind="languages"
                    selected-language.two-way="currentLanguage"
                    language-bar-loading.two-way="languageBarLoading"
                ></cx-language-slider>
                <hr if.bind="languages.length">
                <h2 class="text-large-plus currency-title">\${'CX_CURRENCY' | siteStrings}</h2>
                <cx-lookup
                    class="select-background-outlined w-100"
                    selected-object.two-way="currentCurrency"
                    label="\${'CX_SELECT_CURRENCY' | siteStrings}"
                    dropdown-options.bind="currencyOptions"
                    purple-scrollbar="true"
                    scrollbar-dynamic-height="true"
                    always-show-label="true"
                    align-vertically="true"
                    lookup-name="currency"
                ></cx-lookup>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;