// Module
var code = `<template>
    <section id="privacy-policy" class="legal-page \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold text-purple \${pageLoading ? 'title-container-skeleton' : ''}">
            <h1 class="header-title text-start" skeleton-loading="is-loading.bind:pageLoading;">Privacy Policy</h1>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body legal-page-content \${pageLoading ? 'legal-page-skeleton' : ''}">
                <cx-page-content-area key.bind="'PRIVACY_POLICY_LAST_UPDATED'" class="text-end last-update" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INTRODUCTION_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INTRODUCTION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DEFINITIONS_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DEFINITIONS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_COLLECTION_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_COLLECTION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_HOW_WE_USE_YOU_INFORMATION_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_HOW_WE_USE_YOU_INFORMATION_CONTENT'" class="text-content margened-paragraph" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INSURANCE_LIFE_CANADA_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INSURANCE_LIFE_CANADA_CONTENT'" class="text-content margened-paragraph cookies" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_ANALYTICS_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_ANALYTICS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_BEHAVIORAL_REMARKETING_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_BEHAVIORAL_REMARKETING_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_SHARING_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_INFORMATION_SHARING_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_UNSUBSCRIBE_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_UNSUBSCRIBE_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_STORED_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_DATA_STORED_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_SECURITY_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_SECURITY_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_USER_DATA_RIGHTS_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_USER_DATA_RIGHTS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHILD_PROTECTION_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CHILD_PROTECTION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_RIGHT_ACCESS_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_RIGHT_ACCESS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_GDPR_REGULATIONS_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_GDPR_REGULATIONS_CONTENT'" class="text-content margened-paragraph" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CCPA_GUIDELINES_TITLE'" class="text-title font-bold" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'PRIVACY_POLICY_CCPA_GUIDELINES_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>`;
// Exports
export default code;