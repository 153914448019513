export interface Toast {
    title: string;
    message: string;
    toastType: string;
    imgIcon: string;
    forceOut: boolean;
    screenTime: number;
    urlName: string;
    actionLabel: string;
    onAction: () => void;
}

export enum ToastType {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    TOOLTIP = 'text-only',
}
