import './cx-chat-view.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { SupportTicketService } from 'services/support-ticket-service';
import { SignalrService } from 'services/signalr-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ImageService } from 'services/image-service';
import { SupportTicketMessage } from 'services/models/support-ticket/support-ticket';
import { ToastService } from 'services/toast-service';

@autoinject()
export class CxChatView {
    @bindable issue;
    @bindable fileList = [];
    @bindable ticketId;
    @bindable chatMessagesLoading;
    @bindable messagePosition;
    messages;
    issueInput;
    containerMessages;
    user;
    ticketData;
    connection;
    pageLoading = true;
    supportTicketAttachments = [];
    attachmentsUploading = false;
    responseTime;
    displayResponseTime = true;
    triggeredReplyToTicket = false;
    triggeredMarkTicketAsResolved = false;
    triggeredMarkTicketAsReopened = false;

    constructor(
        private sessionService: SessionService,
        private supportTicketService: SupportTicketService,
        private signalRService: SignalrService,
        private eventAggregator: EventAggregator,
        private imageService: ImageService,
        private toastService: ToastService
    ) {}

    async attached() {
        this.chatMessagesLoading = true;
        this.user = await this.sessionService.getProfile();
        this.containerMessages = document.getElementById('messages');
        this.containerMessages.scrollTop = this.containerMessages.scrollHeight;
        this.connection = await this.signalRService.getSignalRConnection();
        this.ticketData = await this.supportTicketService.getTicket(this.ticketId);

        this.messages = this.ticketData?.messages;

        if (!this.messages) {
            this.chatMessagesLoading = false;
        }

        this.messages?.forEach(element => {
            if (element.userId !== this.user.id) {
                this.displayResponseTime = false;
            }
        });

        if (this.displayResponseTime) {
            this.responseTime = await this.supportTicketService.getAvgResponseTime();
        }

        if (this.ticketId) {
            await this.connection?.invoke('SubscribeSupportTicketChat', this.ticketId);
        }

        this.connection.on('ReceiveMessage', async (result) => {
            this.messages.push(result);

            setTimeout(() => {
                this.containerMessages.scrollTop = this.containerMessages.scrollHeight;
            }, 10);
        });

        this.eventAggregator.subscribe('support-issue-input-keyup', payload => {
            if (payload.key === 'Enter') {
                this.replyToTicket();
            }
        });
        this.pageLoading = false;
        setTimeout(() => {
            this.containerMessages.scrollTop = this.containerMessages.scrollHeight;
        }, 10);
    }

    detached() {
        if (this.ticketId) {
            this.connection?.invoke('UnsubscribeSupportTicketChat', this.ticketId);
        }
        this.connection?.off('ReceiveMessage');
    }

    async showIntercomMessage() {
        if (this.ticketData.status !== 'closed') {
            if (window.Intercom) {
                if (!this.issue) {
                    window.Intercom('showNewMessage', 'Hello, I need help');
                } else {
                    if (this.ticketData.title !== 'Choose a Title') {
                        window.Intercom('showNewMessage', `${this.ticketData.title}: ${this.issue}`);
                    } else {
                        window.Intercom('showNewMessage', this.issue);
                    }
                }
            }
        }
    }

    async replyToTicket() {
        if (this.ticketData.status !== 'closed') {
            if (!this.issue && this.fileList.length <= 0) {
                return this.toastService.showToast('Info', !this.issue ? 'Please enter a message to reply to a ticket.' : 'Please upload atleast one file to reply to a ticket.', 'info');
            }

            if (!this.triggeredReplyToTicket) {
                this.triggeredReplyToTicket = true;
                try {
                    this.attachmentsUploading = true;

                    const message: SupportTicketMessage = {
                        message: this.issue,
                        userId: this.user.id,
                        attachments: []
                    };

                    this.issue = '';
                    this.issueInput?.au.controller.viewModel.cleanFileList();
                    message.attachments = await this.uploadAttachments(this.fileList);
                    this.fileList = [];
                    await this.sessionService.destroyDraftMessage();
                    await this.sessionService.destroyDraftTitle();
                    await this.sessionService.destroyDraftCreatedDate();
                    await this.sessionService.destroyDraftUpdatedDate();
                    await this.supportTicketService.chat(this.ticketId, message);
                    this.attachmentsUploading = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    setTimeout(() => this.triggeredReplyToTicket = false, 1000);
                }
            }
        }
    }

    async uploadAttachments(fileList) {
        const uploadedFiles = [];
        for (const file of fileList) {
            const formData = this.imageService.buildFormData([file]);
            const response = await this.imageService.postDocumentSupportTicket(formData, this.ticketId);
            response.supportTicket = this.ticketData;
            uploadedFiles.push(response);
        }
        return uploadedFiles;
    }

    async changeTicketStatus(ticketStatus) {
        if (ticketStatus === 'closed' && this.ticketData.status !== 'closed') {
            if (!this.triggeredMarkTicketAsResolved) {
                this.triggeredMarkTicketAsResolved = true;
                try {
                    const response = await this.supportTicketService.changeTicketStatus(this.ticketId, ticketStatus);
                    if (response) {
                        this.ticketData.status = 'closed';
                        this.toastService.showToast(undefined, 'Ticket has been marked as resolved.', 'success');
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    this.triggeredMarkTicketAsResolved = false;
                }
            }
        } else if (ticketStatus === 'active' && this.ticketData.status !== 'active') {
            if (!this.triggeredMarkTicketAsReopened) {
                this.triggeredMarkTicketAsReopened = true;
                try {
                    const response = await this.supportTicketService.changeTicketStatus(this.ticketId, ticketStatus);
                    if (response) {
                        this.ticketData.status = 'active';
                        this.toastService.showToast(undefined, 'Ticket has been reopened.', 'success');
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    this.triggeredMarkTicketAsReopened = false;
                }
            }
        }
    }
}
