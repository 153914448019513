import { baseUrl, chicksGroupBaseUrl, clientId } from 'environment';
import { inject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { SessionService } from 'services/session-service';
import { parseAuthQueryParams, redirectToSSO } from '@chicksgroup/helpers';

@inject(
    ToastService,
    SessionService
)
export class AuthenticationExtension {
    protected toastService: ToastService;
    protected sessionService: SessionService;

    constructor(
        toastService: ToastService,
        sessionService: SessionService
    ) {
        this.sessionService = sessionService;
        this.toastService = toastService;
    }

    private async _doLocalLogin(route: string) {
        const email = prompt('email');
        const password = prompt('password');

        this.sessionService.login({ email, password, skipChecks: true, token: '' }).then(loginResult => {
            this.sessionService.validateAuthorizationCode(loginResult.authorizationCode, clientId()).then(codeResponse => {
                if (!codeResponse?.token) {
                    return;
                }

                this.sessionService.saveToken(codeResponse.token);
                location.reload();
            }).catch(err => {
                console.error(err);
                this.handleAuthRedirection(route);
            });
        }).catch(err => {
            console.error(err);
            this.handleAuthRedirection(route);
        });
    }

    async handleSSO() {
        const query = parseAuthQueryParams();
        let handledToken = false;

        try {
            const storedNonce = window.localStorage.getItem('nonce');

            const token = query.get('token');
            const nonce = query.get('nonce');

            if (!token || (storedNonce && !nonce)) {
                return;
            }

            const storedVerifier = window.localStorage.getItem('codeVerifier');
            handledToken = true;

            if (storedNonce !== nonce) {
                void this.toastService.showToast('Error', 'Failed to validate sign in request. Please try again.', 'error');
                return;
            }

            const response = await this.sessionService.validateAuthorizationCode(token, clientId(), storedVerifier);

            if (!response?.token) {
                return;
            }

            this.sessionService.saveToken(response.token);
        } catch (e) {
            console.log(e);
        } finally {
            localStorage.removeItem('nonce');
            localStorage.removeItem('codeVerifier');

            const referrerCode = query['referrerCode'] as number;

            if (referrerCode) {
                this.sessionService.saveReferrerCode(referrerCode);
            }

            if (handledToken) {
                window.location.href = window.location.href.split('#')[0];
            }
        }
    }

    async handleAuthRedirection(route: string = null, referralLink: string = null, authenticationRoute: string = null, refLink: string = null) {

        if (baseUrl().includes('localhost')) {
            const skipChecks = confirm('skip sso?');

            if (skipChecks) {
                await this._doLocalLogin(route);
                return;
            }
        }

        void redirectToSSO({
            authenticationRoute,
            route,
            refLink,
            referralLink,
            ssoUrl: chicksGroupBaseUrl(),
            clientId: clientId()
        });
    }
}
