import './faq.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { FaqQuestion, FaqSection } from 'services/models/faq';
import { FaqSectionService } from 'services/faq-section-service';
import { ToastService } from 'services/toast-service';
import { bindable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Helper } from 'resources/helpers/helper';
import { isStaticAsset } from 'resources/constants';
import { getAWSBucketEndpoint } from 'environment';
import { SimplebarOverride } from 'resources/simplebar_override';
import { SessionService } from 'services/session-service';

interface ExtendedFaqQuestion extends FaqQuestion {
    isVerificationQuestion?: boolean;
}

interface ExtendedFaqSection extends FaqSection {
    questions: ExtendedFaqQuestion[];
}

@autoinject()
export class Faq {
    @bindable isVerificationQuestion = false;
    private sections: ExtendedFaqSection[];
    @bindable({ callback:'searchChanged' }) private search: string;
    faqDialog;
    changeSearchWatch: NodeJS.Timeout;
    filteredSections;
    toastSearchSent: boolean;
    timeouts: NodeJS.Timeout[];
    pages: PageByWebsite[];
    constRepeatFor = 3;
    sizeSubscriber: Subscription;
    preSelectedCard:number;
    showDrawerContent = false;
    noResults = false;
    baseAwsEndpoint = getAWSBucketEndpoint('icons');
    prevSearch = '';
    currentLeadingIcon = 'search';
    isUpperbarOpen = false;
    noResultsDescription = 'There are no entries to show here. \n \n Please consider changing search keywords, or updating filter options.';
    isDrawerOpen = false;
    drawerContentSimplebar;
    width;
    phone: number;
    sizeChanged: Subscription;
    hasResetedDestkop: boolean;
    shouldRenderCollapse = false;
    collapseGroupMultiple = false;
    shouldQuestionBeOpen = true;
    homePageRoute;
    pageLoading: boolean = true;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private faqSectionService: FaqSectionService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private taskQueue: TaskQueue,
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
        this.helper = helper;
        this.helper.getResolutions(this);
    }

    getIconSrc(src) {
        return isStaticAsset().test(src) ? `/${src}` : `${this.baseAwsEndpoint}${src}`;
    }

    resetQuestionsOpen() {
        this.shouldQuestionBeOpen = true;
        setTimeout(() => {
            this.shouldQuestionBeOpen = false;
        }, 5);
    }

    handleCollapseAnimation(evt, section) {
        this.resetQuestionsOpen();
        if (section.questions.length > 8) {
            return evt.target.classList.add('extra-duration-animation');
        }
        if (section.questions.length <= 3) {
            return evt.target.classList.add('reduced-duration-animation');
        }
    }

    async activate() {
        this.sections = [];
        this.pages = this.sessionService.getPages();
        this.filteredSections = [];

        Promise.all([
            this.faqSectionService.getByWebsite()
        ]).then(([sections]) => {
            this.sections = sections.map(section => ({
                ...section,
                openByDefault: false,
                questions: section.questions.map(question => ({
                    ...question,
                    openByDefault:false
                }))
            }));
            this.filteredSections = this.sections;
            this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';

            if (this.sections.length > 0) {
                this.sections.sort((a, b) => {
                    return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position;
                });
            }

            this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Faq')?.id);
        });
    }

    attached() {
        try {
            this.helper.addLoadingComponent('faq');
            this.handleEventSubscriptions();
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            setTimeout(() => {
                this.shouldRenderCollapse = true;
            }); // Temporary solution to <cds-collapse-group> not getting cds-collapse childs
        }

        finally {
            this.helper.validateLoading('faq');
        }
    }

    detached() {
        this.sizeSubscriber?.dispose();
    }

    compareQuestionsMatch = (a, b) => Number(b !== null) - Number(a !== null) || a - b;

    compareOpenDefault = (a, b) => Number(b.openByDefault) - Number(a.openByDefault);

    keyUpHandler(ev) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchBySection(this.search);
            return;
        } else {
            this.changeSearchWatch = setTimeout(() => {
                if (this.search) {
                    this.searchBySection(this.search);

                } else {
                    this.filteredSections = this.sections;
                }
            }, 1000);
        }
        return true;
    }

    async searchBySection(value: string) {
        const valueFixed = value?.trim()?.toLowerCase();
        // Check if prev search value is the same
        if (this.prevSearch === valueFixed) return;

        this.prevSearch = valueFixed;
        this.resetQuestionsOpen();
        const sections = JSON.parse(JSON.stringify(this.sections.slice()));
        const filter = sections.map((section) => {
            const filteredQuestions = section.questions.filter(({ question }) => question?.toLowerCase()?.trim()?.includes(valueFixed) || section.title?.toLowerCase()?.trim()?.includes(valueFixed));

            if (filteredQuestions.length > 0) {
                section.questions = filteredQuestions;
                return section;
            }
            return;
        })
            .filter(s => s)
            .map((section, sectionIndex) => ({
                ...section,
                openByDefault: sectionIndex === 0,
                instant: true,
                questions: section.questions.map((q, questionIndex) => ({
                    ...q,
                    openByDefault: questionIndex === 0 && sectionIndex === 0
                }))
            }));

        if (!filter.length) {
            this.noResults = true;
            return;
        }

        this.noResults = false;
        const matchIsOpenByDefault = filter.find(s => s.openByDefault);
        const matchQuestionsIsOpenByDefault = matchIsOpenByDefault && matchIsOpenByDefault.questions.find(q => q.openByDefault);

        const current = this.filteredSections.find(s => s.openByDefault);
        const currentQuestions = current && current.questions.find(q => q.openByDefault);

        // Check if the matching question is already opened
        if (matchIsOpenByDefault?.id === current?.id && matchQuestionsIsOpenByDefault?.id === currentQuestions?.id && filter.length === this.filteredSections.length) return;
        this.filteredSections = filter;
    }

    resetFiltered() {
        this.filteredSections = this.sections.map(section => ({
            ...section,
            openByDefault: false,
            instant: false
        }));
    }

    handleOpenByDefault(val, id) {
        if (this.filteredSections.filter(s => s.id === id)[0].openByDefault) return;
        if (val) {
            this.filteredSections = this.filteredSections.map(section => ({
                ...section,
                openByDefault: false
            }));
            return;
        }
    }

    handleCloseUpperNavbar() {
        this.isUpperbarOpen = false;
        this.showDrawerContent = false;
        this.resetFiltered();
        this.clearSearch();
    }

    handleOpenUpperNavbar() {
        this.showDrawerContent = true;
        this.isUpperbarOpen = true;
    }

    getFirstColumnSliced(arr) {
        if (arr.length % 2 === 0) {
            return arr.slice(0, (arr.length / 2));
        }
        return arr.slice(0, (arr.length / 2) + 1);
    }

    getSecondColumnSliced(arr) {
        if (arr.length % 2 === 0) {
            return arr.slice((arr.length / 2));
        }
        return arr.slice((arr.length / 2) + 1);
    }

    clearSearch() {
        this.search = '';
        this.prevSearch = '';
        this.noResults = false;
        this.filteredSections = this.sections;
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            if (this.width >= this.phone) {
                !this.hasResetedDestkop && this.showDrawerContent && this.resetFiltered();
                this.showDrawerContent = false;
                this.hasResetedDestkop = true;
            } else {
                this.reinstanceScrollbar();
                this.hasResetedDestkop = false;
                if (!this.isDrawerOpen) return;
                this.showDrawerContent = true;
            }
        });

        this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    handleLeadingIcon() {
        if (!this.search) {
            this.currentLeadingIcon = 'search';
            return;
        }
        this.currentLeadingIcon = 'arrow_left_alt';
    }

    reinstanceScrollbar() {
        const instance = SimplebarOverride.instances.get(this.drawerContentSimplebar);
        instance?.unMount?.();
        new SimplebarOverride(this.drawerContentSimplebar);
    }

    handleDrawerToggle(e) {
        this.isDrawerOpen = Boolean(e?.detail?.value);
        this.showDrawerContent = e?.detail?.value;
        this.resetFiltered();
        this.helper.handleMainPageScroll(this.isDrawerOpen);
    }

    handleSearchChange(event) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.search = event.target.value;

        this.helper.debounce(this, 'isSearching', 'searchingTimeout', 450, () => {
            if (this.search) {
                this.searchBySection(this.search);
                return;
            }
            setTimeout(() => {
                this.resetCollapseGroup();
                this.resetFiltered();
                this.clearSearch();
            }, 200);
        });
    }

    resetCollapseGroup() {
        // Quick Fix For collapseGroup not working when clearing search
        this.collapseGroupMultiple = true;
        setTimeout(() => {
            this.collapseGroupMultiple = false;
        });
    }

    showTable() {
        this.faqDialog.dialog.open();
    }
}
