// Module
var code = `<template>
    <div id="cx-seo-dropdown" skeleton-loading="is-loading.bind:isLoading">
        <cds-collapse class="seo-dropdown" closed-icon="arrow_drop_down" opened-icon="" is-open.two-way="isOpen">
            <span if.bind="showHeaderIcon" slot="collapse-header-icon" class="collapse-header__icon material-icons-outlined">{ headerIcon }</span>
            <p slot="collapse-header" class="collapse-header__title">\${ headerTitle }</p>
            <div ref="collapseContentEl" class="collapse-content closed">
                <slot></slot>
            </div>
        </cds-collapse>
    </div>
</template>
`;
// Exports
export default code;