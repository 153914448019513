// Module
var code = `<template>
    <div id="cx-language-currency-selector">
        <cds-menu
            menu-offset-y="4"
            menu-corner="start-end"
            anchor-corner="end-end"
            typeahead-controller.bind="typeaheadController"
            menuopening.trigger="openMenu = true"
            menuclosing.trigger="openMenu = false">
            <a slot="menu-anchor" class="menu-anchor">
                <cds-icon class="lng-icon" icon="language"></cds-icon>
                <span>\${selectedLanguage.name} / \${selectedCurrency.code}</span>
                <cds-icon class="menu-icon \${openMenu ? 'active' : 'inactive'}" icon="arrow_drop_down"></cds-icon>
            </a>
            <div class="option-box" keep-open="true" slot="menu-options"> 
                <cds-autocomplete
                    ref="languageAutocompleteRef"
                    class="language-menu"
                    dropdown-y-offset="18"
                    dropdown-x-offset="-16"
                    label="\${'CX_LANGUAGE' | siteStrings}" 
                    items.bind="languageAutocompleteItems"
                    data-cy="autocomplete" 
                    input-indicator="background"
                    menu-corner="end-start" 
                    menu-anchor-corner="start-start"
                    trailing-icon="arrow_drop_down"
                    no-vertical-flip
                    leading-icon="language"
                    show-divider.bind="showDivider"
                    search.trigger="search()"
                    selected.trigger="languageSelected(\$event)"
                    closing.trigger="closingLanguageAutocomplete()">
                        <cx-no-results slot="no-results-content"
                            message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                        ></cx-no-results>
                </cds-autocomplete>
                <cds-autocomplete 
                    ref="currencyAutocompleteRef"
                    dropdown-y-offset="18"
                    dropdown-x-offset="-16"
                    label="\${'CX_CURRENCY' | siteStrings}" 
                    items.bind="currencyAutocompleteItems"
                    data-cy="autocomplete" 
                    input-indicator="background"
                    menu-corner="end-end" 
                    menu-anchor-corner="start-end"
                    trailing-icon="arrow_drop_down"
                    leading-icon="monetization_on"
                    no-vertical-flip
                    show-divider.bind="showDivider"
                    search.trigger="search()"
                    selected.trigger="currencySelected(\$event)"
                    closing.trigger="closingCurrencyAutocomplete()">
                        <cx-no-results slot="no-results-content"
                            message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                        ></cx-no-results>
                </cds-autocomplete>
            </div>
        </cds-menu>
    </div>
</template>
`;
// Exports
export default code;