import './cx-seo-dropdown.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';

@autoinject()
export class CxSeoDropdown {
    @bindable isLoading: boolean = true;
    @bindable headerTitle: string = 'View More';
    @bindable showHeaderIcon: boolean = false;
    @bindable headerIcon: string = 'info';
    @bindable @observable isOpen: boolean = false;

    collapseContentEl: HTMLElement;
    closedClassTimeout: NodeJS.Timeout = undefined;

    isOpenChanged(newValue: boolean) {
        if (newValue) {
            clearTimeout(this.closedClassTimeout);
            this.collapseContentEl.classList.remove('closed');
            return;
        }

        this.closedClassTimeout = setTimeout(() => this.collapseContentEl.classList.add('closed'), 750);
    }
}
