// Module
var code = `<template>
    <div id="blogpost">
        <div if.bind="width > 992" class="d-flex container breadcrumbs flex-column flex-sm-row">
            <cx-breadcrumbs breadcrumbs.bind="breadcrumbs"></cx-breadcrumbs>
        </div>
        <div else class="d-flex container breadcrumbs flex-column flex-sm-row">
            <a
                class="return text-start text-purple font-medium d-inline-flex align-items-center cursor-pointer"
                href.bind="breadcrumbs[breadcrumbs.length - 2].href"
            >
                <span class="material-icons back-button rounded-circle me-1">
                    chevron_left
                </span>
                Back
            </a>
        </div>
        <div class="d-flex container flex-column flex-sm-row justify-content-end dropdown-container" if.bind="false">
            <mdc-select
                outlined
                class="form-control input-password p-0 text-regular cx-dropdown"
                required.bind="true"
                value.bind="language"
                selected-index.bind="languageSelected"
                change.delegate="changeSelectHandler(\$event)"
                click.delegate="handleClick(\$event)">
                <img src.bind="language.icon" mdc-select-icon class="flag-icon" alt="Language icon">
                <section data-simplebar>
                    <mdc-list focusout.delegate="handleFocusOut(\$event)">
                        <mdc-list-item class="\${ languageSelected == \$index ? 'd-none' : ''}" repeat.for="language of selectLanguages" value.bind="language" disable-ripple="false">
                            <img src.bind="language.icon" class="flag-icon" alt="Flag" loading="lazy">
                            \${ language.name }
                        </mdc-list-item>
                    </mdc-list>
                </section>
            </mdc-select>
        </div>        
        <div class="content-padding w-100">
            <div class="blogpost-container mx-auto d-flex justify-content-center">
                <div if.bind="blog.previewImagePath" class="img-container">
                    <img src="\${baseAwsEndpoint}\${blog.previewImagePath}" alt="\${blog.title}" class="blog-image" loading="lazy">
                </div>
                <div else class="no-image">
                    <span class="material-icons-outlined">image_not_supported</span>
                </div>
            </div>
            <div class="blogpost-container mx-auto">
                <div class="d-flex justify-content-center">
                    <h1 class="header-title text-center font-bold">
                        \${blog.title}
                    </h1>
                </div>
                <div class="d-flex justify-content-center">
                <div class="text-center d-flex published-at">
                    <div class="d-flex published">
                        <span class="mb-1 mb-sm-0">
                            <span>\${authorText}</span>
                            <a class="by-author" if.bind="blog.blogAuthor" href="\${blogsPageRoute}/author/\${blog.blogAuthor.alias}">
                                \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                            </a>
                        </span>               
                        <span class="mx-3 d-none d-sm-block">|</span>
                        <span class="mb-1 mb-sm-0">
                           Created: \${blog.createdDate | dateFormatter:'format':'MMMM DD, YYYY'}
                        </span>
                        <span class="mx-3 d-none d-lg-block">|</span>
                    </div>
                    <div class="d-flex published">
                        <span if.bind="blog.createdDate !== blog.updatedDate" class="mb-1 mb-sm-0">
                           Last update: \${blog.updatedDate | dateFormatter:'format':'MMMM DD, YYYY'}
                        </span>
                        <span if.bind="blog.createdDate !== blog.updatedDate" class="mx-3 d-none d-sm-block">|</span>
                        <span>
                            Read Time: \${readingTimeResult} \${readingTimeResult === 1 ? 'minute' : 'minutes'}
                        </span>
                    </div>
                </div>
            </div>
                <div class="blog-content" innerhtml.bind="blog.content"></div>
                <div>
                    <hr>
                    <div class="container justify-content-center d-flex">
                        <img class="author-profile" src="\${blog.blogAuthor.profileImagePath ? \`\${baseAwsEndpoint}\${blog.blogAuthor.profileImagePath}\` : \`\${environment}Image/chicks-avatars/chick_12\`}" alt="Author profile" loading="lazy">
                    </div>
                    <div class="container justify-content-center d-flex author-name font-medium">
                        <a href="\${blogsPageRoute}/author/\${blog.blogAuthor.alias}">
                            \${blog.blogAuthor.firstName | fullname: blog.blogAuthor.lastName}
                        </a>
                    </div>
                    <div class="container author-description">
                        \${blog.blogAuthor.about}
                    </div>
                    <hr>
                </div>
            </div>
            <div if.bind="!isCSRBlog()" class="container blogpost-container">
                <div class="text-center articles">
                    <span class="font-medium">Related Articles</span>
                </div>
                <div>
                    <cx-posts-cards blogs.bind="relatedArticles" blog-route.bind="blogsPageRoute"></cx-posts-cards>
                </div>
            </div>
            <div if.bind="isCSRBlog() && blog.csrButtons.length > 0 && (csrBanner.isVisible || csrBanner.isVisible === null)" class="container">
                <span if.bind="viewingAsAdmin">\${ csrBanner.key }</span>
                <cds-dynamic-banner
                    else
                    disabled.bind="csrButtonDisabled"
                    image-url.bind="imageBaseAwsEndpoint + csrBanner.bannerImagePath"
                    background-url.bind="\`\${imageBaseAwsEndpoint}\${csrBanner.bannerBackgroundPath}\`"
                    title.bind="csrBanner.bannerTitle"
                    description.bind="csrBanner.bannerDescription"
                    buttonLink.bind="csrBanner.buttonLink"
                    button-text.bind="csrBanner.buttonCta"
                    ctaclick.trigger="navigateCtaLink()"
                >
                </cds-dynamic-banner>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;