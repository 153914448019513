// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/chicks/crying_chick_empty.svg", import.meta.url);
// Module
var code = `<template>
    \${ preload() }
    <div id="cx-order-section" class="h-100 position-relative">
        <div show.bind="pageLoading">
            <div if.bind="!isPhone">
                <div class="skeleton-container"></div>
                <div class="filter-skeleton-elements-first d-flex">
                    <div class="inner-skeleton-element \${\$last ? 'flex-grow-1' : ''}" repeat.for="i of 2"></div>
                </div>

                <cx-card class.bind="'container-portal-style \${pageLoading ? skeleton-container-style : } area d-flex justify-content-start align-items-stretch flex-column'">
                    <div class="back-skeleton"></div>
                    <div class="filter-skeleton-elements-second d-flex justify-content-between align-items-center">
                        <div class="inner-skeleton-element" repeat.for="i of 2"></div>
                    </div>
                    <div class="skeleton-container-table d-flex flex-column align-content-center align-items-center">
                        <div class="skeleton-container-rows" repeat.for="i of 7"></div>
                    </div>
                </cx-card>
            </div>
            <div else>
                <div class="skeleton-container"></div>
                <div class="filter-skeleton-elements-first d-flex">
                    <div class="inner-skeleton-element \${\$last ? 'flex-grow-1' : ''}" repeat.for="i of 2"></div>
                </div>
                <div class="skeleton-container"></div>
                <div class="mobile-orders-skeleton-conctainer d-flex flex-column" repeat.for="i of 3">
                    <div class="order-skeleton-element" repeat.for="i of 3"></div>
                </div>
                <div class="skeleton-container mx-auto mb-0"></div>
            </div>
        </div>
        <div show.bind="!pageLoading">
            <h2 class="section-title font-medium mb-2">\${ title }</h2>
            <div class="searchbar-container d-flex flex-row w-100 justify-content-between">
                <cx-input-search
                    input-class="input-with-label"
                    search-query.two-way="search"
                    button-handler.call="clearSearch()"
                    handle-search.call="searchFunction()"
                    outlined.bind="false"
                    class="w-100 d-none d-sm-inline-block"
                ></cx-input-search>
                <cx-search-drawer
                    is-empty.bind="orders.length === 0"
                    handle-search.call="searchDrawerFunction()"
                    search.two-way="search"
                    handle-clear.call="clearSearch()"
                    button-style="button-search-drawer"
                    class="d-inline-block d-sm-none"
                >
                    <cx-infinite-scroll
                        load.bind="infiniteScrollLoad"
                        page.two-way="page"
                        page-count.bind="ordersCount"
                    >
                        <div class="d-flex flex-column gap-3 pb-100 px-regular">
                            <cx-order-row
                                repeat.for="order of mappedOrdersMobile"
                                order.bind="order"
                                width.bind="width"
                                click.delegate="handleRowClick(order)"
                                icon-path.bind="iconPathRow"
                                mobile-row-height.bind="162"
                            ></cx-order-row>
                        </div>
                    </cx-infinite-scroll>
                </cx-search-drawer>
                <a if.bind="createButton && createButtonFunction || route" click.delegate="createButtonFunction()" href="\${ route }">
                    <cx-button
                        button-text.bind="'CX_CREATE_NEW_ORDER' | siteStrings"
                        button-style.bind="createButtonClassList"
                    ></cx-button>
                </a>
            </div>
            <cx-card show.bind="!pageLoading" class.bind="\`orders-container \${ordersEmpty ? 'orders-container__empty' : ''}\`">
                <cds-back-button if.bind="(backRoute || handleBackButton) && width > desktop" page-loading.bind="pageLoading" click.trigger="backRoute ? router.navigateToRoute(backRoute) : handleBackButton()"></cds-back-button>
                <div class="d-flex \${ !contentLoading ? 'justify-content-between' : 'justify-content-end' } align-items-center w-100 sort-container">
                    <p show.bind="!contentLoading && !isPhone" class="m-0">\${ paginationCount }</p>
                    <cx-filter
                        type="select"
                        options.bind="sort ? sortByOptions : statusOptions"
                        material-icon="sort"
                        label.bind="sort ? 'Sort By' : 'Show'"
                        change-function.bind="handleStatusUpdate"
                        value.bind="status"
                        class="\${ isPhone ? 'w-100' : '' }"
                    ></cx-filter>
                </div>
                <div show.bind="contentLoading">
                    <div if.bind="!isPhone" class="skeleton-container-table inner-table d-flex flex-column align-content-center align-items-center">
                        <div class="skeleton-container-rows" repeat.for="i of 8"></div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center" if.bind="!pageLoading && !contentLoading && mappedOrders.length === 0">
                    <img src="${___HTML_LOADER_IMPORT_0___}" class="crying-chick" alt="crying_chick" loading="lazy">
                    <span class="text-normal font-regular text-center empty-caption">
                        \${ 'CX_NO_ENTRIES_TO_SHOW' | siteStrings }
                    </span>
                </div>
                <cx-table
                    show.bind="!contentLoading && mappedOrders.length > 0"
                    table-headers.bind="tableHeaders"
                >
                    <cx-order-row
                        repeat.for="order of mappedOrders"
                        order.bind="order"
                        width.bind="width"
                        click.delegate="handleRowClick(order)"
                        icon-path.bind="iconPathRow"
                        mobile-row-height.bind="isCashback ? 100 : 162"
                        container-left-padding.bind="isCashback ? 15 : 20"
                        is-subscription.bind="order.isSubscriptionOrder"
                        mobile-column-width.bind="isCashback ? 90 : null"
                        type.bind="type"
                        icon-variant.bind="iconVariant"
                    ></cx-order-row>
                </cx-table>
                <cx-pagination show.bind="!contentLoading && realCount > perPage" count.two-way="ordersCount" page.two-way="page"></cx-pagination>
            </cx-card>
        </div>
    </div>
</template>
`;
// Exports
export default code;