import './cx-amount-currency-selector.scss';
import { CDS_TEXT_FIELD_STATE } from '@chicksgroup/web-components/dist';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { AdminViewUpdatedEvent, SizeChangedEvent } from 'resources/constants';
import { Helper } from 'resources/helpers/helper';
import { Currency } from 'services/models/purchase-flow/exchange';
import { ISizeEvent } from 'types/events';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { ToastService } from 'services/toast-service';

@autoinject()
export class CxAmountCurrencySelector {
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private toastService: ToastService
    ) { }

    @bindable currencyList: Currency[];
    @bindable currencySelected: Currency;
    @bindable exchangeCurrencySelected: Currency;
    @bindable intent: string;
    @bindable activeCurrencies: Currency[];

    @bindable amount: number;
    @bindable defaultAmount: number = 0;
    @bindable selectedPaymentMethod: string;
    @bindable tooltipRefId: string;
    @bindable pageLoading: boolean;
    @bindable maxAmount: number;
    inputAmount: number | string;
    parent;
    currencyCodeSuffixRef: HTMLElement;
    showTooltip: boolean;
    width: number;
    amountInputMobileRef: HTMLElement;
    sizeSub: Subscription;
    tradeIntentChangedSub: Subscription;
    adminViewUpdatedSub: Subscription;
    amountCurrencySelector: HTMLElement;
    inputState: CDS_TEXT_FIELD_STATE = 'valid';
    viewingAsAdmin: boolean;

    bind(bindingContext) {
        this.parent = bindingContext;
        this.handleSizeScreenChange();
        this.handleEventSubscriptions();
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.tooltipRefId)
            this.showTooltip = true;
    }

    detached() {
        this.sizeSub.dispose();
        this.tradeIntentChangedSub?.dispose();
        this.adminViewUpdatedSub?.dispose();
    }

    handleSizeScreenChange() {
        this.sizeSub = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
            if (!this.inputAmount)
                return;
            if (this.width <= 350) {
                this.currencyCodeSuffixRef.style.left = '';
                this.currencyCodeSuffixRef.style.right = '0px';
            } else {
                this.currencyCodeSuffixRef.style.right = '';
                this.getAmountWidth(this.inputAmount);
            }
        });
    }

    handleEventSubscriptions() {
        this.tradeIntentChangedSub = this.eventAggregator.subscribe('trade-intent-changed', () => setTimeout(() => this.getAmountWidth(this.inputAmount), 100));
        this.adminViewUpdatedSub = this.eventAggregator.subscribe(AdminViewUpdatedEvent, (payload) => this.viewingAsAdmin = payload.bool);
    }

    selectChanged(currencySelected: Currency, intent: string, isClicked: boolean = false) {
        this.parent.selectChanged(currencySelected, intent, isClicked);
        if (!currencySelected) {
            this.inputAmount = undefined;
            this.amount = this.defaultAmount = 0;
        }
    }

    amountChanged() {
        if (this.amount.toFixed() !== this.inputAmount) {
            this.inputAmount = this.helper.parseInputAmount(this.amount);
        }
        this.getAmountWidth(this.inputAmount);
    }

    async focusOutCheckValid(event: 'keydown' | 'focusout') {
        if (!this.inputAmount && event === 'keydown')
            return;
        if (this.inputAmount)
            this.amount = parseFloat(this.inputAmount?.toString());
        if (this.amount.toString().includes('e')) {
            this.toastService.showToast('Error', 'The entered number is too large to process. Please enter a smaller value', 'error', 'error');
            this.amount = this.defaultAmount;
        }
        if (event === 'focusout')
            this.inputAmount = this.helper.parseInputAmount(!isNaN(this.amount) ? this.amount : this.defaultAmount);
        this.getAmountWidth(this.inputAmount);
        await this.parent.focusOutCheckValid(this.intent, event);
    }

    @computedFrom('intent', 'pageLoading', 'viewingAsAdmin')
    get intentLabel() {
        const intentMap = {
            'give': 'CX_YOU_GIVE',
            'receive': 'CX_YOU_RECEIVE'
        };
        return this.siteStringsValueConverter.toView(intentMap[this.intent]);
    }

    getAmountWidth(amountValue) {
        this.inputAmount = amountValue;
        if (!(amountValue as string)?.length)
            return;
        const inputDigitLength = (amountValue as string)?.replace('.', '').length;
        this.currencyCodeSuffixRef.style.left = `${inputDigitLength + 1}ch`;
        this.currencyCodeSuffixRef.style.right = 'unset';
        if ((this.currencyCodeSuffixRef.offsetLeft + 25) >= this.amountInputMobileRef.clientWidth) {
            this.currencyCodeSuffixRef.style.left = 'unset';
            this.currencyCodeSuffixRef.style.right = '0';
            return;
        }
    }

    get labelClass() {
        return this.inputAmount ? 'input-prefilled' : 'input-empty';
    }

    @computedFrom('currencySelected', 'exchangeCurrencySelected', 'intent')
    get showTooltipForReceive(): boolean {
        return this.currencySelected && this.exchangeCurrencySelected && this.intent === 'receive';
    }

    @computedFrom('inputAmount.length')
    get mobileInputClass() {
        if (this.maxAmount && parseFloat(this.inputAmount as string) > this.maxAmount) {
            const toFixedAmount = this.currencySelected?.type === 'F' || this.currencySelected?.isStable ? 2 : 6;
            this.inputAmount = this.maxAmount.toFixed(toFixedAmount);
        }
        if (!(this.inputAmount as string)?.length)
            return;
        const inputDigitLength = (this.inputAmount as string)?.replace('.', '').length;
        if (inputDigitLength > 9)
            return 'amount-input-mobile-10-digits';
        else if (inputDigitLength === 9)
            return 'amount-input-mobile-9-digits';
        else if (inputDigitLength === 8)
            return 'amount-input-mobile-8-digits';
        else if (inputDigitLength === 7)
            return 'amount-input-mobile-7-digits';
        else return '';
    }
}
