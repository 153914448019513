import { bindable, observable, autoinject } from 'aurelia-framework';
import {
    Currency,
    PaymentMethodWebsite,
} from 'services/models/purchase-flow/exchange';
import './cx-nav-preorder-mobile.scss';
import { getAWSBucketEndpoint } from 'environment';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Language } from 'services/models/language/language';
import { LanguageService } from 'services/language-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { LanguageChangedEvent, CurrencyChangedEvent, LanguageAbbreviations } from 'resources/constants';
import SimpleBar from 'simplebar';

interface Option {
    id: string;
    title: string;
    auxname: string;
    image?: string;
    isCategory?: boolean;
    category?: string;
    code?: string;
    childs: Option[];
}

@autoinject()
export class CxNavPreorderMobile {
    @bindable cryptoList: Currency[] = [];
    @bindable currencyList: Currency[] = [];
    @bindable paymentMethodsList: PaymentMethodWebsite[] = [];
    @bindable filterInput: string = '';
    @bindable currentCurrency: Currency;
    @bindable languageList: Language[] = [];
    @bindable currentLanguage: Language;

    @observable selectedOption: Option;
    private selectedOptionAux: Option;
    currencyExpandable;
    languageExpandable;

    private readonly options: Option = {
        id: 'initial',
        title: 'initial',
        auxname: 'initial',
        childs: [
            {
                id: 'buy-crypto',
                title: 'CX_BUY_CRYPTO',
                auxname: 'Buy Crypto',
                isCategory: true,
                childs: [],
            },
            {
                id: 'sell-crypto',
                title: 'CX_SELL_CRYPTO',
                auxname: 'Sell Crypto',
                isCategory: true,
                childs: [],
            },
            {
                id: 'exchange',
                title: 'CX_EXCHANGE',
                auxname: 'Exchange',
                isCategory: true,
                childs: [
                    {
                        id: 'exchange-fiat',
                        title: 'CX_EXCHANGE_FIAT',
                        auxname: 'Exchange Fiat',
                        isCategory: true,
                        childs: [],
                    },
                    {
                        id: 'exchange-crypto',
                        title: 'CX_EXCHANGE_CRYPTO',
                        auxname: 'Exchange Crypto',
                        isCategory: true,
                        childs: [],
                    }
                ],
            },
            {
                id: 'currency',
                title: 'USD',
                auxname: 'Currency',
                isCategory: true,
                childs: [],
            },
            {
                id: 'language',
                title: 'ENG',
                auxname: 'Language',
                isCategory: true,
                childs: [],
            },
        ],
    };

    optionsStack: Option[] = [];

    private baseAwsEndpoint: string;
    private languageAwsEndpoint: string;
    private basePaymentMethodsEndpoint: string;

    navbarCloseSubscriber;
    languageChangedSubscriber;
    currencyChangedSubscriber;

    containerElement: HTMLElement;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private languageService: LanguageService,
        private pageContentAreaService: PageContentAreaService
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
        this.basePaymentMethodsEndpoint =
            getAWSBucketEndpoint('payment-methods');
        this.languageAwsEndpoint = getAWSBucketEndpoint('languages');
    }

    attached() {
        this.handleEventSubscriptions();
        this.containerElement = document.getElementById(
            'selected-content'
        );
    }

    detached() {
        this.navbarCloseSubscriber?.dispose();
    }

    currentCurrencyChanged() {
        if (this.currentCurrency) {
            this.setOptionTitle('currency', this.currentCurrency.code);
            this.resetOptions();
        }
    }

    currentLanguageChanged() {
        if (this.currentLanguage) {
            this.setOptionTitle('language', this.currentLanguage.name);
            this.resetOptions();
        }
    }

    handleEventSubscriptions() {
        this.navbarCloseSubscriber = this.eventAggregator.subscribe(
            'navbar-close',
            () => {
                this.resetOptions();
            }
        );
        this.languageChangedSubscriber = this.eventAggregator.subscribe(LanguageChangedEvent, payload => {
            if (!payload.languageSelected) return;
            this.setOptionTitle('language', payload.languageSelected.name);
        });
        this.currencyChangedSubscriber = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            if (!payload.currencySelected) return;
            this.setOptionTitle('currency', payload.currencySelected.code);
        });
    }

    filterInputChanged() {
        if (this.filterInput === '') {
            this.selectedOption.childs = structuredClone(
                this.selectedOptionAux.childs
            );
            return;
        }
        this.selectedOption.childs = this.filterOptions(this.options, this.filterInput);
    }

    paymentMethodsListChanged() {
        this.fillOptions();
        this.selectedOption = structuredClone(this.options);
        this.selectedOptionAux = structuredClone(this.selectedOption);
    }

    fillOptions() {
        if (this.cryptoList) {
            const formatedList = this.orderCyrptoByTrending(this.cryptoList);
            this.options.childs[0].childs = formatedList.map(x => ({ ...x, category: 'buy-crypto' }));
            this.options.childs[1].childs = formatedList.map(x => ({ ...x, category: 'sell-crypto' }));
        }

        if (this.cryptoList && this.currencyList) {
            const cryptoMap = this.orderCyrptoByTrending(this.cryptoList);
            const currencyMap = this.orderCurrencyByTrendingList(this.currencyList);
            const languageMap = this.formattingLanguageList(this.languageList);

            this.options.childs[2].childs[1].childs = cryptoMap.map(
                (crypto) => ({
                    ...crypto,
                    category: 'exchange-crypto',
                    childs: !this.isAnSeparatorOption(crypto.id) ? cryptoMap : [],
                })
            );

            this.options.childs[2].childs[0].childs = currencyMap.map(
                (crypto) => ({
                    ...crypto,
                    category: 'exchange-fiat',
                    childs: !this.isAnSeparatorOption(crypto.id) ? currencyMap : [],
                })
            );

            this.options.childs[3].childs = currencyMap.map(
                (currency) => ({
                    ...currency,
                    title: !this.isAnSeparatorOption(currency.id) ? `${currency.title} - ${currency.auxname}` : currency.title,
                    category: 'currency'
                })
            );

            this.options.childs[4].childs = languageMap.map(
                (language) => ({
                    ...language,
                    category: 'language'
                })
            );
        }
    }

    selectOption(option: Option) {
        if (!this.selectedOption || !this.selectedOption.childs || this.isAnSeparatorOption(option.id)) {
            return;
        }

        this.optionsStack.push(structuredClone(this.selectedOptionAux));

        if (this.filterInput && this.selectedOption.id === 'initial') {
            if (option.category.includes('exchange')) {
                const previusCategory = structuredClone(this.options.childs[2].childs.find(({ id }) => id === option.category));
                this.optionsStack.push(this.options.childs[2], previusCategory);
            } else {
                const previusCategory = structuredClone(this.options.childs.find(({ id }) => id === option.category));
                this.optionsStack.push(previusCategory);
            }
        }

        this.filterInput = '';

        if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-crypto') {
            this.filterAndSetSelectedOption(option, this.cryptoList);
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-fiat') {
            this.filterAndSetSelectedOption(option, this.currencyList);
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'language') {
            this.selectLanguage(this.languageList.find(x => x.hrefLang === option.id));
        } else if (this.optionsStack[this.optionsStack.length - 1]?.id === 'currency') {
            this.selectCurrency(this.currencyList.find(x => x.code === option.code));
        } else {
            this.selectedOption = option;
            this.selectedOptionAux = structuredClone(this.selectedOption);
        }

        const { queryParams, objectParams } = this.getQueryParams(option);
        if (queryParams) {
            this.router.navigate('/trade' + queryParams);
            if (this.router.currentInstruction.config.route.includes('trade')) this.eventAggregator.publish('new-order-from-navbar', objectParams);
            this.eventAggregator.publish('trade-page-redirect');
            this.resetOptions();
        }
    }

    filterAndSetSelectedOption(option: Option, list = []) {
        const filteredExchangeOption = option;

        filteredExchangeOption.childs = list
            .filter((item) => ![item.reference, item.code].includes(option.id))
            .filter((item) => item.code.toLowerCase().includes(this.filterInput.toLowerCase()));

        if (this.optionsStack.length > 2) {
            if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-crypto') filteredExchangeOption.childs = this.orderCyrptoByTrending(filteredExchangeOption.childs, false);
            if (this.optionsStack[this.optionsStack.length - 1]?.id === 'exchange-fiat') filteredExchangeOption.childs = this.orderCurrencyByTrendingList(filteredExchangeOption.childs);
        }

        this.selectedOption = filteredExchangeOption;
        this.selectedOptionAux = structuredClone(this.selectedOption);
    }

    getQueryParams(option:Option) {
        if (
            this.optionsStack.find(({ id }) => ['buy-crypto', 'sell-crypto'].includes(id)) &&
            this.optionsStack.length === 3
        ) {
            const action = this.optionsStack[1].id === 'buy-crypto' ? 'receive' : 'give';
            const queryParams = `?${action}=${this.optionsStack[2].id}&method=${option.id}`;
            return { queryParams, objectParams: { [action]: this.optionsStack[2].id, method: option.id } };
        }

        if (this.optionsStack.find(({ id }) => id === 'exchange') && this.optionsStack.length === 4) {
            const queryParams = `?give=${this.optionsStack[3].id}&receive=${option.id}`;
            return { queryParams, objectParams: { give: this.optionsStack[3].id, receive: option.id } };
        }

        return { queryParams: '', objectParams: null };
    }

    resetOptions() {
        this.selectedOption = structuredClone(this.options);
        this.selectedOptionAux = structuredClone(this.options);
        this.filterInput = '';
        this.optionsStack = [];
    }

    goBack() {
        if (this.optionsStack.length === 0) return;
        this.selectedOption = this.optionsStack.pop();
        this.selectedOptionAux = structuredClone(this.selectedOption);
        this.filterInput = '';
    }

    showGoBackButton(): boolean {
        return this.optionsStack.length > 0;
    }

    formattingCurrencyList(currency: Currency[], category: string = '') {
        return currency?.map(curr => ({
            id: curr.reference || curr.code,
            title: curr.code,
            auxname: curr.description,
            code: curr.reference || curr.code,
            reference: curr.reference || curr.code,
            image: `${this.baseAwsEndpoint}${curr.imagePath}`,
            category,
            childs: [] as Option[]
        })).sort((a, b) => {
            if (a.code < b.code) return -1;
            else if (a.code > b.code) return 1;
            return 0;
        });
    }

    formattingMethodList(method: PaymentMethodWebsite[]) {
        return method?.map(method => ({
            id: method.paymentMethod.reference,
            title: method.paymentMethod.name,
            auxname: method.paymentMethod.name,
            image: `${this.basePaymentMethodsEndpoint}${method.paymentMethod.imagePath}`,
            childs: []
        }));
    }

    formattingLanguageList(language: Language[]) {
        return language?.map(lang => ({
            id: lang.hrefLang,
            title: LanguageAbbreviations.find(abbr => abbr.abbreviation === lang.name).name,
            auxname: lang.name,
            image: `${this.languageAwsEndpoint}${lang.imagePath}`,
            childs: [] as Option[]
        }));
    }

    private filterOptions(options: Option, filter: string) {
        let optionsClone = structuredClone(options.childs);
        if (!filter) return optionsClone;

        if (this.optionsStack.length > 0) {
            return structuredClone(this.selectedOptionAux.childs).filter(
                child => [child.title, child.auxname].some(x => x.toLowerCase().includes(filter.toLowerCase()))
            );
        }

        optionsClone = [
            ...optionsClone.filter(({ id }) => !this.isLanguageOrCurrency(id)),
            ...optionsClone.find(({ id }) => id === 'exchange').childs
        ];
        const globalFiltered = [];

        for (const option of optionsClone) {
            const itemsFiltred = [];
            for (const item of option.childs) {
                const searchOptionText = [item.title, item.id, item.auxname].join(' ').toLocaleLowerCase();
                if (!searchOptionText.includes(filter.toLocaleLowerCase()) || this.isAnSeparatorOption(item.id)) continue;
                itemsFiltred.push(item);
            }

            if (itemsFiltred.length === 0) continue;
            if (this.selectedOption.id !== option.id) globalFiltered.push(option);
            globalFiltered.push(...itemsFiltred);
        }

        return globalFiltered;
    }

    selectCurrency(currency: Currency) {
        this.eventAggregator.publish('selected-currency', { currency, element: this.currencyExpandable });
    }

    selectLanguage(language: Language) {
        this.eventAggregator.publish('selected-language', { language, element: this.languageExpandable });
    }

    setOptionTitle(optionId, text) {
        const optionIndex = this.options.childs.findIndex(x => x.id === optionId);
        this.options.childs[optionIndex].title = text;
    }

    isAnInitialOption = (optionId: string) => ['buy-crypto', 'sell-crypto', 'exchange'].includes(optionId);

    isATerminalCategory = (optionId: string) => this.isAnInitialOption(optionId) || this. isAnExchangeOption(optionId);

    isAnExchangeOption = (optionId: string) => ['exchange-crypto', 'exchange-fiat'].includes(optionId);

    recalculateSimplebar() {
        setTimeout(async () => {
            const mobileSimplebar = new SimpleBar(document.getElementById('mobile-navbar-drawer-content'));
            if (mobileSimplebar) mobileSimplebar.recalculate();
        }, 100);
    }

    isLanguageOrCurrency(id) {
        return ['language', 'currency'].includes(id);
    }

    isAnSeparatorOption = (optionId) => ['trending', 'all'].includes(optionId);

    orderCyrptoByTrending(cryptoList, addPaymentMethodsList = true) {
        let sortCryptoList = [];
        const trendingCryptoList = cryptoList.filter(crypto => crypto.isTrending);
        const paymentMethodOrderList = this.orderPaymentMethodListByPopular(structuredClone(this.paymentMethodsList));

        if (trendingCryptoList.length > 0) {
            sortCryptoList = sortCryptoList.concat([
                {
                    id: 'trending',
                    title: 'Trending',
                    auxname: 'Trending',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...trendingCryptoList.map((crypto) => ({
                    id: crypto.reference || crypto.code,
                    title: crypto.code,
                    code: crypto.code,
                    reference: crypto.reference,
                    auxname: crypto.description,
                    image: `${this.baseAwsEndpoint}${crypto.imagePath}`,
                    childs: addPaymentMethodsList ? [...paymentMethodOrderList] : []
                }))
            ]);
        }

        sortCryptoList = sortCryptoList.concat([
            {
                id: 'all',
                title: 'All',
                auxname: 'All',
                code: '',
                reference: '',
                image: null,
                childs: []
            },
            ...cryptoList.map((crypto) => ({
                id: crypto.reference || crypto.code,
                title: crypto.code,
                auxname: crypto.description,
                image: `${this.baseAwsEndpoint}${crypto.imagePath}`,
                childs: addPaymentMethodsList ? [...paymentMethodOrderList] : []
            }))
        ]);

        return sortCryptoList;
    }

    orderCurrencyByTrendingList(currencyList) {
        const trendingCurrencyList = currencyList.filter(currency => currency.isTrending);
        const trendingCurrencyIds = trendingCurrencyList.map(currency => currency.id);
        const restOfTrendingList = currencyList.filter(currency => !trendingCurrencyIds.includes(currency.id));
        let currencyListSorted = [];

        if (trendingCurrencyList.length > 0) {

            currencyListSorted = currencyListSorted.concat([
                {
                    id: 'trending',
                    title: 'Trending',
                    auxname: 'Trending',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingCurrencyList(trendingCurrencyList, 'exchange-fiat'),
            ]);

        }

        if (restOfTrendingList.length > 0) {
            currencyListSorted = currencyListSorted.concat([
                {
                    id: 'all',
                    title: 'All',
                    auxname: 'All',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingCurrencyList(restOfTrendingList, 'exchange-fiat'),
            ]);
        }

        return currencyListSorted;
    }

    orderPaymentMethodListByPopular(paymentMethodList: PaymentMethodWebsite[]) {
        let orderList = [];
        const trendingPaymentMethodList = [...paymentMethodList].slice(0, 3);
        const restOfList = paymentMethodList.splice(3);

        if (trendingPaymentMethodList.length > 0) {
            orderList = orderList.concat([
                {
                    id: 'trending',
                    title: 'Trending',
                    auxname: 'Trending',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingMethodList(trendingPaymentMethodList)
            ]);
        }

        if (restOfList.length > 0) {
            orderList = orderList.concat([
                {
                    id: 'all',
                    title: 'All',
                    auxname: 'All',
                    code: '',
                    reference: '',
                    image: null,
                    childs: []
                },
                ...this.formattingMethodList(restOfList)
            ]);
        }

        return orderList;
    }

    listHaveChilds() {
        if (!this.selectOption) return;
        return this.selectedOption.childs.every(option => option.childs.length === 0);
    }

    get resultsIsEmpty() {
        return this.selectedOption.childs?.length === 0;
    }

    roundedIcon(option: Option) {
        return ['buy-crypto', 'sell-crypto', 'language', 'currency', 'exchange-fiat'].includes(option.category) ? 'rounded rounded-5' : '';
    }
}
