import './blog-post.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BlogService } from 'services/blog-service';
import { getAWSBucketEndpoint, apiEndpoint, baseUrl } from 'environment';
import { WebsiteService } from 'services/website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { DynamicButton } from 'resources/helpers/dynamic-buttons';
import { TextTruncateValueConverter } from 'resources/value-converters/text-truncate';
import { buttonRegExp } from 'resources/constants';

@autoinject()
export class Blogpost {
    constructor(private router: Router, private blogService: BlogService, private websiteService: WebsiteService, private eventAggregator: EventAggregator, private helper: Helper, private textTruncateValueConverter: TextTruncateValueConverter) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
        this.imageBaseAwsEndpoint = getAWSBucketEndpoint('page-content-area-image');
    }

    baseAwsEndpoint: string;
    imageBaseAwsEndpoint: string;
    blogs;
    blog;
    blogTag;
    environment = apiEndpoint();
    relatedArticles;
    language;
    languageSelected: number;
    arrowTriggered;
    authorText: string;
    arrowRotated: boolean;
    lastEventTriggered: string;
    @bindable blogsPageRoute;
    selectLanguages = [
        {
            name: 'English US',
            code: 'en',
            icon: this.environment + 'Image/chicks-currencies/us-flag.png'
        },
        {
            name: 'English UK',
            code: 'gb',
            icon: this.environment + 'Image/chicks-currencies/gb-flag.png'
        },
        {
            name: 'Spanish',
            code: 'es',
            icon: this.environment + 'Image/chicks-currencies/es-flag.png'
        },
        {
            name: 'Dutch',
            code: 'nl',
            icon: this.environment + 'Image/chicks-currencies/nl-flag.png'
        },
        {
            name: 'French',
            code: 'fr',
            icon: this.environment + 'Image/chicks-currencies/fr-flag.png'
        },
    ];

    breadcrumbs = [];
    homePageRoute;
    readingTimeResult = 0;
    baseUrl = baseUrl();
    sizeChanged;
    width;
    csrButtonDisabled = false;

    /**
    * @type {DynamicButton[]}
    */
    dynamicButtons = [];

    async activate(params, routeConfig) {
        if (params.blog !== 'author') {
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.blogsPageRoute = pages.find(x => x.name === 'Blog')?.routeName ?? 'blog';
            this.relatedArticles = await this.blogService.getPublishedBlogPosts(3, 1);
            this.blog = await this.blogService.getBlogBySlug(params.blog, true);
            if (!this.blog || routeConfig.route.includes('*childRoute')) {
                this.router.navigateToRoute('404');
            }
            this.authorText = (this.blog.blogTag?.name === 'CSR' && this.blog.blogTag?.translated) ? 'Translated By' : (this.blog.blogAuthor ? 'By' : 'By -');
            const regex = /[<][p][>][<][b][r][>][<][/][p][>]/g;
            this.blog.content = this.blog.content?.replace(regex, '');
            routeConfig.navModel.setTitle(this.blog?.title);
            if (this.blog?.metaDescription) routeConfig.navModel.settings.metaDescription = this.blog.metaDescription;
            this.languageSelected = 0;
            this.language = this.selectLanguages[this.languageSelected];
        }
        this.readingTimeResult = this.readingTime();
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const categoryTag = this.blog.blogTag?.name ?? 'All';
        const categoryHref = categoryTag === 'All' ? this.blogsPageRoute : `${this.blogsPageRoute}/category/${this.blog.blogTag.name.toLowerCase().replaceAll(' ', '-')}`;
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        if (this.blog) {
            await this.blogService.updateBlogPostViewCounter(this.blog.id);
        }
        this.handleReviewSchema();
        this.breadcrumbs = [
            { title: 'Home', href: this.homePageRoute },
            { title: 'Blogs', href: this.blogsPageRoute },
            { title: categoryTag, href: categoryHref },
            { title: this.blog.title, tooltip: this.blog.title.length > 80 }
        ];
        this.handleEventSubscriptions();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    @computedFrom('blog')
    get csrBanner() {
        return this.blog?.csrButtons[0];
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });
    }

    changeSelectHandler(ev) {
        this.languageSelected = ev.detail.index;
        this.handleFocusOut();
    }

    handleFocusOut() {
        if (this.arrowTriggered) {
            this.arrowTriggered.style.transform = 'rotate(0deg)';
            this.arrowRotated = false;
            this.lastEventTriggered = 'focusOut';
            setTimeout(() => {
                this.lastEventTriggered = '';
            }, 200);
        }
    }

    handleClick(e) {
        this.arrowTriggered = e.target.querySelector('.arrow-icon');
        if (this.arrowRotated) {
            if (this.arrowTriggered) {
                this.arrowTriggered.style.transform = 'rotate(0deg)';
            }
            this.arrowRotated = false;
        } else {
            if (this.lastEventTriggered !== 'focusOut') {
                if (this.arrowTriggered) {
                    this.arrowTriggered.style.transform = 'rotate(180deg)';
                }
                this.arrowRotated = true;
            }
        }
    }

    handleReviewSchema() {
        let reviewSchema = `
        {
            "@type": "BlogPosting",
            "@id": "${baseUrl()}#/schema/BlogPosting/${this.blog.id}",
            "headline": "${this.blog.title}",`;

        if (this.blog.previewImagePath) {
            reviewSchema += `"image": "${this.baseAwsEndpoint}${this.blog.previewImagePath}",`;
        }

        reviewSchema += `
            "datePublished": "${this.blog.createdDate}",
            "dateModified": "${this.blog.updatedDate}"`;

        if (this.blog.blogAuthor) {
            reviewSchema += `,
                "author": {
                    "@id": "${baseUrl()}#/schema/Person/${this.blog.blogAuthorId}"
                }`;
        }

        reviewSchema += '}';

        if (this.blog.blogAuthor) {
            reviewSchema += `,{
                "@type": "Person",
                "@id": "${baseUrl()}#/schema/Person/${this.blog.blogAuthorId}",
                "name": "${this.blog.blogAuthor.firstName + ' ' + this.blog.blogAuthor.lastName}",
                "url": "${baseUrl()}${this.blogsPageRoute}/author/${this.blog.blogAuthor.alias}"
            }`;
        }

        this.helper.combineApplicationLdJsonSchemasIntoOne(reviewSchema);
    }

    getReviewSchema = () => document.getElementById('article-schema');

    async tagClickHandler(tag) {
        this.router.navigateToRoute('blog-category', { category: tag.slug });
    }

    readingTime() {
        let wordCount = this.blog.content;
        wordCount = wordCount?.replace(buttonRegExp(), '');
        wordCount = this.blog.content?.split(' ').length;
        const readingTimeMinutes = Math.ceil(wordCount / 250);
        return !isNaN(readingTimeMinutes) ? readingTimeMinutes : 1;
    }

    isCSRBlog() {
        return this.blog.blogTag?.name === 'CSR' || this.blog.blogTags.some(tag => tag.blogTag.name === 'CSR');
    }

    navigateToBlog() {
        this.router.navigate(this.blogsPageRoute);
    }

    navigateCtaLink() {
        this.csrButtonDisabled = true;
        this.router.navigate(this.blog.csrButtons[0].buttonLink);
        this.csrButtonDisabled = false;
    }

    generateDynamicButtons() {
        this.blog.content = this.blog.content?.replace(buttonRegExp(), (_, buttonText, buttonLink, display, offset) => {
            const buttonTextTruncated = this.textTruncateValueConverter.toView(buttonText, 27);
            const dynamicButton = new DynamicButton(offset, buttonTextTruncated, buttonLink, display);
            // Construct the button element
            this.dynamicButtons.push(dynamicButton);
            return dynamicButton.buildHtml();
        });
    }
}
