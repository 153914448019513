import './balance-topup.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { SessionService } from 'services/session-service';
import { User } from 'services/models/user/user';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { UserVerification } from 'resources/extensions/user_verification';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';
import {
    CashInPersonDetail,
    NuveiAddUPOCreditCardResponse,
    NuveiPaymentDataRequest,
    OrderDetail,
    OrderType,
    PaymentMethod,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    RecentlySavedCC
} from 'services/models/purchase-flow/exchange';
import { observable, computedFrom } from 'aurelia-framework';
import { CurrencyChangedEvent, ForceCurrencyEvent } from 'resources/constants';
import { CurrencyService } from 'services/currency-service';
import { ToastService } from 'services/toast-service';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { CxVeriff } from 'resources/elements/cx-veriff/cx-veriff';
import { ProductService } from 'services/product-service';
import { baseUrl, websiteShortCode } from 'environment';
import { NuveiService } from 'services/nuvei-service';
import { OrderService } from 'services/order-service';
import { Order, OrderRequest } from 'services/models/order';
import { Router } from 'aurelia-router';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { ICurrencyChangedEvent, IForceCurrencyEvent } from 'types/events';
import { IMdcTooltip, TooltipStyling } from 'resources/helpers/tooltip-styling';
import { CreditCardHelper } from 'resources/helpers/credit-card-helper';
import { Toast, ToastType } from 'services/models/toast';
import { SignalrService } from 'services/signalr-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { BlockActionLogService } from 'services/block-action-log-service';

@autoinject()
export class BalanceTopup extends UserVerification {
    user: User;
    convertedBalance = '';
    currencySubscription: Subscription;
    currencySymbol = '';
    conversionRate: number | null = null;
    usdAmount: number | null = 0;
    smallOrderFee: number | null = 0;
    transactionFee: number | null = 0;
    balanceTooltipRef: IMdcTooltip;
    balancePendingTooltipRef: IMdcTooltip;
    @observable amount: number | null;
    @observable currentCurrency: string;
    @observable selectedPaymentMethod: PaymentMethodWebsite;

    ccRecentlySaved: RecentlySavedCC | null;
    veriffViewModel: CxVeriff;

    //cash-in-mail validation data
    exchange = { trackingNumber: '' };
    userDocumentsList: File[] = [];
    @observable trackingNumberValid = false;

    //cash-in-person validation data
    date: string;
    @observable meetupAddressValid: boolean;
    location: string;
    time: string;
    selectedDelivery: DeliveryMethod;

    connection;
    blacklistEvent = 'BlacklistResolution';
    pageLoading: boolean = true;
    pageLoadedSubscriber: Subscription;

    private redirectPaymentMethods = ['ideal', 'sofort', 'checkout', 'google-pay-direct', 'apple-pay-direct'];

    constructor(
        private sessionService: SessionService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private currencyService: CurrencyService,
        private notification: ToastService,
        private fiatCurrencyFormatValueConverter: FiatCurrencyFormatValueConverter,
        private productService: ProductService,
        private nuveiService: NuveiService,
        private orderService: OrderService,
        private router: Router,
        private deliveryDateConverter: DeliveryDateConverter,
        private tooltipStyling: TooltipStyling,
        private signalRService: SignalrService,
        private pageContentAreaService: PageContentAreaService,
        private blockActionLogService: BlockActionLogService,
        eventAggregator: EventAggregator,
        paymentMethodService: PaymentMethodWebsiteService,
        customerService: CustomerService,
        helper: Helper,
        creditCardHelper: CreditCardHelper
    ) {
        super(eventAggregator, paymentMethodService, customerService, helper, creditCardHelper);
    }

    async activate() {
        this.user = await this.sessionService.getProfile();
        this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.getTotalBalance());
        await this.setCurrencySymbol();
    }

    attached() {
        try {
            this.helper.addLoadingComponent('balance-topup');
            this.handleEventSubscription();
            this.startVerificationListeners();

            this.currencySubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, async(payload: ICurrencyChangedEvent) => {
                this.helper.debounce(this, 'checkingCurrency', 'currencyChangeTimeout', 250, async () => {
                    this.eventAggregator.publish('check-supported', { method: this.selectedPaymentMethod });
                    this.convertedBalance = await this.currencyFormatValueConverter.toViewFiatOnly(this.getTotalBalance(), null, payload.currencySelected.originalCode);
                    await this.setCurrencySymbol(payload.currencySelected.originalCode);
                });

            });

            this.signalRService.getSignalRConnection().then((connection) => {
                if (!connection) {
                    return;
                }
                this.connection = connection;
                this.connection.on(this.blacklistEvent, async() => {
                    this.user = await this.sessionService.getProfile(true);
                });
            });
        } finally {
            this.helper.validateLoading('balance-topup');
        }
    }

    detached() {
        this.clearVerificationListeners();
        this.currencySubscription.dispose();
        this.pageLoadedSubscriber?.dispose();
    }

    handleEventSubscription() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    async setCurrencySymbol(newCurrency?: string) {
        const currency = newCurrency ?? await this.sessionService.getCurrency();
        if (currency !== this.currentCurrency) this.currentCurrency = currency;
        this.currencySymbol = this.currencyFormatValueConverter.getCurrencyPrefix(this.currentCurrency);
    }

    async userVerificationUpdated(user: User) {
        await this.checkForVerification(user, this.amount, this.selectedPaymentMethod?.paymentMethod);
    }

    async phoneVerificationUpdated(phoneConfirmed: boolean) {
        if (!phoneConfirmed) {
            this.user.phoneVerified = false;
        }

        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async autoTriggerOrder() {
        await this.sessionService.refreshProfile();
        await this.startOrder();
    }

    async startOrder() {
        const createOrder = await this.canCreateOrder();
        if (!createOrder) return;

        const orderData = await this.buildOrder();
        const order = await this.orderService.createTopUpBalanceOrder(orderData);

        if (!order) {
            this.notification.showToast('Error', 'Failed to create order', 'error');
            this.payEnabled = true;
            return;
        }

        if (order.hostedUrl) {
            //Coinbase Commerce Order & iDEAL (BlueSnap) & Coinpayments & BTCPay & BitCart & Sofort & Skrill Direct
            this.finish(order, order.hostedUrl);
        } else if (order.g2aToken) {
            //G2A Orders
            this.finish(order, 'https://checkout.pay.g2a.com/index/gateway?token=' + order.g2aToken);
        } else if (order.paypalOrderAggregate && order.paypalOrderAggregate.paymentUrl) {
            //Paypal orders
            this.finish(order, order.paypalOrderAggregate.paymentUrl);
        }

        this.openLivechat(order);
        this.notification.showToast('Info', 'Payment pending. Your ChicksX wallet will reflect your balance once the payment has been verified.', 'info');
        this.router.navigateToRoute('order-completed', { id: order.id });
        this.payEnabled = true;
    }

    openLivechat(order: Order) {
        if (!window.Intercom) return;
        window.Intercom('showNewMessage', `Hello! I have a question about order #${order.id}`);
    }

    async canCreateOrder() {
        const paymentMethodValid = await this.validatePaymentMethod();
        if (!paymentMethodValid) return false;

        this.payEnabled = false;
        const checkForStartVerification = await this.checkForStartVerification();

        if (checkForStartVerification) {
            this.veriffViewModel.startVerification();
            return false;
        }

        return true;
    }

    async validatePaymentMethod() {
        if (!this.selectedPaymentMethod?.paymentMethod) return false;

        if (this.selectedPaymentMethod.paymentMethod.usesCard && !this.selectedPaymentMethod.paymentMethod.cardInfo) {
            this.showBalanceError('Missing card information');
            return false;
        }

        if (this.usdAmount < this.selectedPaymentMethod.minimum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.minimum);
            this.showBalanceError(`Payment Method Minimum Not Met ${formattedAmount}`);
            return false;
        }

        if (this.usdAmount > this.selectedPaymentMethod.maximum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.maximum);
            this.showBalanceError(`Payment Maximum Exceeded ${formattedAmount}`);
            return false;
        }

        return true;
    }

    showBalanceError(message: string) {
        this.notification.showToast('Payment method error', message, ToastType.ERROR);
    }

    private getTotalBalance() {
        return (this.user.balance ?? 0) + (this.user.pendingBalance ?? 0);
    }

    async finish(order: Order, url: string) {
        if (this.selectedPaymentMethod.paymentMethod.reference.includes('bluesnap')) {
            await this.sessionService.refreshProfile();
        }

        if (!url) return;

        if (this.helper.includesSome(order.paymentMethod.reference, this.redirectPaymentMethods)) {
            this.router.navigate(url);
            return;
        }

        const checkOutWindow = window.open(url);

        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: url });
        }
    }

    async buildOrder(): Promise<OrderRequest | null> {
        const productsResponse = await this.productService.getProductsWithFilter('Balance');

        if (!productsResponse?.length) return;

        const product = productsResponse[0];
        let pylonPaymentData: PylonPaymentDataRequest;
        let nuveiPaymentData: NuveiPaymentDataRequest;
        let inPersonDetail: CashInPersonDetail;

        switch (this.selectedPaymentMethod.paymentMethod.reference) {
            case 'pylon': {
                pylonPaymentData = {
                    cardReferenceID: null,
                    baseUrl: baseUrl(),
                };
                break;
            }
            case 'nuvei': {
                let nuveiCardSaved: NuveiAddUPOCreditCardResponse | null = null;
                if (this.ccRecentlySaved) nuveiCardSaved = await this.nuveiService.addCreditCard(this.ccRecentlySaved);

                nuveiPaymentData = {
                    cvv: this.ccRecentlySaved?.cvv,
                    userPaymentOptionId: nuveiCardSaved ? nuveiCardSaved.userPaymentOptionId : parseInt(this.selectedPaymentMethod.paymentMethod.cardInfo.cardId ?? '0')
                };
                break;
            }
            case 'cash-in-person': {
                inPersonDetail = {
                    date: this.date,
                    location: this.location,
                    time: this.time,
                    meetingType: this.getDeliveryDate(this.selectedDelivery)
                };
                break;
            }
        }

        const orderDetail: OrderDetail = {
            cashInPersonDetail: inPersonDetail
        };

        return {
            websiteShortCode: websiteShortCode(),
            totalPrice: this.usdAmount,
            products: [{
                productId: product.id,
                quantity: 1,
                price: this.usdAmount
            }],
            orderDetail,
            easyPostTrackingId: this.exchange.trackingNumber,
            currencyUsed: this.currentCurrency,
            paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
            status: `${this.selectedPaymentMethod.paymentMethod.reference}:created`,
            address: this.ccRecentlySaved?.billingAddress?.address,
            city: this.ccRecentlySaved?.billingAddress?.city,
            state: this.ccRecentlySaved?.billingAddress?.state,
            country: this.ccRecentlySaved?.billingAddress?.country,
            zip: this.ccRecentlySaved?.billingAddress?.zip,
            referralLinkUrl: this.sessionService.getReferralLink(),
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            cardLastFour: this.ccRecentlySaved?.ccCardNumber?.slice(-4),
            cardLast4: this.ccRecentlySaved?.ccCardNumber?.slice(-4),
            cardType: this.ccRecentlySaved?.ccCardNumber ? this.creditCardHelper.getType(this.ccRecentlySaved?.ccCardNumber) : null,
            pylonPaymentData,
            nuveiPaymentData,
            cardCvv: this.ccRecentlySaved?.cvv,
            orderTypeId: OrderType.TopUp
        };
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }

    setTransactionFee() {
        if (!this.selectedPaymentMethod) {
            this.transactionFee = this.smallOrderFee = 0;
            return;
        }

        let remainingAmount = this.usdAmount;
        this.smallOrderFee = 0;

        if (remainingAmount > 0) {
            if (remainingAmount < this.selectedPaymentMethod.smallOrderMinimumAmount) this.smallOrderFee = this.selectedPaymentMethod.smallOrderFee;
            remainingAmount += this.smallOrderFee;
            this.transactionFee = parseFloat(((remainingAmount * (this.selectedPaymentMethod.fee / 100)) + this.selectedPaymentMethod.additionalInternalFee).toFixed(2));
            return;
        }

        this.transactionFee = 0;
    }

    setTooltipStyle(element: IMdcTooltip, pending = false) {
        if (!pending) this.tooltipStyling.setArrowPosition(element, 'BELOW_START');
        if (pending) this.tooltipStyling.addWidthClass(element);
        this.tooltipStyling.setMaxWidth(element, pending ? 340 : 372);
    }

    showToast = (data: Toast) => {
        this.notification.showToast(data.title, data.message, data.toastType);
    };

    async checkForVerification(user: User, totalPrice: number, selectedPaymentMethod?: PaymentMethod) {
        await super.checkForVerification(user, totalPrice, selectedPaymentMethod);

        if (selectedPaymentMethod?.reference === 'cash-in-mail' && !this.trackingNumberValid) this.payEnabled = false;
        else if (selectedPaymentMethod?.reference === 'cash-in-person' && !this.meetupAddressValid) this.payEnabled = false;
    }

    async selectedPaymentMethodChanged(newValue: PaymentMethodWebsite) {
        const eventData: IForceCurrencyEvent = {
            currentPaymentMethodSelected: newValue,
            currency: await this.sessionService.getCurrency(),
            forceSelector: true
        };

        this.eventAggregator.publish(ForceCurrencyEvent, eventData);
        this.setTransactionFee();
        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async currentCurrencyChanged(newValue: string | null) {
        if (!newValue || newValue === 'USD') this.conversionRate = 1;
        else this.conversionRate = await this.currencyService.getConvertionRateToUSD(newValue, false);

        if (this.amount) this.usdAmount = this.amount * this.conversionRate;
        if (this.selectedPaymentMethod) this.setTransactionFee();
    }

    async amountChanged(newValue: number | null) {
        if (newValue) this.usdAmount = newValue * this.conversionRate;
        else this.usdAmount = null;

        if (this.selectedPaymentMethod) this.setTransactionFee();
        await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
    }

    async validateCashMethods(isValid: boolean) {
        if (isValid) await this.checkForVerification(this.user, this.usdAmount, this.selectedPaymentMethod?.paymentMethod);
        else this.payEnabled = false;
    }

    async trackingNumberValidChanged(newValue: boolean) {
        await this.validateCashMethods(newValue);
    }

    async meetupAddressValidChanged(newValue: boolean) {
        await this.validateCashMethods(newValue);
    }

    @computedFrom('usdAdmount', 'selectedPaymentMethod', 'payEnabled', 'veriffStep')
    get disabledPayButton() {
        return !this.usdAmount || !this.selectedPaymentMethod || (!this.payEnabled && !this.veriffStep);
    }

    getTooltipText(title: string, message: string) {
        const data = {
            title: title,
            message: '',
            toastType: ToastType.TOOLTIP
        };
        if (!this.user) return data;
        data.message = `All balances are stored as USD, so the amount in other currencies will fluctuate. ${message}`;
        return data;
    }

    @computedFrom('user', 'user.totalBalance', 'user.pendingBalance', 'getTotalBalance')
    get tooltipText() {
        const message = `The balance available, which includes the pending balance converted to USD is $${this.getTotalBalance()}.`;
        return this.getTooltipText('Balance available', message);
    }

    @computedFrom('user', 'user.pendingBalance')
    get balancePendingTooltipText() {
        const message = `\n\n$${this.user.pendingBalance} USD can only be withdrawn immediately through PayPal due to the sale of an account(s). After six months your pending balance will retroactively be added to your regular cash balance where you can withdraw in any method.\n\nFor users who have a profile spend of 10k or more with level 3 verification, this restriction is removed.`;
        return this.getTooltipText('Balance pending', message);
    }

    @computedFrom('usdAmount', 'fees')
    get totalWithFees() {
        return this.usdAmount + this.fees;
    }

    @computedFrom('conversionRate')
    get invertedRate() {
        return 1 / this.conversionRate;
    }

    @computedFrom('smallOrderFee', 'transactionFee')
    get fees() {
        return this.smallOrderFee + this.transactionFee;
    }

    @computedFrom('amount', 'convertedBalance')
    get buttonText() {
        return this.getButtonText();
    }

    async getButtonText() {
        return 'Top Up • ' + await this.currencyFormatValueConverter.toViewFiatOnly(this.amount || 0, null, null, true);
    }

    async checkForStartVerification() {
        if (this.user.idVerified) return false;

        const amountRequiresVeriff = await this.requiresVeriff(this.user, this.selectedPaymentMethod.paymentMethod, this.usdAmount);
        if (amountRequiresVeriff) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in Top Up', reason: 'Thresholds' });
            return true;
        }

        if (this.veriffStep) {
            return true;
        }

        if (this.sessionService.checkVeriffBlacklistUser()) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in Top Up', reason: 'User Blacklisted' });
            return true;
        }

        return false;
    }
}
