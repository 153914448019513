import './modern-slavery-statement.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/helpers/helper';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
@autoinject()
export class ModernSlaveryStatement {
    pageLoading = true;
    pageLoadedSubscriber: Subscription;

    constructor (private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService, private helpers: Helper, private eventAggregator: EventAggregator) {}


    async attached() {
        try {
            this.helpers.addLoadingComponent('modern');
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Modern Slavery Statement')?.id);
            this.handleSubscriptions();
        } finally {
            this.helpers.validateLoading('modern'); }
    }

    detached() {
        this.pageLoadedSubscriber?.dispose();
    }

    handleSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }
}
