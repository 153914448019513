import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import './cx-footer-menu.scss';

import { autoinject, bindable, observable } from 'aurelia-framework';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { LanguageRouteValueConverter } from 'resources/value-converters/language-route';
import { SessionService } from 'services/session-service';

@autoinject()
export class CxFooterMenu {
    @bindable link;
    @bindable showFilter: boolean;
    @observable keyword: string;
    tradePageParams: { give: string, receive: string, exchangeType: string, method: string };
    filteredItems;
    openMenu: boolean;

    typeaheadController = {
        active: true, onKeydown: () => { }
    };

    constructor(
        private router: Router,
        private currencyService: CurrencyService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private languageRouteValueConverter: LanguageRouteValueConverter
    ) {
        this.tradePageParams = { give: undefined, receive: undefined, exchangeType: undefined, method: undefined };
    }

    bind() {
        this.link.menuItems.forEach(async item => {
            item.route = await this.getMenuItemRoute(item);
        });
        this.filteredItems = this.link.menuItems;
    }

    keywordChanged() {
        this.filteredItems = this.link.menuItems.filter(i => i.name.toLowerCase().includes(this.keyword.toLowerCase()));
    }

    async getMenuItemRoute(item) {
        if (item.routeName)
            return item.routeName;
        else return await this.getValidRouteFragmentFromSlug(item);
    }

    async navigateToRoute(item) {
        if (item.routeName)
            this.router.navigate(await this.languageRouteValueConverter.toView(item.routeName));
        else {
            const route = await this.getValidRouteFragmentFromSlug(item);
            this.router.navigate(await this.languageRouteValueConverter.toView(route));
            if (this.isCurrentRouteTrade())
                setTimeout(() => {
                    this.eventAggregator.publish('new-order-from-navbar', this.tradePageParams);
                }, 200);
        }
    }

    private isCurrentRouteTrade() {
        return this.router.currentInstruction.config.name.includes('trade');
    }

    async getValidRouteFragmentFromSlug(item) {
        const slug = item.slug[0];
        const startsWith = slug.split('-')[0];
        const currencyCode = item.currency?.code;
        const { give, receive } = this.getGiveAndReceiveFromSlug(slug);

        if (this.router.routes.find(route => route.route.includes(slug)))
            return slug;
        if (give && receive) {
            const giveType = this.getCurrencyByCode(give)?.type;
            const receiveType = this.getCurrencyByCode(receive)?.type;
            if (giveType === 'F' && receiveType === 'C')
                return `/buy/${give}-to-${receive}`;
            else if (giveType === 'C' && receiveType === 'F')
                return `/sell/${give}-to-${receive}`;
            else if (giveType === 'C' && receiveType === 'C')
                return `/swap/crypto/${give}-to-${receive}`;
            else if (giveType === 'F' && receiveType === 'F')
                return `/swap/fiat/${give}-to-${receive}`;
        }

        if (startsWith === 'buy') {
            this.tradePageParams.give = this.getCurrencyByCode('USD')?.reference;
            this.tradePageParams.receive = this.getCurrencyByCode(currencyCode)?.reference;
            this.tradePageParams.exchangeType = 'B';
            if (currencyCode)
                return `/buy/${currencyCode.toLowerCase()}`;
            else
                return 'buy';
        }
        else if (startsWith === 'sell') {
            this.tradePageParams.give = this.getCurrencyByCode(currencyCode)?.reference;
            this.tradePageParams.receive = this.getCurrencyByCode('USD')?.reference;
            this.tradePageParams.exchangeType = 'S';
            if (currencyCode)
                return `/sell/${currencyCode.toLowerCase()}`;
            else
                return 'sell';
        }
        else if ((await this.currencyService.getCurrencyById(item?.giveExchangeCurrencyId))?.type === 'F') {
            this.tradePageParams.give = (await this.currencyService.getCurrencyById(item?.giveExchangeCurrencyId))?.reference;
            this.tradePageParams.receive = (await this.currencyService.getCurrencyById(item?.receiveExchangeCurrencyId))?.reference;
            this.tradePageParams.exchangeType = 'FF';
            return `/swap/fiat/${slug}`;
        }
        else if ((await this.currencyService.getCurrencyById(item?.giveExchangeCurrencyId))?.type === 'C') {
            this.tradePageParams.give = (await this.currencyService.getCurrencyById(item?.giveExchangeCurrencyId))?.reference;
            this.tradePageParams.receive = (await this.currencyService.getCurrencyById(item?.receiveExchangeCurrencyId))?.reference;
            this.tradePageParams.exchangeType = 'CC';
            return `/swap/crypto/${slug}`;
        }
        else
            return 'trade';
    }

    getCurrencyByCode(code: string): Currency {
        return this.sessionService.getActiveCurrencies().find(c => c.code?.toLowerCase() === code?.toLowerCase());
    }

    getGiveAndReceiveFromSlug(slug: string) {
        return {
            give: slug.split('-to-')[0],
            receive: slug.split('-to-')[1]
        };
    }

    menuClosing() {
        this.openMenu = false;
        setTimeout(() => {
            this.keyword = '';
        }, 300);
    }
}
