// Module
var code = `<template>
    <section id="complaints-policy" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_TITLE'" class="title-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_LAST_UPDATED'" class="text-end last-update text-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_OUR_COMMITMENT_TITLE'" class="text-title font-bold title-2" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_OUR_COMMITMENT_CONTENT'" class="text-content text-content-1" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_HOW_TO_FILE_COMPLAINT_TITLE'" class="text-title font-bold title-3" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_HOW_TO_FILE_COMPLAINT_CONTENT'" class="text-content text-content-2" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_COMPLAINT_HANDLING_PROCESS_TITLE'" class="text-title font-bold title-4" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_COMPLAINT_HANDLING_PROCESS_CONTENT'" class="text-content text-content-3" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_RESOLUTION_TIMEFRAME_TITLE'" class="text-title font-bold title-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_RESOLUTION_TIMEFRAME_CONTENT'" class="text-content text-content-4" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_ESCALATION_PROCESS_TITLE'" class="text-title font-bold title-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_ESCALATION_PROCESS_CONTENT'" class="text-content text-content-4" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_CONTINUOUS_IMPROVEMENT_TITLE'" class="text-title font-bold title-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_CONTINUOUS_IMPROVEMENT_CONTENT'" class="text-content text-content-4" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_REGULATORY_COMPLIANCE_TITLE'" class="text-title font-bold title-5" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'COMPLAINTS_POLICY_REGULATORY_COMPLIANCE_CONTENT'" class="text-content text-content-4" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;