import './cx-seo-content.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SeoContentItem, SeoContentListItem, SeoContentQuadCard, SeoContentSection } from 'services/models/seo-content/seo-content';
import { AdminViewUpdatedEvent, DesktopBreakpoint, PhoneBreakpoint, SizeChangedEvent } from 'resources/constants';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class CxSeoContent {
    viewingAsAdmin: boolean = false;
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService
    ) {}

    @bindable mainSuffix: string;
    @bindable alternativeSuffix: string;
    pageLoading: boolean = true;
    listContent: SeoContentListItem[] = [];
    infoQuadCardsData: SeoContentQuadCard[] = [];
    contentSections: SeoContentSection[] = [];
    width: number;
    deviceType: string = 'DESKTOP';

    async attached() {
        try {
            this.helper.addLoadingComponent('cx-seo-content');
            this.width = this.helper.getWidth();
            this.helper.widthHandler(this);
            this.setCurrentSize();
            this.handleEventSubscriptions();
            this.buildContent();
        } catch (error) {
            console.error(error);
        } finally {
            this.helper.validateLoading('cx-seo-content');
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        const events = {
            'page-loaded': () => this.pageLoading = false,
            [SizeChangedEvent]: () => {
                this.width = this.helper.getWidth();
                const oldDeviceType = this.deviceType;
                this.setCurrentSize();
                this.contentSections.forEach(section => {
                    section.children.forEach(child => {
                        if (this.hasOnlyImage(child)) {
                            child.setProp('image', `${child.image.replace(oldDeviceType, '')}${this.deviceType}`);
                        }
                    });
                });
            },
            [AdminViewUpdatedEvent]: payload => this.viewingAsAdmin = payload.bool
        };

        this.helper.subscribeEvents(this, events);
    }

    buildContent() {
        this.listContent = this.generateContentListItems('LC', 3);
        this.infoQuadCardsData = this.generateContentQuadCards('QC', 4);
        this.buildSections();
    }

    buildSections() {
        this.contentSections = [
            new SeoContentSection([
                new SeoContentItem().setProp('title', `${this.mainSuffix}_FIRST_PARAGRAPH`)
                    .setProp('paragraph', `${this.mainSuffix}_FIRST_PARAGRAPH_BODY`),
                new SeoContentItem().setProp('displayListContent', true),
                new SeoContentItem().setProp('image', `${this.mainSuffix}_FIRST_BANNER_IMAGE_`)
            ]),
            new SeoContentSection([
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_SECOND_PARAGRAPH_BODY`)
                    .setProp('image', `${this.mainSuffix}_SECOND_PARAGRAPH_IMAGE`)
                    .setProp('title', `${this.mainSuffix}_SECOND_PARAGRAPH`),
            ]),
            new SeoContentSection([
                new SeoContentItem()
                    .setProp('imageFirst', true)
                    .setProp('title', `${this.mainSuffix}_THIRD_PARAGRAPH`)
                    .setProp('paragraph', `${this.mainSuffix}_THIRD_PARAGRAPH_BODY`)
                    .setProp('image', `${this.mainSuffix}_THIRD_PARAGRAPH_IMAGE`)
            ]),
            new SeoContentSection([
                new SeoContentItem().setProp('image', `${this.mainSuffix}_SECOND_BANNER_IMAGE_`),
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_FOURTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_FOURTH_PARAGRAPH`),
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_FIFTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_FIFTH_PARAGRAPH`),
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_SIXTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_SIXTH_PARAGRAPH`),
            ]),
            new SeoContentSection([
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_SEVENTH_PARAGRAPH_BODY`)
                    .setProp('image', `${this.mainSuffix}_SEVENTH_PARAGRAPH_IMAGE`)
                    .setProp('title', `${this.mainSuffix}_SEVENTH_PARAGRAPH`),
            ]),
            new SeoContentSection([
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_EIGHTH_PARAGRAPH_BODY`)
                    .setProp('image', `${this.mainSuffix}_EIGHTH_PARAGRAPH_IMAGE`)
                    .setProp('imageFirst', true)
                    .setProp('title', `${this.mainSuffix}_EIGHTH_PARAGRAPH`),
            ]),
            new SeoContentSection([
                new SeoContentItem().setProp('image', `${this.mainSuffix}_THIRD_BANNER_IMAGE_`),
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_NINTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_NINTH_PARAGRAPH`),
                new SeoContentItem()
                    .setProp('paragraph', `${this.mainSuffix}_TENTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_TENTH_PARAGRAPH`),
            ]),
            new SeoContentSection([
                new SeoContentItem()
                    .setProp('displayQuadContent', true)
                    .setProp('paragraph', `${this.mainSuffix}_ELEVENTH_PARAGRAPH_BODY`)
                    .setProp('title', `${this.mainSuffix}_ELEVENTH_PARAGRAPH`),
                new SeoContentItem().setProp('image', `${this.mainSuffix}_FOURTH_BANNER_IMAGE_`)
            ]),
        ];

        this.contentSections.forEach(section => {
            section.children.forEach(child => {
                if (this.hasOnlyImage(child)) child.setProp('image', `${child.image}${this.deviceType}`);
            });
        });
    }

    generateContentListItems(prefix: string, count: number): SeoContentListItem[] {
        const items = [];
        for (let i = 1; i <= count; i++) {
            items.push({
                iconPath: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_IMAGE`,
                header: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_TITLE`,
                body: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_PARAGRAPH`,
                alternativeIconPath: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_IMAGE` : undefined,
                alternativeHeader: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_TITLE` : undefined,
                alternativeBody: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_PARAGRAPH` : undefined
            });
        }
        return items;
    }

    generateContentQuadCards(prefix: string, count: number): SeoContentQuadCard[] {
        const items = [];
        for (let i = 1; i <= count; i++) {
            items.push({
                iconPath: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_IMAGE`,
                title: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_TITLE`,
                text: `${this.mainSuffix}_${prefix}_${this.getOrdinal(i)}_PARAGRAPH`,
                alternativeIconPath: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_IMAGE` : undefined,
                alternativeTitle: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_TITLE` : undefined,
                alternativeText: this.alternativeSuffix ? `${this.alternativeSuffix}_${prefix}_${this.getOrdinal(i)}_PARAGRAPH` : undefined
            });
        }
        return items;
    }

    getOrdinal(n: number): string {
        switch (n) {
            case 1: return 'FIRST';
            case 2: return 'SECOND';
            case 3: return 'THIRD';
            case 4: return 'FOURTH';
            default: return '';
        }
    }

    setCurrentSize(): void {
        this.deviceType = this.width <= PhoneBreakpoint ? 'MOBILE' : this.width <= DesktopBreakpoint ? 'TABLET' : 'DESKTOP';
    }

    hasOnlyImage(contentItem: SeoContentItem): boolean {
        return !!contentItem.image && !this.hasCustomProperties(contentItem) && Object.keys(contentItem).every(key => key === 'image' || key === 'customProperties' || contentItem[key] === undefined);
    }

    hasImageAndText(child: SeoContentItem): boolean {
        return !!child.image && !!child.title && !!child.paragraph && !this.hasCustomProperties(child);
    }

    hasOnlyText(seoContentItem: SeoContentItem): boolean {
        return !seoContentItem.image && !!seoContentItem.title && !!seoContentItem.paragraph && !this.hasCustomProperties(seoContentItem);
    }

    hasCustomProperties(contentItem: SeoContentItem): boolean {
        return Object.keys(contentItem.getCustomProperties()).length > 0;
    }

    async shouldRenderContent(keys: string | string[]): Promise<boolean> {
        if (!keys || (Array.isArray(keys) && keys.every(k => !k))) return false;
        keys = Array.isArray(keys) ? keys : [keys];
        const contentPromises = keys.map(async key => {
            const resolvedKey = key.includes(this.mainSuffix) ? key : `${this.mainSuffix}${key}`;
            const defaultKey = resolvedKey.replace(this.mainSuffix, this.alternativeSuffix);
            const foundContent = await this.pageContentAreaService.getByKey(resolvedKey)
                    || await this.pageContentAreaService.getByKey(defaultKey);
            const isContentImage = (resolvedKey.includes('IMAGE') || defaultKey.includes('IMAGE')) && foundContent?.imagePath;

            return foundContent && (foundContent?.markup || isContentImage);
        });

        const contentResults = await Promise.all(contentPromises);
        return contentResults.every(result => result);
    }

    async shouldRenderSection(section: SeoContentSection): Promise<boolean> {
        const sectionList = [];
        for (const child of section.children) {
            const result = await this.shouldRenderChild(child);
            sectionList.push(result);
        }
        return sectionList.some(s => s);
    }

    async shouldRenderChild(child: SeoContentItem): Promise<boolean> {
        const contentKeys = Object.keys(child).filter(key => key !== 'customProperties' && key !== 'imageFirst' && child[key])?.map(key => child[key]);
        let result = false;

        if (contentKeys.length > 0) {
            result = await this.shouldRenderContent(contentKeys);
        }

        if (child.getProp('displayQuadContent')) {
            result = result || await this.shouldRenderQuadContent();
        }

        if (child.getProp('displayListContent')) {
            result = result || await this.shouldRenderListContent();
        }

        return result;
    }

    async shouldRenderQuadContent(): Promise<boolean> {
        const quadKeys = Object.keys(this.infoQuadCardsData).flatMap(key =>
            Object.keys(this.infoQuadCardsData[key])
                .map(k => this.infoQuadCardsData[key][k])
                .filter(k => k)
        );
        return await this.shouldRenderContent(quadKeys);
    }

    async shouldRenderListContent(): Promise<boolean> {
        const listKeys = Object.keys(this.listContent).flatMap(key =>
            Object.keys(this.listContent[key])
                .map(k => this.listContent[key][k])
                .filter(k => k)
        );
        return await this.shouldRenderContent(listKeys);
    }

    async shouldRenderNextSection(index: number, isLast: boolean): Promise<boolean> {
        if (index + 1 >= this.contentSections.length || isLast) return false;

        return this.shouldRenderSection(this.contentSections[index + 1]);
    }
}
