export class DynamicButton {
    id: string;
    buttonText: string;
    buttonLink: string;
    display: string;

    /**
     * @param {string} id
     * @param {string} buttonText
     * @param {string} buttonLink
     * @param {string} display
     */
    constructor(id: string, buttonText: string, buttonLink: string, display: string = 'inline') {
        this.id = id;
        this.buttonText = buttonText;
        this.buttonLink = buttonLink;
        this.display = display;
    }

    buildHtml() {
        const buttonStyle = this.display ? `${this.display}-button` : '';
        return `
        <button id="button-${this.id}" type="button" onclick="location.href='${this.buttonLink}';" 
         class="button-blue-filled mdc-button--outlined ${buttonStyle}">
           <span>${this.buttonText}</span> 
        </button>
        `;
    }
}
