// Module
var code = `<template>
    <section id="cx-seo-content" class="container">
        <cx-seo-dropdown is-loading.bind="pageLoading">
            <section repeat.for="section of contentSections" class="content-sections" if.bind="viewingAsAdmin || shouldRenderSection(section) & async">
                <div repeat.for="child of section.children">
                    <div if.bind="child.getProp('displayQuadContent')" class="content-sections__quad-cards-container">
                        <div class="cards">
                            <div
                                repeat.for="quadCard of infoQuadCardsData"
                                class="quad-card"
                                if.bind="viewingAsAdmin || shouldRenderContent([quadCard.title, quadCard.text, quadCard.iconPath]) & async"
                            >
                                <cx-page-content-image
                                    key.bind="quadCard.iconPath"
                                    class="qc-img d-flex justify-content-center"
                                ></cx-page-content-image>
                                <div class="card-content">                                    
                                    <cx-paragraph-title
                                        caption.bind="quadCard.title"
                                        text.bind="quadCard.text"
                                        caption-style="quad-card__title"
                                        text-style="quad-card__body"
                                    ></cx-paragraph-title>
                                </div>
                            </div>
                        </div>
                        <cx-paragraph-title
                            caption.bind="child.title"
                            text.bind="child.paragraph"
                            caption-style="paragraph-image__title"
                            text-style="paragraph-image__body"
                            if.bind="viewingAsAdmin || shouldRenderContent([child.title, child.paragraph]) & async"
                        ></cx-paragraph-title>
                    </div>                    
                    <div if.bind="child.getProp('displayListContent')" class="list-content">
                        <article repeat.for="listItem of listContent" class="list-content__item">
                            <div class="list-content__icon">
                                <cx-page-content-image
                                    class="icon"
                                    key.bind="listItem.iconPath"
                                    alternative-key.bind="listItem.alternativeIconPath"
                                    if.bind="viewingAsAdmin || shouldRenderContent([listItem.iconPath]) & async"
                                ></cx-page-content-image>
                            </div>
                            <div>
                                <cx-paragraph-title
                                    caption.bind="listItem.header"
                                    text.bind="listItem.body"
                                    caption-style="list-content-title"
                                    text-style="list-content-body"
                                    skeleton-loading="is-loading.bind:pageLoading;"
                                    if.bind="viewingAsAdmin || shouldRenderContent([listItem.header, listItem.body]) & async"
                                ></cx-paragraph-title>
                            </div>
                        </article>
                    </div>
                    <div if.bind="hasOnlyImage(child)">
                        <cx-page-content-image
                            if.bind="viewingAsAdmin || shouldRenderContent([child.image]) & async"
                            key.bind="child.image"
                            class="content-sections__banner"
                        ></cx-page-content-image>
                    </div>
                    <div if.bind="hasImageAndText(child)" class="content-sections__paragraph-image \${ child.imageFirst ? 'image-first' : ''}">
                        <cx-paragraph-title
                            caption.bind="child.title"
                            text.bind="child.paragraph"
                            caption-style="paragraph-image__title"
                            text-style="paragraph-image__body"
                            if.bind="viewingAsAdmin || shouldRenderContent([child.title, child.paragraph]) & async"
                        ></cx-paragraph-title>
                        <cx-page-content-image
                            key.bind="child.image"
                            class="banner"
                            if.bind="viewingAsAdmin || shouldRenderContent([child.image]) & async"
                        ></cx-page-content-image>
                    </div>
                    <div if.bind="hasOnlyText(child)">
                        <cx-paragraph-title
                            if.bind="viewingAsAdmin || shouldRenderContent([child.title, child.paragraph]) & async"
                            caption.bind="child.title"
                            text.bind="child.paragraph"
                            caption-style="paragraph-title"
                            text-style="paragraph-body"
                        ></cx-paragraph-title>
                    </div>
                </div>
                <hr if.bind="viewingAsAdmin && !\$last || shouldRenderNextSection(\$index, \$last) & async">
            </section>
        </cx-seo-dropdown>
    </section>
</template>
`;
// Exports
export default code;