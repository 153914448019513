import './cx-payment-method-selector.scss';
import { autoinject, bindable, computedFrom, observable } from 'aurelia-framework';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { getAWSBucketEndpoint } from 'environment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { DefaultCurrencyValueConverter } from 'resources/value-converters/default-currency';
import { Router } from 'aurelia-router';
import { BillingAddress } from 'services/models/user/billingAddress';
import { Country } from 'country-state-city';
import { NuveiService } from 'services/nuvei-service';
import { Helper } from 'resources/helpers/helper';
import { CreditCardHelper, StagesCardForm } from 'resources/helpers/credit-card-helper';
import { ToastService } from 'services/toast-service';
import moment from 'moment';
import {
    Currency,
    PaymentMethodThreshold,
    PaymentMethodWebsite,
    ThresholdOperationType,
    VerificationType,
    NuveiEditCreditCard,
    RecentlySavedCC,
    PaymentMethod,
    CardInfo
} from 'services/models/purchase-flow/exchange';
import { User } from 'services/models/user/user';
import { CurrencyService } from 'services/currency-service';
import { GameForNav } from 'services/models/game/productCategory';
import { deepCopyObj } from 'resources/helpers/objects-helper';
import { ForcePaymentMethodEvent, MethodPayoutableChangedEvent, SpecialSelectorEvent, AdminViewUpdatedEvent } from 'resources/constants';
import { ReceivingPaymentMethodService } from 'services/receiving-payment-method-service';
import { IForcePaymentMethodEvent, IMethodPayoutableUpdated, ISpecialSelectorEvent } from 'types/events';
import { SignalrService } from 'services/signalr-service';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';

@autoinject()
export class CxPaymentMethodSelector {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private currencyService: CurrencyService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private router: Router,
        private defaultCurrencyValueConverter: DefaultCurrencyValueConverter,
        private nuveiService: NuveiService,
        private helper: Helper,
        private creditCardHelper: CreditCardHelper,
        private toastService: ToastService,
        private receivingPMService: ReceivingPaymentMethodService,
        private signalRService: SignalrService,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');
        this.id = Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable givenCurrencySelected: Currency;
    @bindable receivingCurrencySelected: Currency;
    @bindable showCryptoPaymentMethod: boolean;
    @bindable home;
    @bindable loading: boolean;
    @bindable cardNumber: string;
    @bindable cardMonthYear:string;
    @bindable inputClass: string = '';
    @bindable cardType: string;
    @bindable billing: BillingAddress;
    @bindable @observable stageCardForm: number = StagesCardForm.INACTIVE;
    @bindable ccRecentlySaved: RecentlySavedCC;
    @bindable game: GameForNav;
    @bindable ignoreRoute: boolean = false;
    @bindable exchangeType: 'B' | 'S' | 'FF' | 'CC';
    @bindable isCartPage: boolean = false;
    @bindable payoutableOnly = false;
    @bindable autoSelect = true;
    @bindable placeholder?: string;
    @bindable outlined = true;
    @bindable renewalMethodId: number;
    @bindable renewalCardLastDigits: string;
    @bindable suffix: string;
    @bindable ignoreSelectedCurrencies: boolean = false;
    @bindable dropdownWidth: number;

    @observable isCvvValid: boolean;

    userVaultedCards = [];
    cardCvv: string;
    recentPaymentMethods: PaymentMethodWebsite[];
    paymentMethods: PaymentMethodWebsite[];
    baseAwsEndpoint: string;
    allPaymentMethods: PaymentMethodWebsite[];
    displayPaymentMethods: PaymentMethodWebsite[];
    recentThreePaymentMethods: PaymentMethodWebsite[];
    thresholds: PaymentMethodThreshold[];
    activeCurrencies: Currency[];
    search = '';
    customerCountry = '';
    parent;
    user: User;
    forcePaymentMethodSubscriber: Subscription;
    showCheckoutContainer = false;
    showAllRecent = false;
    buttonText: string;
    cardInfoToForm: CardInfo;
    isDeletingPaymentMethod = false;
    paymentSelectorElement: HTMLButtonElement;
    isExpDateValid: boolean;
    billingAddressIsValid: boolean;
    nameOnCard: { firstName: string, lastName:string };
    isEditingPaymentMethod = false;
    isLoading: boolean = true;
    viewingAsAdmin: boolean;
    id: string = '';
    resultsFound = true;
    firstResultIndex = 0;
    lastResultIndex = 0;
    triggerPaymentMethodListener = false;
    input;
    focusIn = false;
    route: string | string[];
    userSubscriber: Subscription;
    togglePaymentMethods: Subscription;
    showPayoutMethodForm = false;
    methodPayoutableSubscriber: Subscription;
    editButtonClicked = false;
    specialSelectorListener: Subscription;
    pageLoadedSubscriber: Subscription;
    adminViewUpdatedSubscriber: Subscription;
    formLock = false;
    connection;
    blacklistEvent = 'BlacklistResolution';
    blacklistedUser = false;


    async attached() {
        this.handlePageLoadedEventSubscriptions();
        this.setSuffix();

        this.connection = await this.signalRService.getSignalRConnection();
        this.route = this.getRoute();
        this.allPaymentMethods = await this.paymentMethodWebsiteService.getByWebsite(this.payoutableOnly);
        this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
        this.thresholds = await this.paymentMethodWebsiteService.getThresholds();

        await this.initializeSignalRConnection();

        this.user = await this.sessionService.getProfile();

        if (this.user && await this.checkUserInBlackList()) {
            return;
        }

        await this.getRecentPaymentMethods();

        await this.postInitializationTasks();
    }

    private getRoute(): string | string[] {
        return this.router.currentInstruction.parentInstruction?.config.route || this.router.currentInstruction.config.route;
    }

    private async initializeSignalRConnection() {
        this.signalRService.getSignalRConnection().then((connection) => {
            if (connection) {
                this.connection = connection;
                this.connection.on(this.blacklistEvent, async () => {
                    await this.checkUserInBlackList();
                });
            }
        });
    }

    private async postInitializationTasks() {
        this.removeBitcoinFromPaymentMethods();
        this.customerCountry = this.sessionService.getCountry();
        this.handlePaymentMethodSelectorFocusOutEvent();
        this.filterPaymentMethodsByCurrencySelected();
        this.paymentMethods.forEach(x => x.show = true);
        this.setShowCheckoutContainer();

        if (this.showCheckoutContainer) {
            this.stageCardForm = this.setStageCardForm();
            this.paymentSelectorElement.click();
        }

        this.setSuffix();
        this.handleEventSubscriptions();

        const previousPaymentMethod = this.getReceivingPaymentMethod();
        const foundPaymentMethod = this.allPaymentMethods.find(x => x.paymentMethodId === previousPaymentMethod?.paymentMethodId);
        if (this.autoSelect && !this.selectedPaymentMethod && foundPaymentMethod && !this.game?.giveExchangeCurrencyId) {
            this.eventAggregator.publish(ForcePaymentMethodEvent, { paymentMethodReference: foundPaymentMethod.paymentMethod.reference } as IForcePaymentMethodEvent);
        }

        if (!this.selectedPaymentMethod?.paymentMethod?.cardInfo?.cardId && this.selectedPaymentMethod?.paymentMethod?.cardInfo?.reference) {
            this.stageCardForm = StagesCardForm.INACTIVE;
            this.selectPaymentMethod(this.allPaymentMethods.find(pm => pm.paymentMethod.reference === this.selectedPaymentMethod.paymentMethod.cardInfo.reference));
        }

        if (this.selectedPaymentMethod) {
            this.selectPaymentMethod(this.selectedPaymentMethod);
        }

        if (this.recentPaymentMethods?.some(rpm => rpm.paymentMethod.usesCard)) {
            this.getCreditCards().then(cards => {
                this.userVaultedCards = cards;
                this.syncDisplayPaymentMethods();
            });
        } else {
            this.syncDisplayPaymentMethods();
        }

        if (this.renewalMethodId) {
            this.selectRenewalMethod();
        }

        this.handleTextFieldFocusOut();
        this.handlePaymentSelectorEvents();
        this.isLoading = false;
    }

    async getCreditCards() {
        if (!this.user) return;
        let vaultedCards = [];

        if (this.recentPaymentMethods?.some(rpm => rpm.paymentMethod.reference === 'nuvei')) {
            vaultedCards = [...await this.getNuveiVaultedCards()];
        }

        return vaultedCards;
    }

    handleEventSubscriptions () {
        this.forcePaymentMethodSubscriber = this.eventAggregator.subscribe(ForcePaymentMethodEvent, async (payload: IForcePaymentMethodEvent) => {
            if (payload.paymentMethodReference) {
                if (payload.paymentMethodReference === 'bitcoin-coinpayments') payload.paymentMethodReference = 'bitcoin-btcpay';
                this.selectPaymentMethod(this.allPaymentMethods.find(pm => pm.paymentMethod.reference === payload.paymentMethodReference));
            }
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload: { user: User }) => {
            this.user = payload.user;
            if (this.user) {
                this.setShowCheckoutContainer();
                this.selectPaymentMethod(this.selectedPaymentMethod);
                await this.getRecentPaymentMethods();
                if (this.recentPaymentMethods?.some(rpm => rpm.paymentMethod.usesCard)) {
                    this.userVaultedCards = await this.getCreditCards();
                }
                this.syncDisplayPaymentMethods();
            }
        });

        this.methodPayoutableSubscriber = this.eventAggregator.subscribe(MethodPayoutableChangedEvent, async(payload: IMethodPayoutableUpdated) => {
            this.user = await this.sessionService.fetchProfileData(true);
            this.user.receivingPaymentMethods.forEach(x => {
                if (x.paymentMethodId !== payload.paymentMethod.id) return;
                x.address = payload.paymentMethod.payoutableInfo?.address;
                x.code = payload.paymentMethod.payoutableInfo?.code;
                x.additionalInformation = payload.paymentMethod.payoutableInfo?.additionalInformation;
            });
            this.syncDisplayPaymentMethods();
            this.paymentSelectorElement.click();
        });

        this.togglePaymentMethods = this.eventAggregator.subscribe('toggle-payment-methods', async payload => {
            const state = payload.state;
            if (state !== null && state !== undefined) {
                await this.toggleAllRecent(state);
            }
        });

        this.specialSelectorListener = this.eventAggregator.subscribe(SpecialSelectorEvent, (payload: ISpecialSelectorEvent) => {
            this.formLock = payload.focused;
        });
    }

    handlePageLoadedEventSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.isLoading = false;
        });

        this.adminViewUpdatedSubscriber = this.eventAggregator.subscribe(AdminViewUpdatedEvent, (payload) => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    clearPayoutableInfo() {
        this.displayPaymentMethods?.forEach(x => x.paymentMethod.payoutableInfo = null);
    }

    clearCvvCardInfo() {
        this.recentPaymentMethods?.forEach(pm => { if (pm.paymentMethod.cardInfo?.cvv) pm.paymentMethod.cardInfo.cvv = null;});
    }

    async getRecentPaymentMethods() {
        this.recentPaymentMethods = await this.paymentMethodWebsiteService.getRecentPaymentMethodByUserId();
        this.recentPaymentMethods?.forEach(x => {
            x.supportedCurrencies = this.allPaymentMethods.find(p => p.paymentMethodId === x.paymentMethodId)?.supportedCurrencies;
        });
        this.removeBitcoinFromPaymentMethods();
    }

    detached() {
        this.forcePaymentMethodSubscriber?.dispose();
        this.methodPayoutableSubscriber?.dispose();
        this.specialSelectorListener?.dispose();
        this.userSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.adminViewUpdatedSubscriber?.dispose();
        this.clearPayoutableInfo();
        this.clearCvvCardInfo();
        this.paymentSelectorElement?.removeEventListener('hide.bs.dropdown', () => {}, true);
        this.paymentSelectorElement?.removeEventListener('show.bs.dropdown', () => {}, true);
    }

    handlePaymentMethodSelectorFocusOutEvent() {
        const element = document.getElementById(`payment-selector-${this.id}`);
        if (!element || this.triggerPaymentMethodListener) return;
        this.triggerPaymentMethodListener = true;
    }

    async getNuveiVaultedCards() {
        return await this.nuveiService.getVaultedCards();
    }

    setShowCheckoutContainer() {
        this.showCheckoutContainer = this.selectedPaymentMethod?.paymentMethod.usesCard && (this.ignoreRoute || this.route === 'cart') && !!this.user;
        this.showPayoutMethodForm = this.payoutableOnly && Boolean(this.selectedPaymentMethod?.paymentMethod) && !this.selectedPaymentMethod.paymentMethod.usesCard;

        if (!this.showPayoutMethodForm) return;

        if (this.selectedPaymentMethod.paymentMethod.payoutableInfo && !this.editButtonClicked) {
            this.showPayoutMethodForm = false;
            return;
        }

        this.paymentSelectorElement.click();
    }

    setStageCardForm() {
        if (!this.showCheckoutContainer) {
            return StagesCardForm.INACTIVE;
        }

        if (this.selectedPaymentMethod?.paymentMethod?.cardInfo?.type) {
            if (!this.selectedPaymentMethod?.paymentMethod?.cardInfo?.cvv) {
                return StagesCardForm.REQUESTING_CVV;
            }
            this.showCheckoutContainer = false;
            return StagesCardForm.INACTIVE;
        }
        return StagesCardForm.ADDING;
    }

    handlePaymentSelectorEvents() {
        this.paymentSelectorElement?.addEventListener('hide.bs.dropdown', async event => {
            if ((this.stageCardForm !== StagesCardForm.INACTIVE && this.stageCardForm !== StagesCardForm.DELETING) || this.formLock) {
                event.preventDefault();
                if (this.formLock) return;
            }

            this.showPayoutMethodForm = false;
            await this.toggleAllRecent(false);
            this.stageCardForm = StagesCardForm.INACTIVE;
            this.handleTextFieldFocusOut();
            this.clearSearch();
            this.input.blur();
            this.eventAggregator.publish('payment-method-selector-expanded', { expanded: false });
            this.input.label_.root.classList.remove('mdc-floating-label--float-above');
        });

        this.paymentSelectorElement?.addEventListener('show.bs.dropdown', () => this.eventAggregator.publish('payment-method-selector-expanded', { expanded: true }));
    }

    isCvvValidChanged(newValue) {
        if (newValue && this.stageCardForm === StagesCardForm.REQUESTING_CVV) {
            this.parent?.validateCryptoPayButton?.();
        }
    }

    /**
     * Resets the payment method selected and filter the paymentMethodList based on the given currency
     */
    filterPaymentMethodsByCurrencySelected() {
        if (this.givenCurrencySelected?.type === 'C' && this.receivingCurrencySelected?.type === 'C') {
            this.paymentMethods = [this.allPaymentMethods?.find(p => {
                return p.paymentMethod.reference === 'crypto-to-crypto';
            })];
        } else {
            const references = ['coinpayments', 'bitcart', 'btcpay'];
            this.paymentMethods = this.allPaymentMethods?.filter(p => {
                const isCryptoPaymentMethod = p.paymentMethod.reference.includesSome(references);

                if (this.givenCurrencySelected?.type === 'C' && this.receivingCurrencySelected?.type === 'F') {
                    return (this.route === 'cart' ? isCryptoPaymentMethod : !isCryptoPaymentMethod) && p.paymentMethod.reference !== 'crypto-to-crypto';
                }

                return ((this.showCryptoPaymentMethod ? isCryptoPaymentMethod : !isCryptoPaymentMethod) && p.paymentMethod.reference !== 'crypto-to-crypto') &&
                ((this.givenCurrencySelected?.type === 'C' ? p.payoutable : this.receivingCurrencySelected?.type === 'F' ? p.paymentMethod.reference === 'cash-in-person' : (p.live || this.payoutableOnly)) ||
                (this.givenCurrencySelected?.type === 'C' && this.receivingCurrencySelected?.type === 'C' && p.paymentMethod.reference === 'crypto-to-crypto'));
            });
        }
        this.syncDisplayPaymentMethods();
    }

    removeBitcoinFromPaymentMethods() {
        if (this.route !== 'cart') {
            this.allPaymentMethods = this.removeMethodFromPaymentMethods(this.allPaymentMethods, 'bitcoin');
            this.recentPaymentMethods = this.removeMethodFromPaymentMethods(this.recentPaymentMethods, 'bitcoin');
        } else {
            const paymentMethodsToRemove = ['bitcoin-coinpayments', 'bitcoin-cash-coinpayments'];
            this.allPaymentMethods = this.removeMethodFromPaymentMethods(this.allPaymentMethods, paymentMethodsToRemove);
            this.recentPaymentMethods = this.removeMethodFromPaymentMethods(this.recentPaymentMethods, paymentMethodsToRemove);

        }
    }

    resetPaymentMethod() {
        this.selectedPaymentMethod = null;
        this.input.input_.value = '';
    }

    removeMethodFromPaymentMethods(paymentMethodsList: PaymentMethodWebsite[], methodName: string | string[]) {
        if (typeof methodName !== 'string') {
            return paymentMethodsList?.filter(p => !methodName.includes(p.paymentMethod.reference));
        }
        return paymentMethodsList?.filter(p => !p.paymentMethod.reference.includes(methodName));
    }

    handleSearchChange(event: KeyboardEvent) {
        this.search = (<HTMLInputElement>event.target).value;
        if ((<HTMLInputElement>event?.target)?.value) {
            for (const method of this.paymentMethods) {
                method.show = method.paymentMethod.name.toLowerCase().includes(this.search.toLocaleLowerCase());
            }
            for (const method of this.displayPaymentMethods) {
                method.show = method.paymentMethod.name.toLowerCase().includes(this.search.toLocaleLowerCase()) || method.paymentMethod.cardInfo?.type?.toLowerCase().includes(this.search.toLocaleLowerCase());
            }
        }
        this.resultsFound = this.paymentMethods?.some(x => x.show);
        this.firstResultIndex = this.displayPaymentMethods?.findIndex(x => x.show);
        this.displayPaymentMethods?.forEach((x, i) => {
            if (x.show) this.lastResultIndex = i;
        });
        const dropdownMenu = document.getElementById(`payment-dropdown-menu-${this.id}`);
        dropdownMenu?.classList.add('show');
    }

    handleTextFieldFocusIn() {
        this.clearSearch();
        this.filterPaymentMethodsByCurrencySelected();
        this.input.focus();
        if (!this.search?.length) this.input.input_.value = '';
        this.focusIn = true;
    }

    handleTextFieldFocusOut() {
        this.focusIn = false;
        this.resultsFound = true;

        setTimeout(() => {
            if (!this.displayPaymentMethods?.find(x => x.paymentMethodId === this.selectedPaymentMethod?.paymentMethodId)) {
                if (this.givenCurrencySelected?.type === 'C' && this.receivingCurrencySelected?.type === 'C' && this.selectedPaymentMethod?.paymentMethod.reference === 'crypto-to-crypto') {
                    return;
                }
                if (this.givenCurrencySelected?.type === 'F' && this.receivingCurrencySelected?.type === 'F' && this.selectedPaymentMethod?.paymentMethod.reference === 'cash-in-person') {
                    return;
                }
                this.selectedPaymentMethod = null;
                this.suffix = null;
                this.input.input_.value = '';
                return;
            }}, 200);
        this.setSuffix();
    }

    setSuffix() {
        if (!this.selectedPaymentMethod || !this.input) return;
        this.input.input_.value = this.calculatePaymentMethodDisplayName(this.selectedPaymentMethod.paymentMethod);
    }

    calculatePaymentMethodDisplayName(paymentMethod: PaymentMethod) {
        let name = paymentMethod.name;
        if (paymentMethod.usesCard && paymentMethod.cardInfo?.type) name = `${this.handleCardName(paymentMethod.cardInfo.type)} **** ${paymentMethod.cardInfo.lastDigits}`;
        if (paymentMethod.payoutableInfo) name = paymentMethod.payoutableInfo.address;
        if (this.suffix) name = `${name} ${this.suffix}`;
        return name;
    }

    selectPaymentMethod(method: PaymentMethodWebsite) {
        if (!method || this.stageCardForm !== StagesCardForm.INACTIVE) {
            this.handleTextFieldFocusOut();
            return;
        }
        const selector = document.getElementById(`payment-selector-${this.id}`);
        if (selector) {
            selector.classList.remove('show');
            const dropdownMenu = document.getElementById(`payment-dropdown-menu-${this.id}`);
            dropdownMenu.classList.remove('show');
        }
        this.clearSearch();
        this.selectedPaymentMethod = { ...method };
        //Force currency based on payment
        let currencyToSent = '';
        if (method.supportedCurrencies?.length) {
            const currencies = method.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
            currencyToSent = this.defaultCurrencyValueConverter.toView(this.customerCountry);
            if (!currencies.includes(currencyToSent)) {
                currencyToSent = currencies[0];
            }
        } else if (this.givenCurrencySelected || this.receivingCurrencySelected) {
            currencyToSent = this.givenCurrencySelected?.type === 'F' ? this.givenCurrencySelected?.code : this.receivingCurrencySelected?.code;
        }
        this.eventAggregator.publish('force-currency', { currency: currencyToSent, oldCurrency: this.givenCurrencySelected?.type === 'F' ? this.givenCurrencySelected?.code : this.receivingCurrencySelected?.code, currentPaymentMethodSelected: method });
        if (this.route === 'cart') {
            this.parent.validateCryptoPayButton();
            this.parent.validateDeliveryOptions();
            this.parent.handleRequiresDelivery();
        }
        this.setShowCheckoutContainer();
        if (this.showCheckoutContainer) {
            this.stageCardForm = this.setStageCardForm();
            if (this.stageCardForm === StagesCardForm.ADDING) {
                this.billing = null;
                this.cardInfoToForm = undefined;
                this.paymentSelectorElement.click();
            }

            if (this.stageCardForm === StagesCardForm.REQUESTING_CVV) {
                this.cardInfoToForm = this.selectedPaymentMethod.paymentMethod.cardInfo;
                this.paymentSelectorElement.click();
            }
        } else {
            this.cardCvv = null;
        }
        this.handleTextFieldFocusOut();
    }

    selectedPaymentMethodChanged(newValue: PaymentMethodWebsite, oldValue: PaymentMethodWebsite) {
        this.setSuffix();

        if (newValue?.paymentMethod.reference !== oldValue?.paymentMethod.reference && (this.route === 'cart' || this.route === 'trade')) {
            this.parent.handlePaymentMethodSwitch(newValue, oldValue);
        }
    }

    clearSearch() {
        if (this.search === this.input?.input_.value) {
            this.input.input_.value = '';
        }
        this.search = null;
    }

    changeCryptoPaymentSelected(cryptoAbbreviation: string) {
        const paymentMethod = this.paymentMethods.find(p => p.paymentMethod.cryptoAbbreviation === cryptoAbbreviation);
        this.selectPaymentMethod(paymentMethod);
    }

    async checkUserInBlackList() {
        this.blacklistedUser = true;
        const banForPaymenthMethod = await this.sessionService.checkIfUserByPaymentMethodInBlackList(this.thresholds.filter(t => t.paymentMethodId === this.selectedPaymentMethod?.paymentMethodId));
        if (banForPaymenthMethod.paymentMethods) {
            this.paymentMethods = null;
            this.allPaymentMethods = null;
            this.selectedPaymentMethod = null;
            this.recentPaymentMethods = null;
            this.syncDisplayPaymentMethods();
            return true;
        }
        if (banForPaymenthMethod.filterPaymentMethod) {
            this.allPaymentMethods = this.allPaymentMethods.filter(this.checkPaymentMethodVerification);
            this.recentPaymentMethods = this.recentPaymentMethods?.filter(this.checkPaymentMethodVerification);
            this.selectedPaymentMethod = banForPaymenthMethod.selectedPaymentMethod ? null : this.selectedPaymentMethod;
            this.allPaymentMethods = this.allPaymentMethods?.filter(x => this.helper.includesSome(x.paymentMethod.reference, ['crypto', 'coinpayments', 'btcpay', 'bitcart']));
        } else {
            this.blacklistedUser = false;
            this.allPaymentMethods = await this.paymentMethodWebsiteService.getByWebsite(this.payoutableOnly);
        }
        this.syncDisplayPaymentMethods();
        return false;
    }

    handleCardImage(type) {
        return this.helper.getCardImageType(type);
    }

    handleCardName(type) {
        return this.helper.getCardName(type);
    }

    async toggleAllRecent (value: boolean) {
        if (this.stageCardForm !== StagesCardForm.INACTIVE) {
            if (this.stageCardForm === StagesCardForm.EDITING) {
                if ((this.route === 'trade' ? this.isExpDateValid : this.isCvvValid) && this.billingAddressIsValid) {
                    this.isEditingPaymentMethod = true;
                    const monthYear = this.cardMonthYear.split('/');
                    if (this.ccRecentlySaved && !this.cardInfoToForm?.cardId) {
                        this.ccRecentlySaved = this.createDataObject();
                        this.selectedPaymentMethod.paymentMethod.cardInfo = {
                            lastDigits: this.cardNumber.substring(this.cardNumber.length - 4),
                            type: this.cardType.toLocaleLowerCase(),
                            reference: this.selectedPaymentMethod.paymentMethod.reference,
                            cardId: null,
                            cardExpiry: {
                                month: parseInt(monthYear[0]),
                                year: parseInt(monthYear[1])
                            },
                            cvv: this.cardCvv
                        };
                    } else {
                        if (this.cardInfoToForm.reference === 'nuvei') {
                            let data = new NuveiEditCreditCard();
                            data = {
                                userPaymentOptionId: parseInt(this.cardInfoToForm.cardId),
                                ccExpMonth: monthYear[0],
                                ccExpYear: (parseInt(monthYear[1]) + 2000).toString(),
                                ccNameOnCard: `${this.nameOnCard.firstName} ${this.nameOnCard.lastName}`,
                                billingAddress: {
                                    firstName: this.nameOnCard.firstName,
                                    lastName: this.nameOnCard.lastName,
                                    address: this.billing.street,
                                    phone: this.user.phoneNumber,
                                    zip: this.billing.zip,
                                    city: this.billing.city,
                                    countryCode: this.billing.countryCode,
                                    state: this.billing.state,
                                    email: this.user.email,
                                    country: this.billing.countryName
                                }
                            };
                            const response = await this.nuveiService.editCreditCard(data);
                            if (response.status !== 'SUCCESS') {
                                this.toastService.showToast('Error', `${response.reason}`, 'error');
                            } else {
                                this.helper.clearServiceQueueState('getRecentPaymentMethodByUserId');
                                const pmEditing = this.recentPaymentMethods.find(pm => pm.paymentMethod?.cardInfo?.cardId === this.cardInfoToForm?.cardId);
                                if (pmEditing) pmEditing.paymentMethod.cardInfo.cvv = this.cardCvv;
                            }
                        }
                        const recentPmAux = this.ccRecentlySaved ? this.recentPaymentMethods[0] : null;

                        await this.getRecentPaymentMethods();
                        this.userVaultedCards = await this.getCreditCards();
                        if (recentPmAux) this.addRecentPM(recentPmAux, true);
                        this.syncDisplayPaymentMethods();
                    }
                }
            }
            if (this.stageCardForm === StagesCardForm.REQUESTING_CVV && this.cardCvv) {
                this.selectedPaymentMethod.paymentMethod.cardInfo.cvv = this.cardCvv;
            }
            this.stageCardForm = StagesCardForm.INACTIVE;
            this.nameOnCard = null;
            this.billing = null;
            this.cardInfoToForm = null;
            this.isEditingPaymentMethod = false;
            if (this.route === 'cart') this.parent?.validateCryptoPayButton();
        } else {
            this.showAllRecent = value ;
        }
    }

    checkForHidingPaymentMethods (method) {
        return ['game-currency', 'balance'].some(x => !x.includes(method.paymentMethod?.reference));
    }

    syncDisplayPaymentMethods() {
        if (!this.paymentMethods) {
            this.displayPaymentMethods = [];
            return;
        }

        this.recentThreePaymentMethods = this.recentPaymentMethods && (this.givenCurrencySelected?.type !== this.receivingCurrencySelected?.type || this.ignoreSelectedCurrencies) ? this.recentPaymentMethods.slice(0, 3) : [];
        this.displayPaymentMethods = [
            ...this.recentThreePaymentMethods,
            ...this.paymentMethods.filter(x => !this.recentThreePaymentMethods?.some(y => y.paymentMethodId === x.paymentMethodId && !x.paymentMethod.usesCard && x.paymentMethod.cardInfo))
        ];

        if (this.blacklistedUser) {
            this.displayPaymentMethods = this.displayPaymentMethods.filter(x => this.helper.includesSome(x.paymentMethod.reference, ['crypto', 'coinpayments', 'btcpay', 'bitcart']));
        }

        if (!this.payoutableOnly || !this.user.receivingPaymentMethods?.length) return;
        const validReceivingPaymentMethods = this.user.receivingPaymentMethods.filter(x => this.displayPaymentMethods.find(y => y.paymentMethodId === x.paymentMethodId));
        if (!validReceivingPaymentMethods.length) return;

        for (const receivingPm of validReceivingPaymentMethods) {
            const paymentMethod = this.displayPaymentMethods.find(x => x.paymentMethodId === receivingPm.paymentMethodId);
            paymentMethod.paymentMethod.payoutableInfo = {
                address: receivingPm.address,
                type: receivingPm.transactionAddressType,
                code: receivingPm.code,
                additionalInformation: receivingPm.additionalInformation
            };
        }

        for (const toClear of this.displayPaymentMethods.filter(x => x.paymentMethod.payoutableInfo && !validReceivingPaymentMethods.find(y => y.paymentMethodId === x.paymentMethodId))) {
            toClear.paymentMethod.payoutableInfo = null;
        }

        this.displayPaymentMethods = this.displayPaymentMethods.sort((a, b) => {
            if (!a.paymentMethod.payoutableInfo && b.paymentMethod.payoutableInfo) return 1;
            if (!b.paymentMethod.payoutableInfo && a.paymentMethod.payoutableInfo) return -1;
            return 0;
        });
    }

    getformattedExpiryDate(method) {
        const cardExpiry = method.paymentMethod.cardInfo.cardExpiry;
        const val = moment(new Date(cardExpiry.year, cardExpiry.month - 1, 1)).format('MM/YY');
        return `Exp: ${val}`;
    }

    isCardExpiredOrClose(method) {
        const cardExpiry = method.paymentMethod.cardInfo.cardExpiry;
        return this.creditCardHelper.isCardExpiredOrClose(cardExpiry.year, cardExpiry.month);
    }

    async saveCCFunction(ignoreCardInfo: boolean = false) {
        this.addRecentPM(this.selectedPaymentMethod, ignoreCardInfo);
        this.stageCardForm = StagesCardForm.INACTIVE;
        this.cardInfoToForm = null;
        this.paymentSelectorElement.click();
        this.syncDisplayPaymentMethods();
        this.selectPaymentMethod(this.recentPaymentMethods[0]);
    }

    addRecentPM(method: PaymentMethodWebsite, ignoreCardInfo: boolean = false) {
        const newRecentPM = deepCopyObj(method);
        const monthYear = this.cardMonthYear?.split('/');
        if (!ignoreCardInfo) {
            newRecentPM.paymentMethod.cardInfo = {
                lastDigits: this.cardNumber.substring(this.cardNumber.length - 4),
                type: this.cardType.toLocaleLowerCase(),
                reference: method.paymentMethod.reference,
                cardId: null,
                cardExpiry: {
                    month: parseInt(monthYear[0]),
                    year: parseInt(monthYear[1])
                },
                cvv: this.cardCvv
            };
            this.ccRecentlySaved = this.createDataObject();
        }
        this.recentPaymentMethods.unshift(newRecentPM);
    }

    deleteCard() {
        this.stageCardForm = StagesCardForm.DELETING;
    }

    async confirmDeletion(method: PaymentMethodWebsite) {
        if (method.paymentMethod.payoutableInfo) {
            await this.receivingPMService.delete(method.paymentMethodId);
            this.user = await this.sessionService.fetchProfileData(true);
            method.paymentMethod.payoutableInfo = null;
            this.syncDisplayPaymentMethods();
            this.paymentSelectorElement.click();
            return;
        }

        const { cardInfo } = method.paymentMethod;
        let response;
        if (cardInfo.cardId) {
            switch (cardInfo.reference) {
                case 'nuvei':
                    response = await this.nuveiService.deleteCreditCard({ userPaymentOptionId: parseInt(cardInfo.cardId) });
                    break;
                default:
                    break;
            }
        } else {
            this.ccRecentlySaved = null;
        }
        if (response?.status === 'SUCCESS') this.helper.clearServiceQueueState('getRecentPaymentMethodByUserId');
        const recentPmAux = this.ccRecentlySaved ? this.recentPaymentMethods[0] : null;
        await this.getRecentPaymentMethods();
        this.userVaultedCards = await this.getCreditCards();
        if (recentPmAux) this.addRecentPM(recentPmAux, true);
        this.stageCardForm = StagesCardForm.INACTIVE;
        this.syncDisplayPaymentMethods();

        if (cardInfo?.cardId === this.selectedPaymentMethod.paymentMethod?.cardInfo?.cardId) {
            const pmToSelect = this.recentPaymentMethods[0]?.paymentMethod?.cardInfo?.cardId ?
                this.recentPaymentMethods[0] :
                this.paymentMethods?.find(pm => pm.paymentMethod.reference === this.sessionService.getPreviousPaymentMethod());

            this.selectPaymentMethod(pmToSelect);

            if (this.selectedPaymentMethod.paymentMethod.usesCard) {
                this.setShowCheckoutContainer();
                this.stageCardForm = this.setStageCardForm();
            }
        }
    }

    async editCard(paymentMethod: PaymentMethodWebsite) {
        if (paymentMethod.paymentMethod.payoutableInfo) {
            this.editButtonClicked = true;
            setTimeout(() => {
                this.editButtonClicked = false;
            }, 1500);
            return;
        }

        this.stageCardForm = StagesCardForm.EDITING;
        this.showCheckoutContainer = true;
        this.cardInfoToForm = deepCopyObj(paymentMethod.paymentMethod.cardInfo);
        let cardFound;
        switch (this.cardInfoToForm.reference) {
            case 'nuvei':
                cardFound = this.userVaultedCards?.find(vc => vc.userPaymentOptionId.toString() === this.cardInfoToForm.cardId);
                if (cardFound) {
                    this.nameOnCard = {
                        firstName: cardFound.billingAddress.firstName,
                        lastName: cardFound.billingAddress.lastName
                    };
                    this.billing = {
                        city: cardFound.billingAddress.city,
                        state: cardFound.billingAddress.state,
                        countryCode: cardFound.billingAddress.countryCode,
                        countryName: cardFound.billingAddress.country ?? Country.getCountryByCode(cardFound.billingAddress.countryCode)?.name,
                        zip: cardFound.billingAddress.zip,
                        street: cardFound.billingAddress.address
                    };
                    this.cardType = cardFound.upoData.brand;
                    this.cardCvv = '';
                }
                if (this.ccRecentlySaved && !cardFound) {
                    this.cardNumber = this.ccRecentlySaved.ccCardNumber;
                    this.cardCvv = this.ccRecentlySaved.cvv;
                    const { city, state, countryCode, country, zip, address } = this.ccRecentlySaved.billingAddress;

                    this.billing = {
                        city,
                        state,
                        countryCode,
                        countryName: country ?? Country.getCountryByCode(this.ccRecentlySaved?.billingAddress.countryCode)?.name,
                        zip,
                        street: address };

                    this.cardType = this.creditCardHelper.getType(this.cardNumber);
                }
                break;
            default:
                this.nameOnCard = null;
                this.billing = null;
                break;
        }

    }

    stageCardFormChanged(newValue: number, oldValue: number) {
        if (newValue === StagesCardForm.INACTIVE) {
            this.cardNumber = this.cardMonthYear = undefined;
            this.showCheckoutContainer = false;
        }

        if (newValue === StagesCardForm.REQUESTING_CVV) {
            this.cardInfoToForm = deepCopyObj(this.selectedPaymentMethod?.paymentMethod?.cardInfo);
        }

        if (newValue === StagesCardForm.ADDING) {
            this.cardCvv = undefined;
            this.input?.blur();
        }

        if (oldValue === StagesCardForm.ADDING || oldValue === StagesCardForm.REQUESTING_CVV) {
            this.paymentSelectorElement.click();
        }
    }

    createDataObject() {
        const { reference } = this.selectedPaymentMethod.paymentMethod;
        let dataObject;
        const monthYear = this.cardMonthYear.split('/');
        switch (reference) {
            case 'nuvei':
                dataObject = {
                    ccCardNumber: this.cardNumber,
                    ccExpMonth: monthYear[0],
                    ccExpYear: (parseInt(monthYear[1]) + 2000).toString(),
                    ccNameOnCard: `${this.user.firstName} ${this.user.lastName}`,
                    billingAddress: {
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        address: this.billing.street,
                        phone: this.user.phoneNumber,
                        zip: this.billing.zip,
                        city: this.billing.city,
                        countryCode: this.billing.countryCode,
                        state: this.billing.state,
                        email: this.user.email
                    },
                    cvv: this.cardCvv
                };
                break;
            default:
                break;
        }

        return dataObject;
    }

    checkPaymentMethodVerification = (x: PaymentMethodWebsite) => {
        return !this.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Sell)) &&
        !this.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Buy)) &&
        !this.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Sell)) &&
        !this.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Buy));
    };

    selectRenewalMethod() {
        const method = this.displayPaymentMethods.find(
            x => x.paymentMethodId === this.renewalMethodId &&
            (!this.renewalCardLastDigits || !x.paymentMethod.cardInfo?.lastDigits || this.renewalCardLastDigits === x.paymentMethod.cardInfo.lastDigits)
        );
        this.selectPaymentMethod(method);
    }

    @computedFrom('placeholder', 'selectedPaymentMethod', 'isLoading', 'viewingAsAdmin')
    get selectorLabel() {
        if (this.selectedPaymentMethod?.paymentMethod?.payoutableInfo && !this.focusIn) return this.selectedPaymentMethod.paymentMethod.name;
        return this.siteStringsValueConverter.toView(this.placeholder ?? 'CX_PAYMENT_METHOD', this.placeholder);
    }

    get listContainerStyle() {
        return this.showCheckoutContainer || this.showPayoutMethodForm ? 'form' : '';
    }

    get dynamicDropdownStyles() {
        return this.outlined ? 'outlined-selector' : '';
    }

    getReceivingPaymentMethod() {
        if (!this.user) return null;
        const { receivingPaymentMethodId = null, receivingPaymentMethods = [] } = this.user;
        return receivingPaymentMethods.find(method => method?.paymentMethodId === receivingPaymentMethodId) || null;
    }
}
