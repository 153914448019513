import numeral from 'numeral';
import { CurrencyService } from 'services/currency-service';
import { autoinject } from 'aurelia-dependency-injection';
import { SessionService } from 'services/session-service';

@autoinject()
export class CurrencyFormatValueConverter {
    signals = ['currency-change'];

    constructor(
        private currencyService: CurrencyService,
        private sessionService: SessionService
    ) { }

    toView(value: number, symbol: string, type: string, isStable: boolean, format: string | null = null) {
        format ??= isStable ? '(0,0.00)' : '(0,0.000000)';

        const numericValue = value?.toString().includes('e-') ? Number(value.toFixed(6)) : value;

        return type === 'F' ? `${symbol}${numeral(numericValue ?? 0).format(format)}` : `${numeral(numericValue ?? 0).format(format)} ${symbol}`;
    }

    async toViewFiatOnly(value: number, format: string | null = null, currencyUsed: string = null, avoidConversion: boolean = false) {
        if (value === null || value === undefined) {
            return '0';
        }

        format ??= '(0,0.00)';

        const currency = currencyUsed ?? await this.sessionService.getCurrency();
        const symbol = this.getCurrencyPrefix(currencyUsed ?? currency);
        value = await this.getConvertedPrice(value, currency, avoidConversion);

        return `${symbol}${numeral(value).format(format)}`;
    }

    async getConvertedPrice(value: number, currentCurrency: string, avoidConversion: boolean = false) {
        if (avoidConversion) return value;

        const rate = await this.currencyService.getStoredCurrencyRates(currentCurrency);

        if (!rate) {
            return 1;
        }

        const total = this.evenRound(value, 5);
        return total * rate;
    }

    getCurrencyPrefix(currentCurrency: string) {
        const activeCurrencies = this.currencyService.getStoredActiveCurrenciesByWebsite();
        return activeCurrencies.find(x => x.code === currentCurrency)?.symbol || 'CURRENCY-ERROR';
    }

    evenRound(num: number, decimalPlaces: number) {
        const d = decimalPlaces || 0;
        const m = Math.pow(10, d);
        const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
        const i = Math.floor(n); const f = n - i;
        const e = 1e-8; // Allow for rounding errors in f
        const r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n);
        return d ? r / m : r;
    }
}
