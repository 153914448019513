import 'bootstrap';
import 'styles/styles.scss';
import '@popperjs/core';
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { HttpClient } from 'aurelia-fetch-client';
import { environmentName, apiEndpoint, websiteShortCode } from './environment';
import { ApiInterceptor } from './services/api-interceptor';
import '@chicksgroup/web-components';

export function configure(aurelia: Aurelia): void {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/base'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/form-field'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/text-field'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/expandable'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/checkbox'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/radio'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/select'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/circular-progress'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/dialog'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/data-table'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/drawer'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/tooltip'), (config) => {
            config.scrollHost = '#main-page-host .simplebar-content-wrapper';
            config.showDelay = 0;
            config.hideDelay = 0;
        })
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/lookup'))
        .feature(PLATFORM.moduleName('resources/index'))
        .feature(PLATFORM.moduleName('resources/elements/cx-customer-portal-navigation/index', 'aurelia'));

    aurelia.use.developmentLogging(environmentName() === 'local' ? 'debug' : 'none');

    aurelia.container.get(HttpClient).configure(config => {
        config
            .withBaseUrl(apiEndpoint())
            .withInterceptor(aurelia.container.get(ApiInterceptor))
            .withDefaults({
                headers: {
                    'Accept': 'application/json',
                    'websiteShortCode': websiteShortCode()
                }
            });
    });

    aurelia.start().then(() => {
        aurelia.setRoot(PLATFORM.moduleName('app'));
    });
}
